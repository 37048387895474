import AddVendorDetails from "Components/AddVendorDetails/AddVendorDetails";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const VendorsNew = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail="Add Vendor Details" back="all-vendor" />
      </div>
      <AddVendorDetails />
    </div>
  );
};

export default VendorsNew;
