import React, { useEffect, useRef, useState } from "react";
import { DateField, SelectField } from "Components/GeneralComponents";
import {
  selectDateRange,
  selectVendorServices,
  invoiceFilter,
} from "utils/dropdownData.js";
import { getProperties } from "network/landlord/property";
import { formatDate } from "utils";
const FilterPopup = ({
  isOpen,
  onclose,
  filterVal,
  hanldleFilter,
  handleSubmit,
  fieldHeight,
  category,
  filterList = {
    date: false,
    range: false,
    property: false,
    service: false,
    invoiceFilter: false,
  },
}) => {
  const [propertyOptions, setPropertyOptions] = useState([]);
  const popupRef = useRef(null);

  useEffect(() => {
    if (filterList.property) {
      getProperties().then((res) => {
        if (res.apiCallStatus === "success") {
          setPropertyOptions(
            res.message.map((item) => ({ label: item.title, value: item.id }))
          );
        }
      });
    }
  }, []);

  return (
    <>
      {isOpen && (
        <div ref={popupRef} className="filter-dropdown position-absolute">
          <div className="filter-checkbox-container">
            <p className="task-filter-dropdown-title mb-2">FILTER BY</p>
            {filterList.date && (
              <>
                <div className="mb-2 w-100">
                  <DateField
                    label={"From Date"}
                    placeholder={"Select Date"}
                    defaultValue={
                      filterVal.fromDate && formatDate(filterVal.fromDate)
                    }
                    handler={(e) => {
                      const formattedDate = new Date(e);
                      hanldleFilter("fromDate", formattedDate);
                    }}
                  />
                </div>
                <div className="mb-2 w-100">
                  <DateField
                    label={"To Date"}
                    placeholder={"Select Date"}
                    defaultValue={
                      filterVal.toDate && formatDate(filterVal.toDate)
                    }
                    handler={(e) => {
                      const formattedDate = new Date(e);
                      hanldleFilter("toDate", formattedDate);
                    }}
                  />
                </div>
              </>
            )}

            {filterList.range && (
              <div className="mb-2 w-100">
                <SelectField
                  label="Range"
                  placeholder={"custom"}
                  value={filterVal.range}
                  options={selectDateRange}
                  handler={(value) => hanldleFilter("range", value)}
                />
              </div>
            )}

            {filterList.invoiceFilter && (
              <div className="mb-2 w-100">
                <SelectField
                  label="invoice filter"
                  placeholder={"custom"}
                  value={filterVal.invoiceFilter}
                  options={invoiceFilter}
                  handler={(value) => hanldleFilter("invoiceFilter", value)}
                />
              </div>
            )}

            {filterList.service && (
              <div className="mb-2 w-100">
                <SelectField
                  label="Service"
                  placeholder={"Service"}
                  value={filterVal.service}
                  options={selectVendorServices}
                  handler={(value) => hanldleFilter("service", value)}
                />
              </div>
            )}

            {filterList.property && (
              <div className="mb-2 w-100">
                <SelectField
                  label="Property"
                  placeholder={"Property"}
                  value={filterVal.property}
                  options={propertyOptions}
                  handler={(value) => hanldleFilter("property", value)}
                />
              </div>
            )}

            <button
              onClick={handleSubmit}
              className="task-filter-dropdown-btn w-100 mt-2"
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterPopup;
