import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import ConvertToTenant from "Pages/ConvertToTenant/ConvertToTenant";

const ConvertToTenantPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"Convert To Tenant"}
          back="all-prospect"
        />
      </div>
      <ConvertToTenant />
    </div>
  );
};

export default ConvertToTenantPage;
