import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import ProspectDetails from "Components/ProspectDetails/ProspectDetails";

const ProspectDetailsPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail="Prospect Details" back="all-prospect" />
      </div>
      <ProspectDetails />
    </div>
  );
};

export default ProspectDetailsPage;
