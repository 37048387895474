import AddUserRole from "Components/AddUserRole/AddUserRole";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const UsersRoleNew = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"User Role"}
          back={"./settings/user-role"}
        />
      </div>
      <AddUserRole />
    </div>
  );
};

export default UsersRoleNew;
