import EditPayable from "Components/EditPayable/EditPayable";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const PayableEdit = () => {
  return (
    <div className="col-xl-10 col-lg-12 col-md-12 mt-3">
      <div className="container-fluid">
        <TitleTopBar topBarTitleDetail="Edit Payment" back="payment" />
      </div>
      <EditPayable />
    </div>
  );
};

export default PayableEdit;
