import AddMileage from "Components/AddMileage/AddMileage";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const MileageNew = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail={"Add Mileage"} back="mileage" />
      </div>
      <AddMileage />
    </div>
  );
};

export default MileageNew;
