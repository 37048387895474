import TenantProperties from "Components/TenantProperties/TenantProperties";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const Properties = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle={"Tenant Properties"} />
      </div>
      <TenantProperties />
    </div>
  );
};

export default Properties;
