import Chat from "Components/Chat/Chat";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const UserChat = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle="Chat" />
      </div>
      {/* {![
        "Owner/Executive",
        "Property Manager",
        "Maintenance Technician",
        "Leasing Agent",
      ].some((role) => myUserRoles?.includes(role)) &&
      myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <Chat />
      )} */}
      <Chat />
    </div>
  );
};

export default UserChat;
