import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import AddTenantDetails from "Components/AddTenantDetails/AddTenantDetails";

const AddTenantDetailsPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail="Add Tenant Details"
          back="all-tenants"
        />
      </div>
      <AddTenantDetails />
    </div>
  );
};

export default AddTenantDetailsPage;
