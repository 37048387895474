import React, { useEffect, useState } from "react";
import { message } from "antd";
import TenantPassportPaymentMethodModal from "Modals/TenantPassportPaymentMethodModal/TenantPassportPaymentMethodModal";
import TenantPassportInvitationSentModal from "Modals/TenantPassportInvitationSentModal/TenantPassportInvitationSentModal";
import UseGetHook from "Hooks/UseGetHook";
import config from "Helpers/config.js";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import { useNavigate } from "react-router-dom";
import * as InnerComponents from "./index";
import { fetchUserTenant } from "network/tenant/passport";
import Checkout from "Components/Payment/Checkout";
import axios from "axios";
import PassportCheckout from "./PassportCheckout";
import IdentityVerificaton from "./IdentityVerification";
import IncomeVerification from "./IncomeVerification";
import { employmentStatus } from "utils/dropdownData";
const TenantPassportProfile = () => {
  // States start.
  const [openModal, setOpenModal] = useState(false);
  const [openModalInvitation, setOpenModalInvitation] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [DeletedImages, setDeletedImages] = useState([]);
  const [Images, setImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [disabledFields, setDisabledFields] = useState([]);
  const [options, setOptions] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");

  const navigate = useNavigate();

  const [form, setForm] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    dob: "",
    ssn: "",
    gender: "",
    maritalStatus: "",
    moveInDate: "",
    itin: "",
    address: "",
    country: "USA",
    locality: "",
    region: "",
    zipCode: "",
    income: "",
    employmentStatus: "",
    incomeFrequency: "",
    otherIncome: "",
    otherIncomeFrequency: "",
    driverLicense: "",
    licenseNumber: "",
    nationalId: "123456789", //NationalId and must be 9 characters long.
  });

  const [employerVerificationForm, setEmployerVerificationForm] = useState([
    {
      jobTitle: "",
      companyName: "",
      annualIncome: "",
      employmentStatus: "",
      startDate: "",
      endDate: "",
      supervisorName: "",
      supervisorEmail: "",
      supervisorPhone: "",
    },
  ]);

  const addWorkHistory = () => {
    setEmployerVerificationForm([
      ...employerVerificationForm,
      {
        jobTitle: "",
        companyName: "",
        annualIncome: "",
        employmentStatus: "",
        startDate: "",
        endDate: "",
        supervisorName: "",
        supervisorEmail: "",
        supervisorPhone: "",
      },
    ]);
  };

  const removeWorkHistory = (index) => {
    setEmployerVerificationForm(
      employerVerificationForm.filter((_, i) => i !== index)
    );
  };

  const [rentalHistoryForm, setRentalHistoryForm] = useState([
    {
      residenceType: "",
      streetAddress: "",
      aptOrUnit: "",
      state: "",
      zipCode: "",
      country: "USA",
      moveInDate: "",
      moveOutDate: "",
      referenceType: "",
      fullName: "",
      monthlyRentAmount: "",
      email: "",
      phone: "",
      images: [],
    },
  ]);

  const handleRentalHistoryChange = (field, index, value) => {
    const newRentalHistory = [...rentalHistoryForm];
    newRentalHistory[index][field] = value;
    setRentalHistoryForm(newRentalHistory);
  };

  const addRentalHistory = () => {
    setRentalHistoryForm([
      ...rentalHistoryForm,
      {
        residenceType: "",
        streetAddress: "",
        aptOrUnit: "",
        state: "",
        zipCode: "",
        country: "USA",
        moveInDate: "",
        moveOutDate: "",
        referenceType: "",
        fullName: "",
        monthlyRentAmount: "",
        email: "",
        phone: "",
      },
    ]);
  };

  const removeRentalHistory = (index) => {
    setRentalHistoryForm(rentalHistoryForm.filter((_, i) => i !== index));
  };

  const handleEmployerChange = (field, index, value) => {
    const newEmployerInfo = [...employerVerificationForm];
    newEmployerInfo[index][field] = value;
    setEmployerVerificationForm(newEmployerInfo);
  };

  const [incomeForm, setIncomeForm] = useState({
    yearlyIncome: "",
    details: "",
  });

  const handleIncomeChange = (fieldName, value) => {
    setIncomeForm({
      ...incomeForm,
      [fieldName]: value,
    });
  };

  const [screeningQuestions, setScreeningQuestions] = useState({
    pets: "",
    people: "",
    individuals: "",
    fullName: "",
    phone: "",
  });

  const handleScreeningQuestionsChange = (fieldName, value) => {
    setScreeningQuestions({
      ...screeningQuestions,
      [fieldName]: value,
    });
  };

  const { email, id } = UseUrlParamsHook();

  const handleProfileChange = (fieldName, value) => {
    console.log(fieldName, value);
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const { user, FetchUserTenant } = UseGetHook("userinfo");
  // States end
  useEffect(() => {
    FetchUserTenant();
  }, []);

  useEffect(() => {
    setLoader(true);
    fetchUserTenant().then((res) => {
      setLoader(false);
      if (res.apiCallStatus === "success") {
        console.log("User >>> ", res);
        setForm({
          ...form,
          firstName: res.message.firstName || "",
          middleName: res.message.middleName || "",
          lastName: res.message.lastName || "",
          email: res.message.email || "",
          phone: res.message.phone || "",
          dob: res.message.dob || "",
          address: res.message.addressLine_1 || "",
          city: res.message.city || "",
          locality: res.message.state || "",
          zipCode: res.message.zipcode || "",
        });
        // set those fields to disabled which are already filled
        const fieldsToDisable = [];

        if (res.message.firstName) {
          fieldsToDisable.push("firstName");
        }
        if (res.message.middleName) {
          fieldsToDisable.push("middleName");
        }
        if (res.message.lastName) {
          fieldsToDisable.push("lastName");
        }
        if (res.message.email) {
          fieldsToDisable.push("email");
        }
        if (res.message.phone) {
          fieldsToDisable.push("phone");
        }
        if (res.message.dob) {
          fieldsToDisable.push("dob");
        }
        if (res.message.addressLine_1) {
          fieldsToDisable.push("address");
        }
        if (res.message.city) {
          fieldsToDisable.push("city");
        }
        if (res.message.state) {
          fieldsToDisable.push("state");
        }
        if (res.message.zipcode) {
          fieldsToDisable.push("zipCode");
        }

        setDisabledFields(fieldsToDisable);

        setProfileImage(res.message.profileImage);
      }
    });
  }, []);
  // States end

  const url = "/api/system/transunion/verify";

  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  // Modal Function
  const onOpenModalInvitation = () => {
    setOpenModalInvitation(true);
  };
  const onCloseModalInvitation = () => {
    setOpenModalInvitation(false);
  };

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await axios.get(
          `${config.baseUrl}/api/stripe/payForPassportReport`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const data = response.data;
        console.log(data);
        if (data?.message?.data?.client_secret) {
          const clientSecret = data.message.data.client_secret;
          setOptions({ clientSecret });
          setClientSecret(clientSecret);
        } else {
          throw new Error("Client secret not found");
        }
      } catch (error) {
        console.error(
          "Error fetching client secret:",
          error.response?.data || error.message
        );
      }
    };

    if (currentStep === 1) {
      fetchClientSecret();
    }
  }, []);

  const handlePaymentAuthorization = () => {
    setCurrentStep(2);
  };

  // States

  // Stepper Function
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
    if (currentStep === 4) {
      setCurrentStep(1);
    }
  };
  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const CreateScreeningRequest = () => {
    console.log("Creating screening");
    setLoader(true);
    const newErrors = {};

    for (const key in form) {
      if (form[key] === "") {
        newErrors[key] = `${key} is required`;
        setLoader(false);
      }
    }
    setErrors(newErrors);
    console.log("newErrors: " + JSON.stringify(newErrors));
    if (Object.keys(newErrors).length === 0) {
      fetch(`${config.baseUrl}${url}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          firstName: form.firstName,
          middleName: form.middleName,
          lastName: form.lastName,
          email: form.email,
          phone: form.phone,
          dob: form.dob,
          ssn: form.ssn,
          gender: form.gender,
          maritalStatus: form.maritalStatus,
          moveInDate: form.moveInDate,
          itin: form.itin,
          address: form.address,
          country: form.address,
          locality: form.locality,
          region: form.region,
          zipCode: form.zipCode,
          income: form.income,
          assets: form.assets,
          incomeFrequency: form.incomeFrequency,
          otherIncome: form.otherIncome,
          employmentStatus: form.employmentStatus,
          otherIncomeFrequency: form.otherIncomeFrequency,
          driverLicense: form.driverLicense,
          licenseNumber: form.licenseNumber,
          nationalId: "123456789", //NationalId and must be 9 characters long.
        }),
      })
        .then((res) => {
          console.log("respons hai ye:", res.status);
          if (res.status == 409) {
            message.error("You already have one Screening Request, Can not create an other");
            // console.log(res.message.message);
            setLoader(false);
            setTimeout(() => {
              navigate(`/tenant-passport`);
            }, 2000);
          }

          return res.json();
        })
        .then((res) => {
          if (res.apiCallStatus === "success") {
            if (
              res.message.message ===
              "You already have one Screening Request, Can not create an other"
            ) {
              message.error(res.message.message);
              console.log(res.message.message);
              setLoader(false);
              setTimeout(() => {
                navigate(`/tenant-passport`);
              }, 2000);
            } else {
              console.log("created a new Screening Request");
              setLoader(false);
              navigate(
                `/tenant-questions?id=${
                  id || res.message.screeningRequests_._id
                }&ssn=123456789`
              );
            }
          } else {
            message.error(res.error.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err, "errorr");
          setLoader(false);
        });
    }
  };

  return (
    <>
      {openModal === true ? (
        <TenantPassportPaymentMethodModal
          onOpen={onOpenModalInvitation}
          onClose={onCloseModal}
        />
      ) : (
        ""
      )}
      {openModalInvitation === true ? (
        <TenantPassportInvitationSentModal
          success="Share Your Profile"
          route="tenant-passport"
          onClose={onCloseModalInvitation}
        />
      ) : (
        ""
      )}
      <div className="container-fluid bg-white p-3">
        <div className="container">
          <InnerComponents.Stepper currentStep={currentStep} />
          <div className="stepper-content-container mt-4">
            {currentStep === 1 && (
              <PassportCheckout
                handlePaymentAuthorization={handlePaymentAuthorization}
              />
              // <InnerComponents.EditProfileForm
              //   form={form}
              //   handleChange={handleProfileChange}
              //   errors={errors}
              //   setProfileImages={setProfileImage}
              //   ProfileImages={profileImage}
              //   setDeletedImages={setDeletedImages}
              //   DeletedImages={DeletedImages}
              //   handleNext={handleNext}
              //   disabledFields={disabledFields}
              // />
            )}
            {currentStep === 2 && (
              <InnerComponents.EditProfileForm
                form={form}
                handleChange={handleProfileChange}
                errors={errors}
                setProfileImages={setProfileImage}
                ProfileImages={profileImage}
                setDeletedImages={setDeletedImages}
                DeletedImages={DeletedImages}
                handleNext={handleNext}
                disabledFields={disabledFields}
              />
              // <InnerComponents.EmploymentVerificationForm
              //   form={employerVerificationForm}
              //   errors={errors}
              //   handleChange={handleEmployerChange}
              //   addWorkHistory={addWorkHistory}
              //   removeWorkHistory={removeWorkHistory}
              //   handleNext={handleNext}
              //   handlePrev={handlePrev}
              // />
            )}
            {currentStep === 3 && (
              <IdentityVerificaton
                handleNext={handleNext}
                handlePrev={handlePrev}
                data={form}
              />

              // <InnerComponents.RentalHistoryForm
              //   form={rentalHistoryForm}
              //   errors={errors}
              //   handleChange={handleRentalHistoryChange}
              //   addRentalHistory={addRentalHistory}
              //   removeRentalHistory={removeRentalHistory}
              //   handleNext={handleNext}
              //   handlePrev={handlePrev}
              // />
            )}
            {currentStep === 4 && (
              <IncomeVerification
                handleNext={CreateScreeningRequest}
                data={form}
              />

              // <InnerComponents.CreditAndBackgroundCheckForm
              //   form={incomeForm}
              //   errors={errors}
              //   Images={Images}
              //   setImages={setImages}
              //   handleChange={handleIncomeChange}
              //   handleNext={handleNext}
              //   handlePrev={handlePrev}
              // />
            )}
            {/* {currentStep === 5 && (
              <InnerComponents.ScreeningQuestionsForm
                form={screeningQuestions}
                errors={errors}
                handleChange={handleScreeningQuestionsChange}
                handleNext={CreateScreeningRequest}
                handlePrev={handlePrev}
              />
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantPassportProfile;
