import React, {useEffect, useState} from "react";
import Notification from "Components/Notification/Notification";
import TitleTopBar from "Helpers/TitleTopBar";
import config from "Helpers/config.js";
import axios from "axios";
import { Puff } from 'react-loader-spinner'

const Notifications = () => {
  const [notifications, setNotifications] = useState();

  useEffect(() => {
    const notificationData = async () => {
      try {
        const response = await axios.get(
          `${config.baseUrl}/api/notifications`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setNotifications(response?.data?.message?.data);
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      }
    };

    notificationData();
  }, []);

  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle="Notification" />
      </div>
      {notifications?.length > 0 ? (
        notifications?.map((notificationsData) => {
          // console.log("🚀 ~ {notifications?.length>0?notifications?.map ~ notificationsData:", notificationsData)
          return (
            <div key={notificationsData?._id}>
              <Notification
                profileImage={notificationsData?.profileImage}
                firstName={notificationsData?.sender?.firstName}
                lastName={notificationsData?.sender?.lastName}
                message={notificationsData?.message}
                createdAt={notificationsData?.createdAt}
                redirectUrl={notificationsData?.redirectUrl}
                _id={notificationsData?._id}
              />
            </div>
          );
        })
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            marginTop: "50px",
          }}
        >
          <Puff
            visible={true}
            height="80"
            width="80"
            color="#ef6b3e"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
    </div>
  );
};

export default Notifications;
