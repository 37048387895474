import React from 'react'

const SettingsNotification = () => {
  return (
    <div>
      
    </div>
  )
}

export default SettingsNotification
