import CreatePayable from "Components/CreatePayable/CreatePayable";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const PayableNew = () => {
  return (
    <div>
      <div className="container-fluid">
        <TitleTopBar topBarTitleDetail="Create Payable" back="payment" />
      </div>
      <CreatePayable />
    </div>
  );
};

export default PayableNew;
