import React, { useState } from "react";
import * as Component from "Components/GeneralComponents";
import ChevronLeft from "assets/chevron-left.png";
import { checkPasswordCriteria } from "utils";
import { Link, useLocation } from "react-router-dom";
import { PasswordValidate } from "Components/GeneralComponents";
import { message } from "antd";
import { tenantGoogleRegister } from "network/tenant/auth";

const SetPasswordForm = ({ openSuccessModal }) => {
  const [form, setForm] = useState({ password: "", confirmPassword: "" });
  const [isLoader, setIsLoader] = useState(false);
  //   get token from the URL
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const ResetPassword = () => {
    const passwordCriteria = checkPasswordCriteria(form.password);
    if (form.password === "" || form.confirmPassword === "") {
      message.error("Please Fill mandatory fields to continue");
    } else if (form.confirmPassword !== form.password) {
      message.error("Password does not match");
    } else if (
      !passwordCriteria.hasMinimumLength ||
      !passwordCriteria.hasNumber ||
      !passwordCriteria.hasUpperCase ||
      !passwordCriteria.hasUpperCase ||
      !passwordCriteria.hasSpecialCharacter
    ) {
      message.error("Please choose another password");
    } else {
      const payload = {
        token: token,
        password: form.password,
      };

      setIsLoader(true);
      tenantGoogleRegister(payload).then((res) => {
        setIsLoader(false);
        if (res.apiCallStatus === "success") {
          openSuccessModal();
        } else {
          message.error(res.message);
        }
      });
    }
  };

  return (
    <>
      <div className="reset-password-heading my-5 pt-5">
        <h1>Set Password</h1>
        <p>Create a new password for your account</p>
      </div>
      <div className="">
        <div className="row mt-4">
          <div className="col-md-12">
            <Component.PasswordField
              name={"password"}
              required={true}
              label="Create new password"
              placeholder={"Password"}
              value={form.password}
              handler={handleChange}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <Component.PasswordField
              name={"confirmPassword"}
              required={true}
              label="Confirm Password"
              placeholder={"Confirm Password"}
              value={form.confirmPassword}
              handler={handleChange}
            />
          </div>
        </div>
        <div className="password-validation-list mt-4">
          <PasswordValidate validate={checkPasswordCriteria(form.password)} />
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="reset-password-buttons d-flex gap-4 ">
              <Component.SubmitButton
                handler={ResetPassword}
                isLoading={isLoader}
              >
                Save
              </Component.SubmitButton>
            </div>
          </div>
        </div>
        <div className="already-account-text text-center mt-4">
          <span>
            <img src={ChevronLeft} alt="" />
            <Link to="/tenant/login" className="custom-link-text fw-medium">
              Back to log in
            </Link>
          </span>
        </div>
      </div>
    </>
  );
};

export default SetPasswordForm;
