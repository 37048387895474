import ResponsiveSetting from "Components/ResponsiveSetting/ResponsiveSetting";
import Setting from "Components/Setting/Setting";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const Settings = () => {
  return (
    <div className="row hv-100">
      <div className="col-xl-12 d-lg-12 d-lg-block mt-3 d-xl-block d-md-none d-none">
        <div className="right-component bg-white h-100">
          <div className="container-fluid bg-default">
            <TitleTopBar topBarTitle="Setting" />
          </div>
          <Setting />
        </div>
      </div>
      <div className="col-md-12 mt-3 d-lg-none d-md-block">
        <div className="right-component bg-white h-100">
          <div className="container-fluid bg-default">
            <TitleTopBar topBarTitle="Setting" />
          </div>
          <ResponsiveSetting />
        </div>
      </div>
    </div>
  );
};

export default Settings;
