import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import AddPropertyDetails from "Components/AddPropertyDetails/AddPropertyDetails";
const PropertiesNew = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail="Add Property Details"
          back="properties-dashboard"
        />
      </div>
      <AddPropertyDetails />
    </div>
  );
};

export default PropertiesNew;
