import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import PropertiesDashboard from "Components/PropertiesDashboard/PropertiesDashboard";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";

const Properties = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white vh-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle={"Properties"} />
      </div>
      {![
        "Owner/Executive",
        "Leasing Agent",
        "Maintenance Technician",
        "Property Manager",
      ].some((role) => myUserRoles?.includes(role)) &&
      myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <PropertiesDashboard />
      )}
      {/* <PropertiesDashboard /> */}
    </div>
  );
};

export default Properties;
