import TitleTopBar from "Helpers/TitleTopBar";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";
import React from "react";

const NoAccess = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle="No Access" />
      </div>
      <NoAccessPage />
    </div>
  );
};

export default NoAccess;
