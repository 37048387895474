import FreeIcon from "assets/free-icon.png";
import ProIcon from "assets/pro-Icon.png";
import React, { useEffect, useState } from "react";
import { message, Skeleton, Card } from "antd";
import PremiumPackageCard from "./PremiumPackageCard";
import { upgrade, downgrade } from "network/landlord/subscription";
const SettingSubscriptionCards = ({
  data,
  interval,
  selectedPlan,
  setSelectedPlan,
  subscribeIfTrial,
  setUpdate,
  isLoading,
}) => {
  const [selected, setSelected] = useState(selectedPlan);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    console.log("Selected Plan >>>>>>>>>", selectedPlan);
  }, [selected]);

  const freePackage = {
    priceMonthly: "Free",
    priceYearly: "Free",
    priceIdMonthly: "",
    productIdMonthly: "",
    priceIdYearly: "",
    productIdYearly: "",
    description:
      "Access to all features, up to 100 properties, up to 10 users, up to 10GB storage, email support",
    features: [
      "Access to all features",
      "Up to 100 properties",
      "Up to 10 users",
      "Up to 10GB storage",
      "Email support",
    ],
  };

  const HandlePlanValue = (planName, price) => {
    setSelected({
      planName: planName,
      price: price,
    });
    setSelectedPlan({
      planName: planName,
      price: price,
    });
  };

  const UpdateSubscription = (updateOption, planType, price) => {
    console.log("Update Option", updateOption);
    console.log("Selected Plan", { planType, price });
    setIsLoader(true);
    if (selectedPlan?.planName === "Trial") {
      subscribeIfTrial(planType);
    } else if (updateOption === "upgrade") {
      upgrade({
        userId: localStorage.getItem("user_id"),
        newPlan: planType,
        billingCycle: interval,
      }).then((res) => {
        setIsLoader(false);
        if (res.apiCallStatus === "success") {
          setUpdate(true);
          message.success("Plan upgraded successfully");
        }
      });
    } else {
      downgrade({
        userId: localStorage.getItem("user_id"),
        downgradePlan: planType,
        billingCycle: interval,
      }).then((res) => {
        setIsLoader(false);
        if (res.apiCallStatus === "success") {
          setUpdate(true);
          message.success("Plan downgraded successfully");
        }
      });
    }
  };

  return (
    <div className="row d-flex justify-content-center">
      {isLoading ? (
        Array.from({ length: 4 }).map((_, index) => (
          <div key={index} className="col-xxl-4 col-lg-6 col-md-6 col-sm-12 mt-4">
            <Card style={{ backgroundColor: "#f8f4f4" }}>
              <Skeleton.Image style={{ width: "100%", height: "100px" }} />
              <Skeleton active paragraph={{ rows: 4 }} />
            </Card>
          </div>
        ))
      ) : (
        <>
          {selectedPlan?.planName === "Trial" && (
            <PremiumPackageCard
              icon={FreeIcon}
              item={freePackage}
              planType={"Free"}
              billingInterval={interval}
              selectedPlan={selected}
              handlePlanValue={HandlePlanValue}
              UpdateSubscription={UpdateSubscription}
              showButton={true}
              isTrialCard={true}
            />
          )}

          <PremiumPackageCard
            icon={ProIcon}
            item={data.Basic}
            planType={"Basic"}
            billingInterval={interval}
            selectedPlan={selected}
            handlePlanValue={HandlePlanValue}
            UpdateSubscription={UpdateSubscription}
            showButton={true}
          />

          <PremiumPackageCard
            icon={ProIcon}
            item={data.Pro}
            planType={"Pro"}
            billingInterval={interval}
            selectedPlan={selected}
            handlePlanValue={HandlePlanValue}
            UpdateSubscription={UpdateSubscription}
            showButton={true}
          />

          <PremiumPackageCard
            icon={ProIcon}
            item={data.Premium}
            planType={"Premium"}
            billingInterval={interval}
            selectedPlan={selected}
            handlePlanValue={HandlePlanValue}
            UpdateSubscription={UpdateSubscription}
            showButton={true}
          />
        </>
      )}
    </div>
  );
};

export default SettingSubscriptionCards;
