import { useEffect, useState } from "react";
import SearchBar from "Helpers/SearchBar";
import { Table, ConfigProvider, message } from "antd";
import whiteDot from "assets/_white-Dot.png";
import { Link, useNavigate } from "react-router-dom";
import UserPermission from "libs/UserPermission";
import SearchBarWithOutBtn from "Helpers/SearchBarWithOutBtn";
import Loader from "Helpers/Loader";
import * as API from "network/landlord/lease";
import * as Icon from "assets/icons";
import * as Component from "Components/GeneralComponents";
import UploadDocument from "Components/NewLease/UploadDocument";

const AllLeases = () => {
  const { ROLE } = UserPermission();

  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [key, setKey] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [leases, setLeases] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [isDeleteLeaseModal, setIsDeleteLeaseModal] = useState(false);
  const [isDeleteLeasesModal, setIsDeleteLeasesModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [listRerender, setListRerender] = useState(false);
  const [isupload, setIsupload] = useState(false);
  const [leaseId, setLeaseId] = useState("");

  const navigate = useNavigate();

  // get leases
  useEffect(() => {
    setIsListLoading(true);
    API.getLeases().then((res) => {
      setIsListLoading(false);
      if (res.apiCallStatus === "success") {
        console.log(res?.message?.data);
        setLeases(
          res?.message?.data?.map((e) => ({
            key: e._id,
            renew: true,
            property: `${e.property.address.address_line_1} ${
              e.property.address.address_line_2
                ? e.property.address.address_line_2
                : ""
            } ${e.property.address.city} ${e.property.address.state} ${
              e.property.address.zipcode
            } ${e.property.address.country}`.trim(),
            date: [
              new Date(e.lease_term_start_date).toLocaleDateString(),
              e.lease_term_end_date
                ? new Date(e.lease_term_end_date).toLocaleDateString()
                : "---",
            ],
            term: e.lease_term,
            rent: `$${e.rent_amount}`,
            deposit: `$4000`,
            balance: "$1000",
            status: {
              value: e.status.value,
              days: e.status.days,
            },
            isDraft: e.is_draft,
          }))
        );
      } else {
        message.error(res?.message);
      }
    });
  }, [listRerender]);

  // Data Table Functions
  const handleIconClick = (result) => {
    const filterData = leases.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const columns = [
    {
      title: "Property Address",
      dataIndex: "property",
      render: (text, property) => (
        <Link to={property.isDraft ? `/draft-lease?id=${property.key}` : `/lease-detail?id=${property.key}`}>
          <div className="all-lease-property-text">{text}</div>
        </Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text) => (
        <>
          <span className="all-lease-date-text">{text[0]}</span>
          <br />
          <span className="all-lease-date-text">{text[1]}</span>
        </>
      ),
    },
    {
      title: "Term",
      dataIndex: "term",
      render: (text, term) => (
        <>
          <div className="table-status-bar lease-status">
            <>
              <img src={whiteDot} alt="" /> <span>{text}</span>
            </>
          </div>
        </>
      ),
    },
    {
      title: "Rent",
      dataIndex: "rent",
    },
    {
      title: "Deposit",
      dataIndex: "deposit",
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, status) => (
        <>
          {status.isDraft ? (
            <>
              <p className="expire-label pb-0 mb-0"></p>
              <span className="about-to-expire-text">Draft</span>
            </>
          ) : text.days ? (
            <div>
              {" "}
              <p className="expire-label pb-0 mb-0"></p>
              <span className="about-to-expire-text">{text.value}</span>
              <br />
              <span className="error-text ">{text.days} days remaining</span>
            </div>
          ) : text.value === "Active" ? (
            <div>
              <p className="active-label pb-0 mb-0"></p>
              <span className="primary-orange-text">{text.value}</span>
            </div>
          ) : (
            <div>
              <p className="expired-label pb-0 mb-0"></p>
              <span className="expired-text">{text.value}</span>
            </div>
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  setDeleteId(setting.key);
                  setIsDeleteLeaseModal(true);
                },
                handleRenewLease: () => {},
                handleView: () => {
                  navigate(`/lease-detail?id=${setting.key}`);
                },
                uploadDocument: () => {
                  setLeaseId(setting.key);
                  handleopenmodal();
                },
                editdraft: () => {
                  navigate(`/draft-lease?id=${setting.key}`);
                },
              }}
              fields={{
                view: true,
                delete: true,
                uploadDocument: true,
                editdraft: setting.isDraft,
                renewLease: setting.renew,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  const handleopenmodal = () => {
    setIsupload(!isupload);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  // handle delete Lease
  const handleDeleteLease = () => {
    setIsLoading(true);
    API.deleteLease(deleteId).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        message.success(res?.message?.message);
        setIsDeleteLeaseModal(false);
        setListRerender(!listRerender);
      } else {
        message.error(res?.message);
      }
    });
  };

  // handle delete multiple leases
  const handleDeleteLeases = () => {
    setIsLoading(true);
    API.deleteMultipleLeases(selectedTableItem).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        message.success(res?.message?.message);
        setIsDeleteLeasesModal(false);
        setListRerender(!listRerender);
      } else {
        message.error(res?.message);
      }
    });
  };

  return (
    <>
      {isupload && (
        <Component.FormModalLayout
          title={"Upload Document"}
          isOpen={isupload}
          onClose={handleopenmodal}
        >
          <div className="modal-content-main-section p-41">
            <UploadDocument
              leaseId={leaseId}
              onClose={handleopenmodal}
            />
          </div>
        </Component.FormModalLayout>
      )}

      <Component.DeleteModal
        isOpen={isDeleteLeasesModal}
        onClose={() => setIsDeleteLeasesModal(false)}
        message={"Are you sure you want to delete selecteed leases?"}
        deleteCall={handleDeleteLeases}
        isLoading={isLoading}
      />

      <Component.DeleteModal
        isOpen={isDeleteLeaseModal}
        onClose={() => setIsDeleteLeaseModal(false)}
        message={"Are you sure you want to delete this lease?"}
        deleteCall={handleDeleteLease}
        isLoading={isLoading}
      />

      <div className="container-fluid bg-white p-3 ">
        <div className="row">
          <div className="col-md-12">
            {localStorage.getItem("role") !== "landlord" &&
            localStorage.getItem("role") === "user" &&
            ROLE[0]?.leases?.add ? (
              <SearchBar
                btnTitle="Add New Lease"
                route="new-lease"
                leaseFilter={true}
              />
            ) : localStorage.getItem("role") === "landlord" ? (
              <SearchBar
                btnTitle="Add New Lease"
                route="new-lease"
                leaseFilter={true}
              />
            ) : (
              <SearchBarWithOutBtn leaseFilter={true} />
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            {selectedTableItem.length !== 0 && (
              <div className="table-delete-icon mb-3">
                <button
                  onClick={() => setIsDeleteLeasesModal(true)}
                  className="table-delete-btn next-btn-main"
                >
                  <Icon.DeleteIcon />
                  Delete
                </button>
              </div>
            )}
            {isListLoading ? (
              <Loader />
            ) : (
              <div className="table-container ">
                <ConfigProvider
                  theme={{
                    components: {
                      Table: {
                        colorTextHeading: "#667085",
                        colorText: "#667085",
                        fontSize: 14,
                        fontWeightStrong: 500,
                        fontFamily: "Montserrat",
                      },
                      Pagination: {
                        itemActiveBg: "#EF6B3E",
                      },
                      Checkbox: {
                        colorPrimary: "#EF6B3E",
                        colorPrimaryHover: "#EF6B3E",
                      },
                    },
                  }}
                >
                  <Table
                    pagination={true}
                    className="all-lease-table-main scroll-remove scroll-responsive-tablet"
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={leases}
                  />
                </ConfigProvider>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllLeases;
