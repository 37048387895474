import TenantPassportDetailsView from "Components/TenantPassportDetailsView/TenantPassportDetailsView";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const PassportDetail = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"Tenant Passport Details"}
          back="tenant-passport"
        />
      </div>
      <TenantPassportDetailsView />
    </div>
  );
};

export default PassportDetail;
