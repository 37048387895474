import AddAccount from "Components/AddAccount/AddAccount";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const AccountsAdd = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail={"Add Account"} back="all-accounts" />
      </div>
      <AddAccount />
    </div>
  );
};

export default AccountsAdd;
