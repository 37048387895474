import PayableOverview from "Components/PayableOverview/PayableOverview";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const PayableOverviewPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail={"Payments"} back={"Payment"} />
      </div>
      <PayableOverview />
    </div>
  );
};

export default PayableOverviewPage;
