import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import PropertyDetailsView from "Components/PropertyDetailsView/PropertyDetailsView";
const PropertyDetails = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"Property Details"}
          back="properties-dashboard"
        />
      </div>
      <PropertyDetailsView />
    </div>
  );
};

export default PropertyDetails;
