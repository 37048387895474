import ConvertToProspect from "Components/ConvertToProspect/ConvertToProspect";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const ProspectLease = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail={"Lease"} back="all-prospect" />
      </div>
      <ConvertToProspect />
    </div>
  );
};

export default ProspectLease;
