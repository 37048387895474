import ProfessionalDetails from "Components/ProfessionalDetails/ProfessionalDetails";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const ServiceProfessionalsDetail = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"Water Zone "}
          back="all-service-professional"
        />
      </div>
      <ProfessionalDetails />
    </div>
  );
};

export default ServiceProfessionalsDetail;
