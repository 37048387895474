import { useState, useEffect } from "react";
import searchIcon from "assets/New icons (7).png";
import filterIcon from "assets/filter.png";
import plusIcon from "assets/plus.png";
import trashIconWhite from "assets/trash-icon-white.png";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import { propertyIcon } from "utils/index";
import AddPropertyEditModal from "Modals/AddPropertyEditModal/AddPropertyEditModal";
import PropertyFilter from "Helpers/PropertyFilter";
import UserPermission from "libs/UserPermission";
import { VerticalElipsisIcon } from "assets/icons";
import {
  CustomTable,
  DeleteModal,
  FormModalLayout,
  RecordMenuPopup,
} from "Components/GeneralComponents";
import {
  archiveProperty,
  unArchiveProperty,
  deleteProperty,
  deleteMultipleProperties,
  getProperties,
} from "network/landlord/property";

const PropertiesDashboard = () => {
  // select box icon
  const { ROLE } = UserPermission();
  // States start
  const navigate = useNavigate();
  const [hideFilter, setHideFilter] = useState(false);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [key, setKey] = useState([]);
  const [search, setSearch] = useState("");
  const [openEditModalTask, setOpenEditModalTask] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [update, setUpdate] = useState(false);
  const [isDeletePropertyRender, setIsDeletePropertyRender] = useState(false);
  const [isDeletePropertiesRender, setIsDeletePropertiesRender] =
    useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeletePropertiesModal, setOpenDeletePropertiesModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState(
    "Are you sure you want to delete this property?"
  );
  const [multipleDeleteMsg, setMultipleDeleteMsg] = useState(
    "Are you sure you want to delete these properties?"
  );
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);
  const [propertiesData, setPropertiesData] = useState([]);
  const [filter, setFilter] = useState({
    propertyStatus: "",
    state: "",
    propertyType: "",
    bedrooms: {},
    bathrooms: {},
    rentAmountMin: 1,
    rentAmountMax: 6000,
  });

  useEffect(() => {
    setIsLoader(true);
    getProperties(filter).then((res) => {
      setIsLoader(false);
      if (res.apiCallStatus === "success") {
        setPropertiesData(
          res.message
            .filter(
              (e) =>
                e?.title?.toLowerCase().includes(search.toLowerCase()) ||
                e?.address?.address_line_1
                  ?.toLowerCase()
                  .includes(search.toLowerCase()) ||
                e?.property_sub_type
                  ?.toLowerCase()
                  .includes(search.toLowerCase()) ||
                e?.rent_amount?.toLocaleString()?.includes(search) ||
                (
                  e?.address?.city +
                  " " +
                  e?.address?.state +
                  " " +
                  e?.address?.zipcode
                )
                  .toLowerCase()
                  .includes(search.toLowerCase())
            )
            .map((e) => ({
              key: e.id,
              propertyName: e.title,
              img: e.images,
              city: e.address.city,
              state: e.address.state,
              zipcode: e.address.zipcode,
              type: `${e.property_sub_type}`,
              address: e.address.address_line_1,
              rentAmount: `$${e.rent_amount.toLocaleString()}`,
              isArchive: e.isArchived,
            }))
        );
      } else {
        message.error("Something went wrong");
      }
    });
  }, [filter, search, update]);

  // Data Table Functions
  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = propertiesData.filter((item) => {
      return item.key === result;
    });
    setKey(filterData.key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };
  const onOpenEditModalTask = () => {
    setOpenEditModalTask(true);
  };

  const columns = [
    {
      title: "Property Name",
      dataIndex: "propertyName",
      render: (text, propertyName) => (
        <>
          <Link
            to={`/property-details-view?id=${propertyName.key}`}
            className="d-flex align-items-center gap-2"
          >
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: "50px",
                borderRadius: "10%",
                overflow: "hidden",
                position: "relative",
              }}
            >
              {propertyName.img.length !== 0 ? (
                <img
                  src={propertyName.img[0]}
                  alt="task"
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src={propertyIcon(propertyName.type)}
                  alt="task"
                  style={{
                    width: "2rem",
                    height: "2rem",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
            <div className="">{text}</div>
          </Link>
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text, type) => (
        <>
          <span className="type-text-multi-family rounded-4">
            {text.split("_").join(" ")}
          </span>
        </>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      render: (text, add) => (
        <>
          <div className="properties_dashbaord_address_text">{text}</div>
          <span className="properties_dashbaord_address_text">
            {add.city} {add.state}, {add.zipcode}
          </span>
        </>
      ),
    },
    {
      title: "Rent Amount",
      dataIndex: "rentAmount",
      render: (text) => (
        <>
          <span className="properties_dashboard_table_amount_text">{text}</span>
        </>
      ),
    },

    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <VerticalElipsisIcon width={23} height={23} />
            <RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  setOpenDeleteModal(true);
                  setDeleteId(setting.key);
                },
                handleView: () => {
                  navigate(`/property-details-view?id=${setting.key}`);
                },
                handleEdit: () => {
                  onOpenEditModalTask();
                  setTaskId(setting.key);
                },
                archive: () => {
                  archiveProperty(setting.key).then((res) => {
                    if (res.apiCallStatus === "success") {
                      message.success("Property has been archived");
                      setUpdate(!update);
                    } else {
                      message.error("Something went wrong");
                    }
                  });
                },
                unarchive: () => {
                  unArchiveProperty(setting.key).then((res) => {
                    if (res.apiCallStatus === "success") {
                      message.success("Property has been unarchived");
                      setUpdate(!update);
                    } else {
                      message.error("Something went wrong");
                    }
                  });
                },
              }}
              fields={{
                view: true,
                edit: true,
                archive: setting.isArchive ? false : true,
                unarchive: setting.isArchive ? true : false,
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];
  // Get Properties

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem([...selectedRowKeys]);
    },

    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  //Status Dropdown
  const onCloseEditModalTask = () => {
    setOpenEditModalTask(false);
  };

  // handle delete property
  useEffect(() => {
    if (deleteId) {
      setIsLoading(true);
      deleteProperty(deleteId).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setOpenDeleteModal(false);
          message.success("Property has been deleted");
          setUpdate(!update);
          setDeleteId("");
        } else {
          setDeleteMsg(res.message);
          setIsDeleteDisabled(true);
        }
      });
      // setOpenDeleteModal(true);
    }
  }, [isDeletePropertyRender]);

  // handle multiple delete properties
  useEffect(() => {
    if (selectedTableItem.length > 0) {
      setIsLoading(true);
      deleteMultipleProperties(selectedTableItem).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setOpenDeletePropertiesModal(false);
          message.success("Properties has been deleted");
          setUpdate(!update);
          setSelectedTableItem([]);
        } else {
          setMultipleDeleteMsg(res.message);
          setIsDeleteDisabled(true);
        }
      });
    }
  }, [isDeletePropertiesRender]);

  return (
    <>
    <DeleteModal
      isOpen={openDeleteModal}
      onClose={() => {
        setOpenDeleteModal(false);
        setDeleteId("");
        setIsDeleteDisabled(false);
        setDeleteMsg("Are you sure you want to delete this property?");
      }}
      message={deleteMsg}
      deleteCall={() => setIsDeletePropertyRender(!isDeletePropertyRender)}
      isLoading={isLoading}
      disableBtn={isDeleteDisabled}
    />
  
    <DeleteModal
      isOpen={openDeletePropertiesModal}
      onClose={() => {
        setOpenDeletePropertiesModal(false);
        setSelectedTableItem([]);
        setMultipleDeleteMsg("Are you sure you want to delete these properties?");
        setIsDeleteDisabled(false);
      }}
      message={multipleDeleteMsg}
      deleteCall={() => setIsDeletePropertiesRender(!isDeletePropertiesRender)}
      isLoading={isLoading}
      disableBtn={isDeleteDisabled}
    />
  
    <FormModalLayout
      title={"Edit Property"}
      isOpen={openEditModalTask}
      onClose={onCloseEditModalTask}
    >
      <AddPropertyEditModal
        id={taskId}
        onClose={() => {
          setOpenEditModalTask(false);
          setUpdate(!update);
        }}
      />
    </FormModalLayout>
  
    <div className="container-fluid d-lg-block p-3">
      <div className="row">
        <div className="search-bar mt-4">
          <form>
            <div className="input-icon">
              <span className="input-icon-addon">
                <img src={searchIcon} alt="" className="icon" />
              </span>
              <input
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Search"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className={hideFilter === true ? "sub-headings mt-4" : "d-none"}>
            <h2 className="property-sub-title">Filters</h2>
          </div>
        </div>
        <div
          className={
            hideFilter === true
              ? "col-md-9 d-flex justify-content-between"
              : "col-md-12 d-flex justify-content-between"
          }
        >
          <div className="sub-headings mt-4 d-flex gap-2 align-items-center">
            <h2 className="property-sub-title mb-0">Property</h2>
            <span className="badge badge-gray">
              <p className="badge-base-text">{propertiesData.length}</p>
            </span>
          </div>
          <div className="properties-dashbboard-btn-main btn-list mt-4">
            <button
              onClick={() => {
                setHideFilter(!hideFilter);
              }}
              className="filter-btn"
            >
              <img src={filterIcon} alt="" className="properties-filter-icon" />{" "}
              Filter
            </button>
            <button
              onClick={() => {
                navigate("/add-property-details");
              }}
              className="add-property-btn"
            >
              <img src={plusIcon} alt="" className="add-property-icon" /> Add
              Property
            </button>
          </div>
        </div>
      </div>
      <div className={hideFilter === true ? "d-flex gap-4" : "d-block"}>
        {/* Scrollable PropertyFilter */}
        <div className="property-filter-container" style={{ overflowY: "auto", height: hideFilter ? "calc(100vh - 289px)" : ""}}>
          <PropertyFilter
            hideFilter={hideFilter}
            filter={filter}
            setFilter={setFilter}
            setUpdate={setUpdate}
            onApply={(e) => {
              setFilter(e);
            }}
          />
        </div>
  
        {selectedTableItem.length >= 1 && (
          <div className="table-delete-icon mt-3">
            <button
              onClick={() => {
                setOpenDeletePropertiesModal(true);
              }}
              className="table-delete-btn next-btn-main"
            >
              <img src={trashIconWhite} alt="not found data" />
              Delete
            </button>
          </div>
        )}
  
        {/* Fixed CustomTable */}
        <div
          className={
            hideFilter === true
              ? "col-md-9 property-main-section mt-3"
              : "property-main-section mt-3"
          }
        >
          <CustomTable
            pagination={true}
            columns={columns}
            dataSource={propertiesData}
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            loading={isLoader}
          />
        </div>
      </div>
    </div>
  </>
  
  );
};

export default PropertiesDashboard;
