import EditFile from "Components/EditFile/EditFile";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const FilesEdit = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail={"Edit File"} back="all-files" />
      </div>
      <EditFile />
    </div>
  );
};

export default FilesEdit;
