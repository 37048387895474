import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import AddProspectDetailsEditModal from "Modals/AddProspectDetailsEditModal/AddProspectDetailsEditModal";

const AddProspectDetailsEditModalPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"Edit Prospects Details"}
          back="all-prospect"
        />
      </div>
      <AddProspectDetailsEditModal />
    </div>
  );
};

export default AddProspectDetailsEditModalPage;
