// IncomeVerification.js
import React, { useState, useEffect, useRef } from 'react';
import { PlaidLink } from 'react-plaid-link';
import { message } from 'antd';
import {
  generateIncomeVerificationToken,
  exchangePublicToken,
  handleIncomeVerificationFailure,
  handleIncomeVerificationSuccess
} from 'network/tenant/passport';

const IncomeVerification = ({ handleNext, data }) => {
  const [linkToken, setLinkToken] = useState(null);
  const plaidLinkRef = useRef(null); // Reference for PlaidLink

  useEffect(() => {
    const fetchLinkToken = async () => {
      try {
        const response = await generateIncomeVerificationToken(data);
        console.log(response);
        if(response?.apiCallStatus == "error"){
          message.error(response.error);
          return;
        }
        setLinkToken(response.linkTokenResponse.link_token);
      } catch (error) {
        console.error("Error generating link token: ", error);
        // Handle error scenario, e.g., set an error state
      }
    };

    fetchLinkToken();
  }, []);

  const onSuccess = async (public_token, metadata) => {
    console.log('Public Token: ', public_token);
    console.log('Metadata: ', metadata);

    try {
      const result = await exchangePublicToken(public_token);
      console.log('Result: ', result);

      console.log("Income verified");
      handleNext();
    } catch (error) {
      console.error("Error in income verification: ", error);
      // Handle error scenario
    }
  };

  const onFailure = async (err, metadata) => {
    console.log('Failure: ', err, metadata);
    try {
      const response = await handleIncomeVerificationFailure();
      console.log("Income verification failure response: ", response.data);
    } catch (error) {
      console.error("Error in handling failure: ", error);
    }
  };

  const onExit = async (err, metadata) => {
    if (err) {
      console.log('Exit with error: ', err);
    } else {
      console.log('Exit without error');
    }

    try {
      const response = await handleIncomeVerificationFailure();
      console.log("Income verification exit response: ", response.data);
    } catch (error) {
      console.error("Error in handling exit: ", error);
    }
  };

  // If there's no linkToken, return null to avoid rendering PlaidLink
  if (!linkToken) {
    return <p>Loading...</p>; // Optional loading state while waiting for the token
  }

  return (
    <PlaidLink
      ref={plaidLinkRef} // Reference to PlaidLink for automatic open
      token={linkToken}
      onSuccess={onSuccess}
      onFailure={onFailure}
      onExit={onExit}
      onLoad={() => {
        if (plaidLinkRef.current) {
          if(linkToken){
          plaidLinkRef.current.open(); // Automatically open the Plaid Link flow
          }
        }
      }}
    >
      Income Verification
      {/* No button text or visual representation */}
    </PlaidLink>
  );
};

export default IncomeVerification;
