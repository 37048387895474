import axios from "axios";
import config from "Helpers/config.js";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import logo from "../../assets/Dt logo.png";

const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
const fetchAccount = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/stripe/accounts`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteaccount = async (id) => {
  try {
    const response = await axios.delete(
      `${config.baseUrl}/api/stripe/Account/${id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchAccountProperty = async ({ id }) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/stripe/propertiesAvailableToAddAccount?landlordId=${id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchClientSecret = async (properties) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/stripe/connect`,
      properties,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchAccountbyId = async (id) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/stripe/accounts/${id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const postclientproperties = async (account_id, property) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/stripe/connect/${account_id}`,
      { properties: property },
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const Deleteproperty = async (data, account_id) => {
  try {
    // delete property from account by using DELETE method
    const response = await axios.delete(
      `${config.baseUrl}/api/stripe/connect/${account_id}`,
      {
        headers,
        data: data,
      }
    );

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchClientSecrets = async (account_id) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/stripe/stripeConnectAgain`,
      {
        account_id: account_id,
      },
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchtenant = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/landlord/tenants`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchInvoiceNo = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/getInvoiceNo`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
const getTenants = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/landLord/tenants/activeleases`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchTenantProperty = async (tenantId) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/landLord/tenants/activeleases`,
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    return error.data.message;
  }
};
const getLease = async (propertyId, unitId) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/leases/unit/${unitId}/${propertyId}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
const createinvoice = async (formdata) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/createInvoice`,
      formdata,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
const updateInvoiceCall = async (formdata, editMode) => {


  // Check if formdata is an instance of FormData and append editMode to it
  if (formdata instanceof FormData) {
    formdata.append("editMode", editMode);
  } else {
    // If formdata is not FormData, log an error or handle it accordingly
    console.error("Formdata is not an instance of FormData.");
    return;
  }
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/updateInvoice`,

      formdata,

      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
const updateRecurrsiveInvoice = async (formdata, editMode,recurringId) => {

  
  // Check if formdata is an instance of FormData and append editMode to it
  if (formdata instanceof FormData) {
    formdata.append("editMode", editMode);
    formdata.append("recurringId", recurringId);
  } else {
    // If formdata is not FormData, log an error or handle it accordingly
    console.error("Formdata is not an instance of FormData.");
    return;
  }
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/updateRecurrsiveInvoice`,

      formdata,

      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const recordInvoicePayment = async (invoiceNo) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/stripe/recordPyment`,
      { invoiceNo },
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const generateAndDownloadPDF = async (invoiceNo) => {
  try {
    // Fetch the invoice data from the server
    let response = await axios.get(
      `${config.baseUrl}/api/getInvoiceByInvoiceNo`,
      {
        params: {
          invoiceNo: invoiceNo, // Pass invoiceNo dynamically
        },
        headers: headers,
      }
    );

    const invoice = response.data.message.invoice.invoice;
    const extraData = response.data.message.invoice;

    if (!invoice) {
      console.error("Invoice not found");
      return;
    }

    let display_invoice_no = invoice.invoiceNo.substring(
      invoice.invoiceNo.indexOf("INV")
    );

    // Initialize jsPDF
    const doc = new jsPDF();

    // Add the logo
    doc.addImage(logo, "PNG", 140, 10, 50, 15); // Adjust positioning to match the layout in the image
    doc.setFontSize(11);

    doc.text("Poston Ave", 140, 29);
    doc.text("534-1477 Non,Av.Bury St, 10846", 140, 33);
    doc.text("Phone: 555-1234", 140, 37);

    // Title "Invoice Overview"
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text("Invoice Overview", 14, 35);

    // Styling for section headers
    doc.setFontSize(10);
    doc.setTextColor(100);

    // Invoice information layout
    doc.text(`INVOICE NO`, 14, 50);
    doc.text(`DATE`, 90, 50);
    doc.text(`DUE DATE`, 150, 50);

    // Dynamic invoice data
    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.text(`${display_invoice_no}`, 14, 55);
    doc.text(`${new Date(invoice.invoiceDate).toLocaleDateString()}`, 90, 55);
    doc.text(`${new Date(invoice.dueDate).toLocaleDateString()}`, 150, 55);

    // Billing and Company details layout
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text("BILLING DETAILS", 14, 70);
    // doc.text("COMPANY DETAILS", 105, 70);

    // Dynamic tenant/landlord information
    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.text(extraData.tenantName || "Tenant Name", 14, 75);
    doc.text(extraData.propertyName || "Property Address", 14, 80);
    doc.text(extraData.unitName || "Property Address", 14, 85);

    // doc.text(extraData.landlordName || 'Landlord Name', 105, 75);
    // doc.text(invoice.landlordAddress || 'Landlord Address', 105, 80);

    // Adding Line Items Table
    doc.autoTable({
      startY: 95,
      head: [["#", "DESCRIPTION", "CATEGORY", "AMOUNT"]],
      body: invoice.description.map((item, index) => [
        (index + 1).toString(),
        item.productName || "N/A",
        item.category || "N/A",
        `$${item.amount.toFixed(2)}`,
      ]),
      styles: {
        fontSize: 10,
        textColor: [0, 0, 0],
        lineColor: [221, 221, 221],
        lineWidth: 0.3,
      },
      headStyles: {
        fillColor: [248, 249, 250], // Light gray for table headers
        textColor: [0, 0, 0],
        fontStyle: "normal",
      },
      margin: { top: 90, left: 14, right: 14 },
      columnStyles: {
        0: { cellWidth: 10, halign: "center" },
        1: { cellWidth: 80 },
        2: { cellWidth: 40 },
        3: { cellWidth: 30, halign: "right" },
      },
      theme: "grid",
    });

    // Subtotal, Discount, and Total Amount
    let finalY = doc.previousAutoTable.finalY + 10;

    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.text(`Sub Total: $${invoice.subtotal.toFixed(2)}`, 14, finalY);

    if (invoice.discount && invoice.discount.value) {
      doc.text(
        `Discount: ${
          invoice.discount.type === "$" ? "$" : ""
        }${invoice.discount.value.toFixed(2)}`,
        14,
        finalY + 10
      );
    }

    // Other amounts (additional charges)
    if (invoice.otherAmounts && invoice.otherAmounts.length > 0) {
      invoice.otherAmounts.forEach((amount, index) => {
        doc.text(
          `${amount.description}: $${amount.amount.toFixed(2)}`,
          14,
          finalY + (index + 1) * 10 + 10
        );
      });
    }

    // Total Amount
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text(`Total Amount: $${invoice.total.toFixed(2)}`, 14, finalY + 30);

    // Footer "Thank You For Your Business"
    doc.setFontSize(12);
    doc.setTextColor(255, 100, 100); // Color for "Thank You" message
    doc.text("Thank You For your Business!", 14, finalY + 50);

    // Save the PDF
    doc.save(`${invoice.invoiceNo}.pdf`);
  } catch (error) {
    console.error("Error fetching invoice:", error);
  }
};

const generateAndPrintPDF = async (invoiceNo) => {
  try {
    // Fetch the invoice data from the server
    let response = await axios.get(
      `${config.baseUrl}/api/getInvoiceByInvoiceNo`,
      {
        params: {
          invoiceNo: invoiceNo, // Pass invoiceNo dynamically
        },
        headers: headers,
      }
    );

    const invoice = response.data.message.invoice.invoice;
    const extraData = response.data.message.invoice;

    if (!invoice) {
      console.error("Invoice not found");
      return;
    }

    let display_invoice_no = invoice.invoiceNo.substring(
      invoice.invoiceNo.indexOf("INV")
    );

    // Initialize jsPDF
    const doc = new jsPDF();

    // Add the logo
    doc.addImage(logo, "PNG", 140, 10, 50, 15); // Adjust positioning to match the layout in the image
    doc.setFontSize(11);

    doc.text("Poston Ave", 140, 29);
    doc.text("534-1477 Non, Av. Bury St, 10846", 140, 33);
    doc.text("Phone: 555-1234", 140, 37);

    // Title "Invoice Overview"
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text("Invoice Overview", 14, 35);

    // Styling for section headers
    doc.setFontSize(10);
    doc.setTextColor(100);

    // Invoice information layout
    doc.text(`INVOICE NO`, 14, 50);
    doc.text(`DATE`, 90, 50);
    doc.text(`DUE DATE`, 150, 50);

    // Dynamic invoice data
    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.text(`${display_invoice_no}`, 14, 55);
    doc.text(`${new Date(invoice.invoiceDate).toLocaleDateString()}`, 90, 55);
    doc.text(`${new Date(invoice.dueDate).toLocaleDateString()}`, 150, 55);

    // Billing and Company details layout
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text("BILLING DETAILS", 14, 70);

    // Dynamic tenant/landlord information
    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.text(extraData.tenantName || "Tenant Name", 14, 75);
    doc.text(extraData.propertyName || "Property Address", 14, 80);
    doc.text(extraData.unitName || "Property Address", 14, 85);

    // Adding Line Items Table
    doc.autoTable({
      startY: 95,
      head: [["#", "DESCRIPTION", "CATEGORY", "AMOUNT"]],
      body: invoice.description.map((item, index) => [
        (index + 1).toString(),
        item.productName || "N/A",
        item.category || "N/A",
        `$${item.amount.toFixed(2)}`,
      ]),
      styles: {
        fontSize: 10,
        textColor: [0, 0, 0],
        lineColor: [221, 221, 221],
        lineWidth: 0.3,
      },
      headStyles: {
        fillColor: [248, 249, 250], // Light gray for table headers
        textColor: [0, 0, 0],
        fontStyle: "normal",
      },
      margin: { top: 90, left: 14, right: 14 },
      columnStyles: {
        0: { cellWidth: 10, halign: "center" },
        1: { cellWidth: 80 },
        2: { cellWidth: 40 },
        3: { cellWidth: 30, halign: "right" },
      },
      theme: "grid",
    });

    // Subtotal, Discount, and Total Amount
    let finalY = doc.previousAutoTable.finalY + 10;

    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.text(`Sub Total: $${invoice.subtotal.toFixed(2)}`, 14, finalY);

    if (invoice.discount && invoice.discount.value) {
      doc.text(
        `Discount: ${
          invoice.discount.type === "$" ? "$" : ""
        }${invoice.discount.value.toFixed(2)}`,
        14,
        finalY + 10
      );
    }

    // Other amounts (additional charges)
    if (invoice.otherAmounts && invoice.otherAmounts.length > 0) {
      invoice.otherAmounts.forEach((amount, index) => {
        doc.text(
          `${amount.description}: $${amount.amount.toFixed(2)}`,
          14,
          finalY + (index + 1) * 10 + 10
        );
      });
    }

    // Total Amount
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text(`Total Amount: $${invoice.total.toFixed(2)}`, 14, finalY + 30);

    // Footer "Thank You For Your Business"
    doc.setFontSize(12);
    doc.setTextColor(255, 100, 100); // Color for "Thank You" message
    doc.text("Thank You For your Business!", 14, finalY + 50);

    // Create a Blob from the PDF and create a URL for it
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab for printing
    const printWindow = window.open(pdfUrl);
    printWindow.onload = () => {
      printWindow.print();
      // Optionally, you can revoke the object URL after printing
      URL.revokeObjectURL(pdfUrl);
    };
  } catch (error) {
    console.error("Error fetching invoice:", error);
  }
};

const getInvoiceByInvoiceNo = async (invoiceNO) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/getInvoiceByInvoiceNo`,
      {
        params: { invoiceNo: invoiceNO },
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
const getPaymentStatus = async (sessionId) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/stripe/payment-status/${sessionId}`,

      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
const postPayment = async (invoiceNo) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/post-payment`,
      { invoiceNo: invoiceNo },
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
const getAllInvoices = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/getLandlordAllInvoices`,

      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
const getAllTenantInvoices = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/getAllTenantInvoices`,

      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
const getAllRecurrsiveInvoices = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/getRecurringInvoices`,

      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
const getRecurrsiveInvoiceById = async(recussiveId) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/getRecurringInvoiceById?recurringId=${recussiveId}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
const filterAllInvoices = async (filter) => {
  const params = {
    fromDate: filter.fromDate,
    toDate: filter.toDate,
    status: filter.invoiceFilter, // Assuming this is the status
  };

  try {
    const response = await axios.get(`${config.baseUrl}/api/filterInvoices`, {
      params, // This will send the data as query parameters
      headers, // Headers go in this object
    });
    return response.data; // Ensure a Promise is returned
  } catch (error) {
    throw error; // Throw error to handle it properly in handleFilter
  }
};
const archiveInvoice = async (invoiceNo) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/archiveInvoiceById?invoiceNo=${invoiceNo}`, // Pass invoiceNo as a query parameter
      {},
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const selectinvoicecatogries = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/invoice/getInvoiceCategories`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const connectstripeaccount = async (account_id) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/stripe/connectConfirm`,
      { account_id: account_id },
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const FetchUser = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/auth/landlord/userinfo`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const FetchUserTenant = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/auth/tenant/userinfo`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const FetchUserLandlordUser = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/auth/landlord/user/userinfo`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const FetchUserOwnerInfo = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/auth/landlord/user/ownerinfo`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const FetchLandlordPlans = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/landlord/plans`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const FetchPaymentMethod = async (id) => {
  try {
    const response = await axios.get(
      `${
        config.baseUrl
      }/api/stripe/getCustomerPaymentMethod?email=${localStorage.getItem(
        "email"
      )}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const addPaymentMethod = async (formdata) => {
  try {
    const response = await axios.post(
      `https://api.stripe.com/v1/tokens`,
      formdata,
      { headers: { Authorization: `Bearer ${config.stripe_publish_key}` } }
    );
    return response.data;
  } catch (error) {}
};

const addpostpaymnent = async (data) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/stripe/addPaymentMethod`,
      {
        email: localStorage.getItem("email"),
        token: data,
        role: localStorage.getItem("role"),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {}
};

const sendVerificationEmail = async () => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/landlord/user/send-verification-email`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const sendVerificationEmailToTenant = async () => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/tenant/user/send-verification-email`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  }
  catch (error) {
    return error.response.data;
  }
};

export {
  fetchAccount,
  deleteaccount,
  fetchAccountProperty,
  fetchClientSecret,
  fetchAccountbyId,
  postclientproperties,
  Deleteproperty,
  fetchClientSecrets,
  fetchtenant,
  fetchInvoiceNo,
  getTenants,
  fetchTenantProperty,
  getLease,
  createinvoice,
  getAllInvoices,
  archiveInvoice,
  getAllTenantInvoices,
  getAllRecurrsiveInvoices,
  getRecurrsiveInvoiceById,
  filterAllInvoices,
  getPaymentStatus,
  selectinvoicecatogries,
  connectstripeaccount,
  FetchUser,
  FetchUserTenant,
  FetchUserLandlordUser,
  FetchLandlordPlans,
  FetchPaymentMethod,
  addPaymentMethod,
  addpostpaymnent,
  postPayment,
  generateAndDownloadPDF,
  generateAndPrintPDF,
  getInvoiceByInvoiceNo,
  FetchUserOwnerInfo,
  updateInvoiceCall,
  updateRecurrsiveInvoice,
  recordInvoicePayment,
  sendVerificationEmail,
  sendVerificationEmailToTenant,
};
