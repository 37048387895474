import NonRenewal from "Components/NonRenewal/NonRenewal";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const LeasesNonRenew = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle={"Non-Renewal Notice"} />
      </div>
      <NonRenewal />
    </div>
  );
};

export default LeasesNonRenew;
