import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import AddProspectDetails from "Components/AddProspectDetails/AddProspectDetails";
const AddProspectDetailsPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"Add Prospects Details"}
          back="all-prospect"
        />
      </div>
      <AddProspectDetails />
    </div>
  );
};

export default AddProspectDetailsPage;
