import CreateNew from "Components/CreateNew/CreateNew";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const CreateNewPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle="Create New" />
      </div>
      <CreateNew />
    </div>
  );
};

export default CreateNewPage;
