import TenantTaskDetails from "Components/TenantTaskDetails/TenantTaskDetails";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const TasksDetail = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail={"Tasks Details"} back="all-task" />
      </div>
      <TenantTaskDetails />
    </div>
  );
};

export default TasksDetail;
