import AllReports from "Components/AllReports/AllReports";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const Reports = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle={"Reports"} />
      </div>
      <AllReports />
    </div>
  );
};

export default Reports;
