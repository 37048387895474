import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import PropertiesUnitsView from "Components/PropertiesUnitsView/PropertiesUnitsView";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";

const PropertiesUnitsViewPage = () => {
  const { property_id } = UseUrlParamsHook();
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"Unit Details"}
          back={`property-details-view?id=${property_id}`}
        />
      </div>
      <PropertiesUnitsView />
    </div>
  );
};

export default PropertiesUnitsViewPage;
