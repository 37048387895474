import TitleTopBar from "Helpers/TitleTopBar";
import EditVendorDetailsModal from "Modals/EditVendorsDetailsModal/EditVendorsDetailsModal";
import React from "react";
import { useParams } from "react-router-dom";

const VendorsEdit = () => {
    const { id } = useParams();
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"Edit Vendor Details"}
          back="all-vendor"
        />
      </div>
      <EditVendorDetailsModal id={id} />
    </div>
  );
};

export default VendorsEdit;
