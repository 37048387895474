import { useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import GoogleAuth from "Hooks/GoogleAuth";
import { AuthLayout } from "Components/GeneralComponents";
import SignUpForm from "./SignUpForm";
import { tenantSignup } from "network/tenant/auth";
import { TenantSignUpValidation } from "utils/validations";

const TenantSignUp = () => {

  const [agreeTermsChecked, setAgreeTermsChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [facebookLogin, setFacebookLogin] = useState(null);
  const [form, setForm] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    cpassword: "",
    phone: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };
  const resolveFacebook = (response) => {
    console.log(response);
    setFacebookLogin(response.data);
    console.log(facebookLogin);
  };

  const rejectFacebook = (error) => {
    console.log(error);
  };

  const tenantSignUp = async () => {
    const newErrors = TenantSignUpValidation(form);
    setErrors(newErrors);
    console.log("New Errors >>", newErrors);
    if (Object.keys(newErrors).length === 0 && agreeTermsChecked) {
      setLoader(true);
      tenantSignup(form).then((response) => {
        setLoader(false);
        if (response.apiCallStatus === "success") {
          message.success("SignUp Successful");
          navigate("/tenant/login");
        } else if (response.status === 409) {
          message.error("User Already Exists");
        }
      });
    }
  };

  const googleAuth = () => {
    GoogleAuth("tenant");
  };

  return (
    <>
      <AuthLayout loader={loader}>
        <SignUpForm
          form={form}
          errors={errors}
          handleChange={handleChange}
          tenantSignUp={tenantSignUp}
          googleAuth={googleAuth}
          setAgree={setAgreeTermsChecked}
          resolveFacebook={resolveFacebook}
          rejectFacebook={rejectFacebook}
        />
      </AuthLayout>
    </>
  );
};

export default TenantSignUp;
