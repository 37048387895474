import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import PropertyAddAccount from "Components/PropertyAddAccount/PropertyAddAccount";
const PropertyAddAccountPage = () => {
  return (
    <>
      <div className="container-fluid">
        <TitleTopBar topBarTitle={"Accounting"} />
      </div>
      <PropertyAddAccount />
    </>
  );
};

export default PropertyAddAccountPage;
