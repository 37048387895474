import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import TenantDetailsView from "Components/TenantDetailsView/TenantDetailsView";

const TenantDetailsViewPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail={"Tenant Details"} back="all-tenants" />
      </div>
      <TenantDetailsView />
    </div>
  );
};

export default TenantDetailsViewPage;
