import VendorDetailView from "Components/VendorDetailView/VendorDetailView";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const VendorsDetail = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail={"Vendor Details"} back="all-vendor" />
      </div>
      <VendorDetailView />
    </div>
  );
};

export default VendorsDetail;
