import React from "react";

function Cancel({ width = 21, height = 21 }) {
  return (
    <>
      <svg
       width={width}
       height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 34 34"
        fill="currentColor"

        
      >
        <g id="Layer_2" data-name="Layer 2">
          <path d="M28.922,7.615a1,1,0,0,0-.216-.323l-6-6a1,1,0,0,0-.323-.216A.988.988,0,0,0,22,1H8.579A3.582,3.582,0,0,0,5,4.579V29.421A3.582,3.582,0,0,0,8.579,33H25.421A3.582,3.582,0,0,0,29,29.421V8A.992.992,0,0,0,28.922,7.615ZM23,4.414,25.586,7H23ZM25.421,31H8.579A1.58,1.58,0,0,1,7,29.421V4.579A1.58,1.58,0,0,1,8.579,3H21V8a1,1,0,0,0,1,1h5V29.421A1.58,1.58,0,0,1,25.421,31Z" />
          <path d="M21.242,14.757a1,1,0,0,0-1.414,0L17,17.586l-2.828-2.829a1,1,0,1,0-1.414,1.414L15.586,19l-2.828,2.829a1,1,0,1,0,1.414,1.414L17,20.414l2.828,2.829a1,1,0,1,0,1.414-1.414L18.414,19l2.828-2.829A1,1,0,0,0,21.242,14.757Z" />
        </g>
      </svg>
    </>
  );
}

export default Cancel;
