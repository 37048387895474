import TaskDetails from "Components/TaskDetails/TaskDetails";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const TaskDetailsPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail={"Tasks Details"} back="all-task" />
      </div>
      <TaskDetails />
    </div>
  );
};

export default TaskDetailsPage;
