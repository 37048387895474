import React from "react";

const archive = ({ width = 21, height = 21 }) => {
  return (
    <>
      {/* <svg
        width={width}
        height={height}
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.875 6.58317H1.125M10.3333 0.916504V3.74984M4.66667 0.916504V3.74984M5.375 10.8332L6.79167 12.2498L9.97917 9.06234M4.525 15.0832H10.475C11.6651 15.0832 12.2602 15.0832 12.7147 14.8516C13.1146 14.6478 13.4397 14.3227 13.6434 13.9229C13.875 13.4683 13.875 12.8733 13.875 11.6832V5.73317C13.875 4.54306 13.875 3.948 13.6434 3.49344C13.4397 3.0936 13.1146 2.76851 12.7147 2.56478C12.2602 2.33317 11.6651 2.33317 10.475 2.33317H4.525C3.33489 2.33317 2.73983 2.33317 2.28527 2.56478C1.88543 2.76851 1.56034 3.0936 1.35661 3.49344C1.125 3.948 1.125 4.54306 1.125 5.73317V11.6832C1.125 12.8733 1.125 13.4683 1.35661 13.9229C1.56034 14.3227 1.88543 14.6478 2.28527 14.8516C2.73983 15.0832 3.33489 15.0832 4.525 15.0832Z"
          stroke="#475467"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg> */}

      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
        />
      </svg>
    </>
  );
};

export default archive;
