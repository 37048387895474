import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import searchIcon from "assets/search.png";
import moment from "moment";
import { Avatar } from "antd";
import { FirstCharacterIcon } from "Components/GeneralComponents";
import { getLandlordArchive } from "network/landlord/chat";
import { getTenantArchive } from "network/tenant/chat";
import ChatUserList from "../ChatUserList/ChatUserList";
const ArchiveChatUser = () => {
  const [messages, setMessages] = useState([]);
  const role = localStorage.getItem("role");
  useEffect(() => {
    if (role === "landlord" || role === "user") {
      getLandlordArchive().then((res) => {
        if (res.apiCallStatus === "success") {
          setMessages(res.message);
        }
      });
    } else {
      getTenantArchive().then((res) => {
        if (res.apiCallStatus === "success") {
          setMessages(res.message);
        }
      });
    }
  }, []);
  const navigate = useNavigate();
  return (
    // <>
    //   <div
    //     className="container-fluid bg-white p-3 "
    //     style={{ height: "calc(100vh - 120px)", overflowY: "hidden" }}
    //   >
    //     <div className="row sticky-top ">
    //       <div className="col-md-12">
    //         <div className="chat-search-bar  position-relative ">
    //           <input
    //             type="text"
    //             placeholder="Search"
    //             className="form-control search-form-control-task"
    //           />
    //           <div className="search-icon-task">
    //             <img src={searchIcon} alt="" />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="row mt-4 archivedmessages ">
    //       <div className="col-md-12">
    //         {messages.map((item) => {
    //           const user =
    //             role === "landlord" || role === "user"
    //               ? item.tenant
    //               : item.landlord;
    //           return (
    //             <>
    //               <div
    //                 onClick={() => {
    //                   navigate(`/archive-chat?id=${item.id}`);
    //                 }}
    //                 className="archive-chat-user-list mt-3 d-flex align-items-center gap-3 position-relative"
    //               >
    //                 {user?.profileImage ? (
    //                   <Avatar
    //                     src={user.profileImage}
    //                     size="large"
    //                     style={{ backgroundColor: "" }}
    //                   />
    //                 ) : (
    //                   <FirstCharacterIcon name={user?.firstName[0]} />
    //                 )}
    //                 <div className="archive-chat-user-text overflow-hidden ">
    //                   <p className="mb-0 text-dark fw-medium">
    //                     {user?.firstName + " " + user?.lastName}
    //                   </p>
    //                   <p
    //                     className="mb-0 normal-grey-text"
    //                     style={{
    //                       textOverflow: "ellipsis",
    //                       whiteSpace: "nowrap",
    //                       overflow: "hidden",
    //                     }}
    //                   >
    //                     {item.text}
    //                   </p>
    //                 </div>
    //                 <div className="archive-chat-user-time position-absolute ">
    //                   <p className="mb-0 normal-grey-text">
    //                     {" "}
    //                     {moment(item.timestamp).format("h:mm a").toUpperCase()}
    //                   </p>
    //                 </div>
    //               </div>
    //             </>
    //           );
    //         })}
    //       </div>
    //     </div>
    //   </div>
    // </>
    <div
      className="container-fluid bg-white p-3"
      style={{ height: "calc(100vh - 120px)", overflowY: "hidden" }}
    >
      <ChatUserList archive={true} />
    </div>
  );
};

export default ArchiveChatUser;
