import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import AllProspect from "Components/AllProspect/AllProspect";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";

const AllProspectPage = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle={"All Prospect"} />
      </div>
      {!["Owner/Executive", "Property Manager", "Leasing Agent"].some((role) =>
        myUserRoles?.includes(role)
      ) && myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <AllProspect />
      )}
      {/* <AllProspect /> */}
    </div>
  );
};

export default AllProspectPage;
