import AllTasks from "Components/AllTasks/AllTasks";
import TitleTopBar from "Helpers/TitleTopBar";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";
import React from "react";

const Tasks = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle="All Tasks" />
      </div>
      {!["Owner/Executive", "Property Manager", "Maintenance Technician"].some(
        (role) => myUserRoles?.includes(role)
      ) && myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <AllTasks />
      )}
      {/* <AllTasks /> */}
    </div>
  );
};

export default Tasks;
