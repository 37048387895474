import TenantPassport from "Components/TenantPassport/TenantPassport";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const Passport = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle={"Tenant Passport"} />
      </div>
      <TenantPassport />
    </div>
  );
};

export default Passport;
