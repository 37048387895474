// PaymentForm.js
import React, { useEffect, useState } from "react";
// import { CardElement, useStripe, useElements, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import "./PaymentForm.css"; // Custom CSS for any additional styling
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import "./PaymentForm.css";
import { message } from "antd";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentForm = ({ setClientSecret, handlePaymentAuthorization }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false); // Track processing state

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || isProcessing) {
      return;
    }

    setIsProcessing(true); // Disable the submit button

    const cardNumberElement = elements.getElement(CardNumberElement);

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      setClientSecret,
      {
        payment_method: {
          card: cardNumberElement,
        },
      }
    );

    if (error) {
      console.error("Payment error:", error);
      message.error(error.message);
    } else {
      console.log("Payment authorized:", paymentIntent);
      handlePaymentAuthorization();
    }

    setIsProcessing(false); // Re-enable the submit button
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <h4 className="mb-3">Authorize Payment for passport verification</h4>
      {/* <p className="text-muted">If any of step failed, Amount will not be captured!</p> */}

      {/* Payment Method Options */}
      {/* <div className="mb-3 d-flex">
        <button className="btn btn-outline-secondary me-2">
          <img src="visa-logo.png" alt="Visa" width="40" />
        </button>
        <button className="btn btn-outline-secondary">
          <img src="mastercard-logo.png" alt="Mastercard" width="40" />
        </button>
      </div> */}

      {/* Payment Plan Info */}
      <div className="border rounded p-3 mb-4">
        <h6 className="mb-0">Amount: $50</h6>
        <p className="small text-muted">
          Report will only be delivred, If the amount is captured successfully!.
        </p>
      </div>

      {/* Name on Card */}
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name on card
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          placeholder="Olivia Rhye"
          required
        />
      </div>

      {/* Card Number */}
      <div className="mb-3">
        <label htmlFor="card-number" className="form-label">
          Card number
        </label>
        <CardNumberElement
          id="card-number"
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": { color: "#aab7c4" },
              },
              invalid: { color: "#9e2146" },
            },
          }}
          className="form-control"
        />
      </div>

      {/* Expiry Date and CVV in Row */}
      <div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="card-expiry" className="form-label">
            Expiry
          </label>
          <CardExpiryElement
            id="card-expiry"
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": { color: "#aab7c4" },
                },
                invalid: { color: "#9e2146" },
              },
            }}
            className="form-control"
          />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="card-cvc" className="form-label">
            CVV
          </label>
          <CardCvcElement
            id="card-cvc"
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": { color: "#aab7c4" },
                },
                invalid: { color: "#9e2146" },
              },
            }}
            className="form-control"
          />
        </div>
      </div>

      {/* Save Card Checkbox */}
      <div className="form-check mb-3">
        <input type="checkbox" className="form-check-input" id="saveCard" />
        <label className="form-check-label" htmlFor="saveCard">
          Save my card details
        </label>
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        className="btn btn-primary w-100"
        disabled={!stripe || isProcessing}
      >
        {isProcessing ? "Processing..." : "Pay Now"}
      </button>
    </form>
  );
};

// PassportCheckout Component
const PassportCheckout = ({ handlePaymentAuthorization }) => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const response = await axios.get(
          "http://localhost:9000/api/stripe/authorizePassportPayment",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log("Payment: ", response);

        setClientSecret(response?.data?.message?.clientSecret);
      } catch (error) {
        console.error("Error creating payment intent:", error);
      }
    };

    createPaymentIntent();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        setClientSecret={clientSecret}
        handlePaymentAuthorization={handlePaymentAuthorization}
      />
    </Elements>
  );
};

export default PassportCheckout;
