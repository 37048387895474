import React, { useEffect, useState } from "react";
import { Radio, ConfigProvider, message } from "antd";
import { SpinnerLoader } from "Components/GeneralComponents";
import {ReactComponent as CheckOrange} from "assets/svg/check_orange.svg"

const PremiumPackageCard = ({
  icon,
  item,
  planType,
  selectedPlan,
  handlePlanValue,
  UpdateSubscription,
  billingInterval,
  showButton = false,
  isTrialCard = false,
  isLoader,
}) => {
  const price =
    billingInterval === "Yearly" ? item.priceYearly : item.priceMonthly;

  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(
      (selectedPlan?.planName === planType && selectedPlan?.price === price) ||
        isTrialCard
    );
    console.log("Selected Plan", selectedPlan);
    console.log("Is Selected", selectedPlan?.plan?.planName === planType);
  }, [selectedPlan, billingInterval]);

  const handleButtonClick = () => {
    // handlePlanValue(planType, price);
    if (isSelected) {
      message.success("This plan is already selected");
    } else {
      const updateOption =
        selectedPlan?.price < price ? "upgrade" : "downgrade";
      UpdateSubscription(updateOption, planType, price);
    }
  };

  return (
    <div
  key={planType}
  className="col-xxl-4 col-lg-6 col-md-6 col-sm-12 my-5 d-flex justify-content-evenly"
>
  <div className="land-lord-package rounded-4 border d-flex flex-column" style={{ height: "100%" }}>
    <div className="package-name text-start border-bottom d-flex align-items-center justify-content-between">
      <div className="package-name-and-icon-container">
        <img src={icon} alt="" /> {planType}
      </div>
      {!showButton && (
        <div className="package-select-radio-container">
          <Radio.Group
            onChange={(e) => handlePlanValue(planType, price)}
            value={selectedPlan.planName === planType ? 1 : 0}
          >
            <ConfigProvider
              theme={{
                components: {
                  Radio: {
                    colorPrimary: "#EF6B3E",
                    radioSize: 18,
                    fontSize: 16,
                    fontFamily: "Montserrat",
                  },
                },
              }}
            >
              <Radio value={1}></Radio>
            </ConfigProvider>
          </Radio.Group>
        </div>
      )}
    </div>

    <div className="package-details text-start flex-grow-1">
      <span className="limited-time-text-subscription">
        Limited time only
      </span>
      <p className="mt-3">
        <span className="fs-1 package-big-text text-dark">
          {price == "Free"
            ? "Free"
            : `${billingInterval === "Yearly" ? price / 12 : price}`}
        </span>{" "}
        <span className="fs-7">
          {price == "Free" ? "only for 15 days" : "per month"}
          <br />
          {item?.description}
        </span>
      </p>
      {item?.features?.map((feature, index) => (
        <p key={index} className="" style={{ marginBottom: "10px" }}>
          <CheckOrange style={{stroke:"#0000FF", width:"7%"}} className="mx-1"></CheckOrange>
          {feature}
        </p>
      ))}
    </div>

    <div className="mb-4 mx-4">
      {showButton && (
        <button
          onClick={handleButtonClick}
          className={isSelected ? "save-btn p-2" : "table-delete-btn w-100"}
        >
          {isSelected
            ? "Selected"
            : selectedPlan?.price === "Free"
            ? "Upgrade"
            : selectedPlan?.price < price
            ? "Upgrade"
            : "Downgrade"}
          {isLoader && <SpinnerLoader />}
        </button>
      )}
    </div>
  </div>
</div>

  );
};

export default PremiumPackageCard;
