import ArchiveChats from "Components/Chat/ArchiveChats/ArchiveChats";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const ChatsArchiveUser = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail="Archived" back="archive-user" />
      </div>
      <ArchiveChats />
    </div>
  );
};

export default ChatsArchiveUser;
