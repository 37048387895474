import AllFiles from "Components/AllFiles/AllFiles";
import TitleTopBar from "Helpers/TitleTopBar";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";
import React from "react";

const Files = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle={`All Files`} />
      </div>
      {!["Owner/Executive", "Property Manager"].some((role) =>
        myUserRoles?.includes(role)
      ) && myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <AllFiles />
      )}
      {/* <AllFiles /> */}
    </div>
  );
};

export default Files;
