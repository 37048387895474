import ResponsiveSetting from "Components/ResponsiveSetting/ResponsiveSetting";
import Setting from "Components/Setting/Setting";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const SettingsPage = () => {
  return (
    <div className="row vh-100 w-100">
  {/* For large screens (lg and above) */}
  <div className="col-lg-12 mt-3 d-none d-lg-block">
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle="Setting" />
      </div>
      <Setting />
    </div>
  </div>

  {/* For medium screens (md and below) */}
  <div className="col-md-12 mt-3 d-lg-none">
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle="Setting" />
      </div>
      <ResponsiveSetting />
    </div>
  </div>
</div>

  );
};

export default SettingsPage;
