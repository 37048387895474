import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import AddUnitDetails from "Components/AddUnitDetails/AddUnitDetails";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";

const AddUnitDetailsPage = () => {
  const { id } = UseUrlParamsHook();
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"Add Unit Details"}
          back={`property-details-view?id=${id}`}
        />
      </div>
      <AddUnitDetails />
    </div>
  );
};

export default AddUnitDetailsPage;
