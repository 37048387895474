import RenewLease from "Components/RenewLease/RenewLease";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const LeasesRenew = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail="Renew Lease" back="lease-detail" />
      </div>
      <RenewLease />
    </div>
  );
};

export default LeasesRenew;
