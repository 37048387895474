import { useEffect, useState } from "react";
import { Avatar, message } from "antd";
import settingIcon from "assets/three-dots.png";
import trashIconWhite from "assets/trash-icon-white.png";
import { Link, useNavigate } from "react-router-dom";
import pdfIcon from "assets/pdf.jpg";
import excelIcon from "assets/excel.jpg";
import wordIcon from "assets/word.jpg";
import {
  CustomTable,
  DeleteModal,
  FilterRow,
  RecordMenuPopup,
} from "Components/GeneralComponents";
import {
  getFiles,
  downloadFile,
  deleteFile,
  deleteMultipleFiles,
} from "network/landlord/file";
const AllFiles = () => {
  // States start

  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [key, setKey] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [fileData, setFileData] = useState([]);
  const [filtredFiles, setFiltredFiles] = useState([]);
  const [isRerender, setIsRerender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenBulkDeleteModal, setIsOpenBulkDeleteModal] = useState(false);
  // States end

  const navigate = useNavigate();

  // Fetch Data
  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    range: "",
    property: "",
  });

  const removeEmptyValues = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([_, value]) => value !== "" && value !== undefined && value !== null
      )
    );
  };

  useEffect(() => {
    const filters = removeEmptyValues(filter);

    setIsLoading(true);
    getFiles(filters).then((response) => {
      setIsLoading(false);
      if (response.apiCallStatus === "success") {
        setFileData(
          response.message.files.map((e, index) => ({
            key: index + 1,
            id: e.id,
            fileName: [e.url, e.fileName, e.name],
            name: e.name,
            property: e.relatedToProperty?.title,
            owner: localStorage.getItem("name"),
            date: new Date(e?.createdAt).toLocaleDateString(),
          }))
        );
      }
    });
  }, [isRerender]);

  const handleResetFilter = () => {
    setFilter({
      search: "",
      fromDate: "",
      toDate: "",
      range: "",
      property: "",
    });
    setIsRerender(!isRerender);
  };

  // handle search filter
  useEffect(() => {
    if (filter.search) {
      const filteredData = fileData.filter((data) => {
        if (data.name.toLowerCase().includes(filter.search.toLowerCase()))
          return data;
        else if (
          data.fileName[1].toLowerCase().includes(filter.search.toLowerCase())
        )
          return data;
        else if (
          data.property.toLowerCase().includes(filter.search.toLowerCase())
        )
          return data;
        else if (data.owner.toLowerCase().includes(filter.search.toLowerCase()))
          return data;
        else if (data.date.toLowerCase().includes(filter.search.toLowerCase()))
          return data;
      });
      setFiltredFiles(filteredData);
    } else {
      setFiltredFiles(fileData);
    }
  }, [filter.search, fileData]);

  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = fileData.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const columns = [
    {
      title: "File",
      dataIndex: "fileName",
      render: (text) => (
        <>
          <Link
            to=""
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {" "}
            <div className="table-file-container d-flex align-items-center gap-3">
              <div className="table-file-img">
                <img
                  className="property-table-image mw_40 mh_40 me-2 rounded-5"
                  src={`${
                    text[0].includes("pdf")
                      ? pdfIcon
                      : text[0].includes("xlsx")
                      ? excelIcon
                      : text[0].includes("docx")
                      ? wordIcon
                      : text[0]
                  }`}
                  alt=""
                />
              </div>
              <div className="table-file-text">
                <p
                  onClick={() => downloadFile(text[0], text[1])}
                  className="m-0 all-files-table-name-text"
                >
                  {text[2]}
                </p>
              </div>
            </div>
          </Link>
        </>
      ),
    },
    {
      title: "File Name",
      dataIndex: "fileName",
      render: (text) => (
        <>
          {/* <Link
            to=""
            onClick={(e) => {
              e.preventDefault();
            }}
          > */}{" "}
          <div className="table-file-container d-flex align-items-center gap-3">
            {/* <div className="table-file-img">
                <img
                  className="property-table-image mw_40 mh_40 me-2 rounded-5"
                  src={`${
                    text[0].includes("pdf")
                      ? pdfIcon
                      : text[0].includes("xlsx")
                      ? excelIcon
                      : text[0].includes("docx")
                      ? wordIcon
                      : text[0]
                  }`}
                  alt=""
                />
              </div> */}
            <div className="table-file-text">
              <p
                // onClick={() => API.downloadFile(text[0], text[1])}
                className="m-0 all-files-table-name-text"
              >
                {text[1]}
              </p>
            </div>
          </div>
          {/* </Link> */}
        </>
      ),
    },
    {
      title: "Properties",
      dataIndex: "property",
      render: (text) => (
        <>
          <span className="tenant_table_properties_main_text">{text}</span>
          <br />
        </>
      ),
    },
    {
      title: "Owner",
      dataIndex: "owner",
      render: (text, type) => (
        <>
          <Avatar
            style={{
              backgroundColor: "#EF6B3E",
              verticalAlign: "middle",
            }}
            size="large"
          >
            {localStorage.getItem("name")[0]}
          </Avatar>
          <span className="tenant_table_name_text ms-3">{text}</span>
        </>
      ),
    },
    {
      title: "Date Uploaded",
      dataIndex: "date",
      render: (text) => (
        <>
          <span className="tenant_table_properties_sub_text">{text}</span>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <img src={settingIcon} alt="" />
            <RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              fields={{ download: true, delete: true }}
              handler={{
                handleDownload: () =>
                  downloadFile(setting.fileName[0], setting.fileName[1]),
                handleDeleteRecord: () => {
                  setDeleteId(setting.id);
                  setOpenDeleteModal(true);
                },
              }}
            />
          </div>
        </>
      ),
    },
  ];
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem(selectedRows.map((item) => item.id));
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };


  // handle delete file
  const handleDeleteFile = () => {
    setIsLoader(true);
    deleteFile(deleteId).then((res) => {
      setIsLoader(false);
      if (res.apiCallStatus === "success") {
        message.success(res.message?.message);
        setOpenDeleteModal(false);
        setIsRerender(!isRerender);
      } else {
        message.error(res.error.message);
      }
    });
  };

  // handle bulk delete file
  const handleBulkDeleteFile = () => {
    setIsLoader(true);
    deleteMultipleFiles(selectedTableItem).then((res) => {
      setIsLoader(false);
      if (res.apiCallStatus === "success") {
        message.success(res.message?.message);
        setIsOpenBulkDeleteModal(false);
        setIsRerender(!isRerender);
        setSelectedTableItem([]);
      } else {
        message.error(res.error.message);
      }
    });
  };

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        message={"Are you sure you want to delete this File?"}
        deleteCall={handleDeleteFile}
        isLoading={isLoader}
      />

      <DeleteModal
        isOpen={isOpenBulkDeleteModal}
        onClose={() => setIsOpenBulkDeleteModal(false)}
        message={"Are you sure you want to delete these files?"}
        deleteCall={handleBulkDeleteFile}
        isLoading={isLoader}
      />

      <div className="container-fluid bg-white p-3 ">
        <FilterRow
          filter={filter}
          setFilter={setFilter}
          btnTitle={"Add New File"}
          btnHandler={() => navigate("/new-files")}
          handleSubmit={() => setIsRerender(!isRerender)}
          handleReset={handleResetFilter}
          filterList={{
            date: true,
            range: true,
            property: true,
          }}
        />
        <div className="row mt-3">
          <div className="col-md-12">
            {selectedTableItem.length >= 1 && (
              <div className="table-delete-icon mt-3">
                <button
                  onClick={() => setIsOpenBulkDeleteModal(true)}
                  className="table-delete-btn next-btn-main"
                >
                  <img src={trashIconWhite} alt="" />
                  Delete
                </button>
              </div>
            )}
            <div className="table-container">
              <CustomTable
                loading={isLoading}
                pagination={true}
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={filtredFiles}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllFiles;
