import tenantImg from "assets/fi_11608240.png";
import prospectImg from "assets/prospects.png";
import vendorImg from "assets/fi_12371498.png";
import serviceProfessionalImg from "assets/service-professional.png";
import { Link } from "react-router-dom";
import UserPermission from "libs/UserPermission";
import { useEffect } from "react";
import { Hidden } from "@mui/material";
import { height } from "@mui/system";
import { LockWrapper } from "Components/GeneralComponents";

const Contacts = () => {
  const { FetchUserRole, role, ROLE } = UserPermission();
  useEffect(() => {
    FetchUserRole();
  }, []);

  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  console.log("retrievedArray ------======>", myUserRoles);

  return (
    <>
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col-md-12 text-center">
            <p className="contact-page-title my-4">Select a Contact Category: Prospect, Tenant, Vendor, or Service Professional</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-4 m-3">
            <div className="card contact-page-card-main">
              <Link to="/all-prospect">
                <div className="card-body p-4">
                  <img
                    src={prospectImg}
                    alt=""
                    className="d-block mx-auto mb-3 contact-page-card-img"
                  />
                  <p className="text-center contact-page-sub-title">
                    Prospects
                  </p>
                  <p className="contact-page-sub-text text-center mb-0">
                    Potential tenants interested in renting a property or unit.
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-4 m-3">
            <div className="card contact-page-card-main">
              <Link to="/all-tenants">
                <div className="card-body p-4">
                  <img
                    src={tenantImg}
                    alt=""
                    className="d-block mx-auto mb-3 contact-page-card-img"
                  />
                  <p className="text-center contact-page-sub-title">Tenants</p>
                  <p className="contact-page-sub-text text-center mb-0">
                    Current tenants occupying your managed properties.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-4 m-3">
            <LockWrapper
              isLocked={
                [
                  "Accounting/Finance",
                  "Maintenance Technician",
                  "Leasing Agent",
                ].some((role) => myUserRoles?.includes(role))
                  ? true
                  : false
              }
            >
              <div className="card contact-page-card-main">
                <Link to="/all-vendor">
                  <div className="card-body p-4">
                    <img
                      src={vendorImg}
                      alt=""
                      className="d-block mx-auto mb-3 contact-page-card-img"
                      style={{ height: "160px" }}
                    />
                    <p className="text-center contact-page-sub-title">
                      Vendors
                    </p>
                    <p className="contact-page-sub-text text-center mb-0">
                      Suppliers providing essential goods or services for
                      property maintenance and management.
                    </p>
                  </div>
                </Link>
              </div>
            </LockWrapper>
          </div>
          <div className="col-md-4 m-3">
            <LockWrapper
              isLocked={
                [
                  "Accounting/Finance",
                  "Maintenance Technician",
                  "Leasing Agent",
                ].some((role) => myUserRoles?.includes(role))
                  ? true
                  : false
              }
            >
              <div className="card contact-page-card-main">
                {/* <Link to="/all-service-professional"> */}
                <div className="card-body p-4">
                  <img
                    src={serviceProfessionalImg}
                    alt=""
                    className="d-block mx-auto mb-3 contact-page-card-img"
                  />
                  <p className="text-center contact-page-sub-title">
                    Service Professional
                  </p>
                  <p className="contact-page-sub-text text-center mb-0">
                    Skilled professionals or contractors performing repairs and
                    specialized services
                  </p>
                </div>
                {/* </Link> */}
              </div>
            </LockWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contacts;
