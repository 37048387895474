import TenantPassportProfile from "Components/TenantPassportProfile/TenantPassportProfile";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const PassportProfile = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"Tenant Passport"}
          back="tenant-passport"
        />
      </div>
      <TenantPassportProfile />
    </div>
  );
};

export default PassportProfile;
