import { Form } from "react-router-dom";

const prospectFormData = (
  form,
  emergencyContacts,
  pets,
  vehicles,
  dependentInfo,
  ProfileImages
) => {
  var formData = new FormData();

  if (ProfileImages.length !== 0) {
    formData.append("profileImage", ProfileImages);
  }
  formData.append("firstName", form?.firstName);
  formData.append("middleName", form?.middleName);
  formData.append("lastName", form?.lastName);
  formData.append("email", form?.email);
  formData.append("phone", form?.phone);
  formData.append("dob", form?.dob);
  formData.append("addressLine_1", form?.addressLine_1);
  formData.append("addressLine_2", form?.addressLine_2);
  formData.append("country", form?.country);
  formData.append("city", form?.city);
  formData.append("state", form?.state);
  formData.append("zipcode", form?.zipCode);
  formData.append("phoneType", form?.phoneType);
  formData.append("company", form?.company);
  formData.append("jobTitle", form?.jobTitle);
  formData.append("interestedIn[property]", form?.property);
  formData.append("interestedIn[unit]", form?.unit);
  if (form?.notes) formData.append("notes", form?.notes);

  // Pets Data
  pets.forEach((pet, index) => {
    if (pet.petType !== "") {
      formData.append(`pet_info[${index}][pet_type]`, pet.petType);
    }
    if (pet.petName !== "") {
      formData.append(`pet_info[${index}][name]`, pet.petName);
    }
    if (pet.petWeight !== "") {
      formData.append(`pet_info[${index}][weight]`, pet.petWeight);
    }
    if (pet.petBreed !== "") {
      formData.append(`pet_info[${index}][breed]`, pet.petBreed);
    }
  });

  // Vehicles Data
  vehicles.forEach((vehicle, index) => {
    if (vehicle.vehicleType !== "") {
      formData.append(
        `vehicle_info[${index}][vehicle_type]`,
        vehicle.vehicleType
      );
    }
    if (vehicle.vehicleMake !== "") {
      formData.append(`vehicle_info[${index}][make]`, vehicle.vehicleMake);
    }
    if (vehicle.vehicleModel !== "") {
      formData.append(`vehicle_info[${index}][model]`, vehicle.vehicleModel);
    }
    if (vehicle.vehicleYear !== "") {
      formData.append(`vehicle_info[${index}][year]`, vehicle.vehicleYear);
    }
    if (vehicle.vehicleColor !== "") {
      formData.append(`vehicle_info[${index}][color]`, vehicle.vehicleColor);
    }
    if (vehicle.vehicleLicense !== "") {
      formData.append(
        `vehicle_info[${index}][license]`,
        vehicle.vehicleLicense
      );
    }
  });

  // Emergency Contacts Data
  emergencyContacts.forEach((emergency, index) => {
    if (emergency.firstName !== "") {
      formData.append(
        `emergencyDetails[${index}][firstName]`,
        emergency.firstName
      );
    }
    if (emergency.middleName !== "") {
      formData.append(
        `emergencyDetails[${index}][middleName]`,
        emergency.middleName
      );
    }
    if (emergency.lastName !== "") {
      formData.append(
        `emergencyDetails[${index}][lastName]`,
        emergency.lastName
      );
    }
    if (emergency.email !== "") {
      formData.append(`emergencyDetails[${index}][email]`, emergency.email);
    }
    if (emergency.phone !== "") {
      formData.append(`emergencyDetails[${index}][phone]`, emergency.phone);
    }
    if (emergency.relationship !== "") {
      formData.append(
        `emergencyDetails[${index}][relationship]`,
        emergency.relationship
      );
    }
    if (emergency.description !== "") {
      formData.append(
        `emergencyDetails[${index}][description]`,
        emergency.description
      );
    }
  });

  // Dependent Info
  dependentInfo.forEach((dependent, index) => {
    if (dependent.firstName !== "") {
      formData.append(
        `dependentInfo[${index}][first_name]`,
        dependent.firstName
      );
    }
    if (dependent.middleName !== "") {
      formData.append(
        `dependentInfo[${index}][middle_name]`,
        dependent.middleName
      );
    }
    if (dependent.lastName !== "") {
      formData.append(`dependentInfo[${index}][last_name]`, dependent.lastName);
    }
    if (dependent.dob !== "") {
      formData.append(`dependentInfo[${index}][dob]`, dependent.dob);
    }
    if (dependent.email !== "") {
      formData.append(`dependentInfo[${index}][email]`, dependent.email);
    }
    if (dependent.phone !== "") {
      formData.append(`dependentInfo[${index}][phone]`, dependent.phone);
    }
    if (dependent.relationship !== "") {
      formData.append(
        `dependentInfo[${index}][relationship]`,
        dependent.relationship
      );
    }
    if (dependent.description !== "") {
      formData.append(
        `dependentInfo[${index}][description]`,
        dependent.description
      );
    }
  });

  return formData;
};

const tenantFormData = (form, emergencyContacts, ProfileImages) => {
  var formdata = new FormData();

  if (ProfileImages.length !== 0) {
    formdata.append("profileImage", ProfileImages);
  }

  formdata.append("firstName", form.firstName);
  formdata.append("middleName", form.middleName);
  formdata.append("lastName", form.lastName);
  formdata.append("email", form.email);
  formdata.append("phone", form.phone);
  formdata.append("dob", form.dob);
  formdata.append("addressLine_1", form.addressLine_1);
  formdata.append("addressLine_2", form.addressLine_2);
  formdata.append("country", form?.country);
  formdata.append("city", form?.city);
  formdata.append("state", form?.state);
  formdata.append("zipcode", form.zipCode);
  formdata.append("phoneType", form.phoneType);
  formdata.append("company", form.company);
  formdata.append("jobTitle", form.jobTitle);
  if (form?.notes) formdata.append("notes", form?.notes);
  emergencyContacts.forEach((emergency, index) => {
    if (emergency.firstName !== "") {
      formdata.append(
        `emergencyDetails[${index}][firstName]`,
        emergency.firstName
      );
    }
    if (emergency.middleName !== "") {
      formdata.append(
        `emergencyDetails[${index}][middleName]`,
        emergency.middleName
      );
    }
    if (emergency.lastName !== "") {
      formdata.append(
        `emergencyDetails[${index}][lastName]`,
        emergency.lastName
      );
    }
    if (emergency.email !== "") {
      formdata.append(`emergencyDetails[${index}][email]`, emergency.email);
    }
    if (emergency.phone !== "") {
      formdata.append(`emergencyDetails[${index}][phone]`, emergency.phone);
    }
    if (emergency.relationship !== "") {
      formdata.append(
        `emergencyDetails[${index}][relationship]`,
        emergency.relationship
      );
    }
    if (emergency.description !== "") {
      formdata.append(
        `emergencyDetails[${index}][description]`,
        emergency.description
      );
    }
  });

  return formdata;
};

const FileAndNoteTabFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    if (key === "images") {
      data[key].forEach((file) => {
        formData.append("file", file);
      });
    } else {
      if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
        formData.append(key, data[key]);
      }
    }
  }
  return formData;
};

const leaseFormData = (
  form,
  leaseFiles,
  dependentInfo,
  pets,
  vehicles,
  documentInfo,
  currentStep
) => {
  var formData = new FormData();

  // lease Info Form
  formData.append("property", form.property.id);
  formData.append("unit", form.unit);
  formData.append("lease_term", form.lease_term);
  formData.append("lease_term_start_date", form.lease_term_start_date);
  formData.append("lease_term_end_date", form.lease_term_end_date);
  formData.append("first_rent_date", form.first_rent_date);
  formData.append("frequency", form.frequency);
  formData.append("rent_amount", form.rent_amount.amount);
  form.security_amount.amount &&
    formData.append("deposit_amount", form.security_amount.amount);
  formData.append("late_fee_charge", form.late_fee_charge);
  formData.append("late_fee_frequency", form.late_fee_frequency);
  form.late_fees_amount.amount &&
    formData.append("late_fees_amount", form.late_fees_amount.amount);
  form.late_fees_amount.type &&
    formData.append("late_fees_amount_type", form.late_fees_amount.type);

  // Lease Files
  (form.newLeaseSign !== "Yes") &&
    leaseFiles.forEach((file) => {
      formData.append("document", file);
    });

  // Tenant Info Form
  formData.append("tenant[_id]", form.tenant._id);
  formData.append("tenant[email]", form.tenant.email);
  formData.append("tenant[type]", form.tenant.type);

  // Co-Tenants Data
  form.coTenants.forEach((cotenant, index) => {
    formData.append(`coTenants[${index}][_id]`, cotenant._id);
    formData.append(`coTenants[${index}][email]`, cotenant.email);
    formData.append(`coTenants[${index}][type]`, cotenant.type);
  });

  // dependentInfos Data
  dependentInfo.forEach((dependent, index) => {
    dependent.firstName &&
      formData.append(
        `additionalInfo[dependent_info][${index}][first_name]`,
        dependent.firstName
      );
    dependent.middleName &&
      formData.append(
        `additionalInfo[dependent_info][${index}][middle_name]`,
        dependent.middleName
      );
    dependent.lastName &&
      formData.append(
        `additionalInfo[dependent_info][${index}][last_name]`,
        dependent.lastName
      );
    dependent.dob &&
      formData.append(
        `additionalInfo[dependent_info][${index}][dob]`,
        dependent.dob
      );
    dependent.email &&
      formData.append(
        `additionalInfo[dependent_info][${index}][email]`,
        dependent.email
      );
    dependent.phone &&
      formData.append(
        `additionalInfo[dependent_info][${index}][phone]`,
        dependent.phone
      );
    dependent.relationship &&
      formData.append(
        `additionalInfo[dependent_info][${index}][relationship]`,
        dependent.relationship
      );
    dependent.description &&
      formData.append(
        `additionalInfo[dependent_info][${index}][description]`,
        dependent.description
      );
  });

  // Pets Data
  pets.forEach((pet, index) => {
    pet.petType &&
      formData.append(
        `additionalInfo[pet_info][${index}][pet_type]`,
        pet.petType
      );
    pet.petName &&
      formData.append(`additionalInfo[pet_info][${index}][name]`, pet.petName);
    pet.petWeight &&
      formData.append(
        `additionalInfo[pet_info][${index}][weight]`,
        pet.petWeight
      );
    pet.petBreed &&
      formData.append(
        `additionalInfo[pet_info][${index}][breed]`,
        pet.petBreed
      );
  });

  // Vehicles Data
  vehicles.forEach((vehicle, index) => {
    vehicle.vehicleType &&
      formData.append(
        `additionalInfo[vehicle_info][${index}][vehicle_type]`,
        vehicle.vehicleType
      );
    vehicle.vehicleMake &&
      formData.append(
        `additionalInfo[vehicle_info][${index}][make]`,
        vehicle.vehicleMake
      );
    vehicle.vehicleModel &&
      formData.append(
        `additionalInfo[vehicle_info][${index}][model]`,
        vehicle.vehicleModel
      );
    vehicle.vehicleYear &&
      formData.append(
        `additionalInfo[vehicle_info][${index}][year]`,
        vehicle.vehicleYear
      );
    vehicle.vehicleColor &&
      formData.append(
        `additionalInfo[vehicle_info][${index}][color]`,
        vehicle.vehicleColor
      );
    vehicle.vehicleLicense &&
      formData.append(
        `additionalInfo[vehicle_info][${index}][license]`,
        vehicle.vehicleLicense
      );
  });

  formData.append("completed_steps", currentStep);

  formData.append("documentId", documentInfo.documentId);
  formData.append("documentName", documentInfo.documentName);
  formData.append("documentUrl", documentInfo.documentUrl);
  formData.append("documentStatus", documentInfo.documentStatus);
  // console.log("Document Invites", documentInfo.invites);
  documentInfo.invites.length !== 0 &&
    documentInfo.invites.forEach((invite, index) => {
      formData.append(`invites[${index}][id]`, invite.id);
      formData.append(`invites[${index}][email]`, invite.email);
      formData.append(`invites[${index}][role_id]`, invite.role_id);
      formData.append(`invites[${index}][order]`, invite.order);
      formData.append(`invites[${index}][status]`, invite.status);
    });

  return formData;
};

const vendorFormData = (form, image) => {
  // Create Vendor
  const formData = new FormData();
  formData.append("firstName", form.first_name);
  formData.append("middleName", form.middle_name);
  formData.append("lastName", form.last_name);
  formData.append("email", form.email);
  formData.append("phone", form.phone_no);
  formData.append("services", form.services);
  formData.append("company", form.company);
  formData.append("job_title", form.job);
  formData.append("address[address_line_1]", form.address1);
  formData.append("address[address_line_2]", form.address2);
  formData.append("address[city]", form.city);
  formData.append("address[state]", form.state);
  formData.append("address[country]", form.country);
  formData.append("address[zipcode]", form.zipCode);
  formData.append("insurance[policy_number]", form.policyNum);
  formData.append("insurance[provider]", form.provider);
  formData.append("insurance[coverage]", form.coverage);
  formData.append("insurance[effective_date]", form.effectiveDate);
  formData.append("insurance[expiration_date]", form.expireDate);
  formData.append("send_1099_forms", form.send_1099_forms);
  if (form.can_access_all_properties) {
    formData.append("can_access_all_properties", true);
  } else {
    for (const i of form.properties) {
      formData.append("property", [i]);
    }
  }
  formData.append("profileImage", image);

  return formData;
};

const TaskFormData = (form, Images, vendor) => {
  const formData = new FormData();
  formData.append("property", form.property.propertyId);
  formData.append("unit", form.property.unitId);
  for (let i = 0; i < vendor.length; i++) {
    formData.append(`assignedTo[${[i]}][_id]`, vendor[i].id);
    formData.append(`assignedTo[${[i]}][type]`, vendor[i].type);
  }
  // formData.append(`assignedToMe`, isLandlord);
  formData.append("title", form.title);
  formData.append("dueDate", form.due_date);
  formData.append("status", form.status);
  formData.append("priority", form.priority);
  formData.append("notifyAssignee", form.isNotifyAssignee);
  formData.append("notifyTenant", form.isNotifyTenant);
  if (form.recurring) {
    formData.append("startDate", form.start_date);
    formData.append("endDate", form.end_date);
    formData.append("frequency", form.frequency);
    formData.append("recurring", true);
  }
  formData.append("description", form.description);
  Images.forEach((img) => {
    formData.append("images", img);
  });

  return formData;
};

const TenantTaskFormData = (form, Images) => {
  const formData = new FormData();
  formData.append("property", form.property.propertyId);
  formData.append("unit", form.property.unitId);
  formData.append("title", form.title);
  formData.append("description", form.description);
  Images.forEach((img) => {
    formData.append("images", img);
  });

  return formData;
};

const TenantCreateTaskFormData = (form, Images) => {
  const formData = new FormData();
  formData.append("title", form.title);
  formData.append("description", form.description);
  formData.append("unit", form.unit);
  formData.append("property", form.property);
  Images.forEach((img) => {
    formData.append("images", img);
  });

  return formData;
};

const CompanyDetailsFormData = (form, image) => {
  console.log("Company Form", form);
  console.log("Company Logo", image);
  const formData = new FormData();
  formData.append("companyName", form.company_name);
  formData.append("email", form.company_email);
  formData.append("phone", form.company_phone);
  formData.append("address", form.address);
  formData.append("zipcode", parseInt(form.zipcode));
  formData.append("state", form.state);
  formData.append("country", "USA");
  formData.append(
    "companyLogo",
    image?.name ? image : image?.substring(image.lastIndexOf("/") + 1)
  );

  return formData;
};

const LandlordUserFormData = (form, image) => {
  const formData = new FormData();
  formData.append("firstName", form.firstName);
  formData.append("middleName", form.middleName);
  formData.append("lastName", form.lastName);
  formData.append("email", form.email);
  formData.append("phone", form.phone);
  formData.append("address[address_line_1]", form.address);
  formData.append("address[city]", form.city);
  formData.append("address[state]", form.state);
  formData.append("address[country]", "USA");
  form.roles.forEach((role, index) => {
    formData.append(`roles[${index}]`, role);
  });
  formData.append("profileImage", image);

  return formData;
};

const addnewfileform = (form, Images) => {
  const formData = new FormData();

  formData.append("file", form.file_name);
  formData.append("property", form.relatedToProperty);
  Images.forEach((img) => {
    formData.append("images", img);
  });

  return formData;
};

const invoicedataform = (
  updatedForm,
  images,
  otherform,
  invoiceLineItems,
  options
) => {
  const formData = new FormData();
  const userId = localStorage.getItem("user_id")
  formData.append("invoiceNo", `${userId}-${updatedForm.invoiceNo}`);
  formData.append("invoiceDate", updatedForm.InvoiceDate || updatedForm.invoiceDate);
  formData.append("dueDate", updatedForm.Duedate || updatedForm.dueDate);
  formData.append("propertyId", updatedForm.property.propertyId);
  formData.append("unitId", updatedForm.property.unitId || updatedForm.unitId);
  formData.append("tenantId", updatedForm.SelectTenant);
  formData.append("bankAccountId", updatedForm.Select_BankAccount.id || updatedForm.Select_BankAccount.value);
  formData.append("leaseId", updatedForm.leaseId);

  // Append each image to the form data
  images.forEach((image, index) => {
    formData.append(`images`, image); 
  });

  if(options == undefined){
    formData.append("type", updatedForm.type);
  }else{
    formData.append("type", options === 1 ? "one time" : "recurring");

  }

  

  // Stringify recurringDetails object
  const recurringDetails = {
    frequency: otherform.frequency,
    startDate: otherform.start_date,
    endDate: otherform.end_date,
    daysUntilDue: otherform.number_of_days_until_due,
    repeatForever: otherform.repeatForever,
  };
  if(otherform.repeatForever){
    recurringDetails.endDate = null;
  }
  formData.append("recurringDetails", JSON.stringify(recurringDetails));

  // Stringify description array of objects
  const description = invoiceLineItems.map((item) => ({
    productName: item?.description,
    category: item?.category ? item?.category : "active",
    amount: Number(item?.amount),
    description: item?.product_details
  }));
  formData.append("description", JSON.stringify(description));

  // Stringify lateFees object
  console.log("LATE FEEEEEEEEEEEEEEEEEEEE: ",updatedForm.latefee)
  const lateFees = {
    applyLateFees: updatedForm.latefee,
    frequency: updatedForm.latefee? updatedForm.frequency : null,
    amount: {
      type: updatedForm.latefee ?  updatedForm?.late_fees_amount?.type : null,
      value: updatedForm.latefee ?  Number(updatedForm?.late_fees_amount?.amount) : null,
    },
  };

  
  const otherAmuonts = [{
    description: updatedForm?.other_amount?.field, 
    amount: Number(updatedForm?.other_amount?.value),  
  }];
  const discount = {
    type: updatedForm?.discount?.type,
    value: Number(updatedForm?.discount?.amount),
  
  }
  formData.append("discount", JSON.stringify(discount));
  formData.append("otherAmounts", JSON.stringify(otherAmuonts));

  formData.append("lateFees", JSON.stringify(lateFees));

  formData.append("message", updatedForm.message);
  formData.append("sendStatus", updatedForm.sendStatus);

  return formData;
};



const userformvalidationsettings = (form, ProfileImages) => {
  const formData = new FormData();
  if (form.firstName) formData.append("firstName", form.firstName);
  if (form.middleName) formData.append("middleName", form.middleName);
  if (form.lastName) formData.append("lastName", form.lastName);
  if (form.email) formData.append("email", form.email);
  if (form.phone) formData.append("phone", form.phone);
  if (form.address1)
    formData.append("address[address_line_1]", form.addressLine_1);
  if (form.city) formData.append("address[city]", form.city);
  if (form.state) formData.append("address[state]", form.state);
  if (form.role) formData.append("role", form.role);
  formData.append("address[country]", "USA");
  formData.append("profileImage", ProfileImages);

  return formData;
}



export {
  prospectFormData,
  tenantFormData,
  FileAndNoteTabFormData,
  leaseFormData,
  vendorFormData,
  TaskFormData,
  TenantTaskFormData,
  TenantCreateTaskFormData,
  CompanyDetailsFormData,
  LandlordUserFormData,
  addnewfileform,
  invoicedataform,
  userformvalidationsettings,
};
