import TenantPassportEditProfile from "Components/TenantPassportEditProfile/TenantPassportEditProfile";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const PassportEditProfile = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle={"Tenant Passport"} />
      </div>
      <TenantPassportEditProfile />
    </div>
  );
};

export default PassportEditProfile;
