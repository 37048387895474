import { useEffect, useState } from "react";
import { message } from "antd";
import Loader from "Helpers/Loader";
import ProfileUploader from "Components/ProfileUploader/ProfileUploader";
import {
  InputField,
  PhoneNumberField,
  SelectField,
  SubmitButton,
} from "Components/GeneralComponents";
import { usaStates } from "utils";
import {
  getCompany,
  updateCompany,
  createCompany,
} from "network/landlord/setting";
import { CompanyDetailsFormData } from "utils/FormDataStructure";
import { validateCompanyDetails } from "utils/validations";

const SettingsCompanyInfo = () => {
  // States
  const [DeletedImages, setDeletedImages] = useState([]);
  const [companyLogo, setCompanyLogo] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRerender, setIsRerender] = useState(false);
  const [btnMsg, setBtnMsg] = useState("Save");
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    company_name: "",
    company_email: "",
    company_phone: "",
    address: "",
    state: "",
    zipcode: "",
  });

  const handleCompanyForm = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    setLoader(true);
    getCompany().then((res) => {
      console.log("My Response >>>", res);
      setLoader(false);
      if (res.apiCallStatus === "success") {
        const data = res.message.company;
        setForm({
          company_name: data.companyName || "",
          company_email: data.email || "",
          company_phone: data?.phone || "",
          address: data.address || "",
          state: data.state || "",
          zipcode: data.zipcode || "",
        });
        setCompanyLogo(data.companyLogo);
      } else if (res.apiCallStatus === "error") {
        message.error(res.message);
        setBtnMsg("Create");
      }
    });
  }, [isRerender]);

  const addCompanyInfo = () => {
    const newErrors = validateCompanyDetails(form);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      console.log("Errors", newErrors);
      return;
    }

    const formdata = CompanyDetailsFormData(form, companyLogo);

    console.log("Form", form);

    console.log(
      "Form Data",
      formdata.forEach((value, key) => console.log(key, value))
    );
    setIsLoading(true);
    btnMsg === "Save"
      ? updateCompany(formdata).then((res) => {
          setIsLoading(false);
          console.log("Updating");
          if (res.apiCallStatus === "success") {
            message.success("Company info updated successfully");
            setIsRerender(!isRerender);
          } else {
            message.error(res.message);
          }
        })
      : createCompany(formdata).then((res) => {
          setIsLoading(false);
          if (res.apiCallStatus === "success") {
            message.success("Company info created successfully");
            setIsRerender(!isRerender);
          } else {
            message.error(res.message);
          }
        });
  };

  return (
    <>
      <p className="heading pt-4 pb-5 ps-4">COMPANY info</p>
      {loader ? (
        <Loader />
      ) : (
        <div className="setting-info-container px-4">
        <div className="container">
            <p
              className="mb-0"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Montserrat",
              }}
            >
              Your Company Logo<span className="sign-up-imp-star">*</span>
            </p>
            <p className="normal-grey-text">
              Lorem ipsum dolor sit amet consectetur.
            </p>
            <div className="row mt-3 d-flex justify-content-start">
              <div className="col-md-12 ">
                <div className="dragger-company-logo">
                  <ProfileUploader
                    setProfileImages={setCompanyLogo}
                    ProfileImages={companyLogo}
                    setDeletedImages={setDeletedImages}
                    DeletedImages={DeletedImages}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <InputField
                  label="Company Name"
                  placeholder="Company Name"
                  value={form.company_name}
                  handler={(e) =>
                    handleCompanyForm("company_name", e.target.value)
                  }
                  required={true}
                  errorMessage={errors.company_name}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3">
                <InputField
                  label="Email"
                  placeholder="Email"
                  value={form.company_email}
                  handler={(e) =>
                    handleCompanyForm("company_email", e.target.value)
                  }
                  required={true}
                  errorMessage={errors.company_email}
                />
              </div>
              <div className="col-md-6 mt-3">
                <PhoneNumberField
                  label="Phone"
                  placeholder="Phone"
                  value={form.company_phone}
                  handler={(e) => {
                    handleCompanyForm("company_phone", e);
                  }}
                  required={true}
                  errorMessage={errors.company_phone}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3">
                <InputField
                  label="Address"
                  placeholder="Address"
                  value={form.address}
                  handler={(e) => handleCompanyForm("address", e.target.value)}
                  required={true}
                  errorMessage={errors.address}
                />
              </div>
              <div className="col-md-6 mt-3">
                <SelectField
                  label="State"
                  placeholder="Select State"
                  value={form.state}
                  handler={(e) => handleCompanyForm("state", e)}
                  options={usaStates.map((e) => {
                    return { value: e.name, label: e.name };
                  })}
                  required={true}
                  errorMessage={errors.state}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3">
                <SelectField
                  label="Country"
                  placeholder="Select Country"
                  value="USA"
                  options={[
                    {
                      value: "USA",
                      label: "USA",
                    },
                  ]}
                  // required={true}
                  errorMessage={errors.country}
                  disabled={true}
                />
              </div>
              <div className="col-md-6 mt-3">
                <InputField
                  name={"Postal code"}
                  label="Zip code"
                  placeholder="Zip code"
                  value={form.zipcode}
                  handler={(e) => handleCompanyForm("zipcode", e.target.value)}
                  required={true}
                  errorMessage={errors.zipcode}
                />
              </div>
            </div>
            <div className="row my-4">
              <div className="setting-btn my-4 d-flex align-items-center gap-3">
                <SubmitButton handler={addCompanyInfo} isLoading={isLoading}>
                  {btnMsg}
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsCompanyInfo;
