import email from "assets/email.png";
import { SubmitButton } from "..";
import { useNavigate } from "react-router-dom";

const TrialEndedModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const userEmail = localStorage.getItem("email");
  const userId = localStorage.getItem("user_id");
  
  return (
    <>
      {isOpen && (
        <div className="container-fluid d-flex flex-column justify-content-center align-items-center min-vh-100 p-4 overflow-hidden">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-12 text-center">
              <img className="img-fluid mb-5" src={email} alt="" />
              <p className="fs-2 fw-bold my-4">
                Your free trial has just ended{" "}
              </p>
              <div className="w-100">
                <p className="fw-semibold fs-5 mt-5">
                  The 14-day trial has just ended.{" "}
                </p>
                <p className="mb-4 fs-6 w-75 mx-auto">
                  Upgrade now to continue accessing all the tools and benefits.
                  We’d love to have you stay with us!
                </p>
              </div>

              {/* Use responsive width classes */}
              <div className="d-flex justify-content-center w-100 mb-2">
                <div className="w-25 w-md-25 w-lg-25">
                  <SubmitButton children="Upgrade Now" className="text-wrap p-3 w-25" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TrialEndedModal;
