import React, { useState, useEffect } from "react";
import Leasedstatuscards from "Components/GeneralComponents/Leasedstatuscards";
import trashIconWhite from "assets/trash-icon-white.png";
import * as Component from "Components/GeneralComponents";
import activeDot from "assets/_Dot.png";
import redDot from "assets/svg/_Dot.svg";
import { useNavigate, Link } from "react-router-dom";
import * as Icon from "assets/icons";
import * as icon from "assets/svg";
import { EditInvoiceWarning } from "Components/GeneralComponents";
import {
  generateAndDownloadPDF,
  generateAndPrintPDF,
} from "network/landlord/Account";
import ConfirmationModal from "Components/GeneralComponents/Modals/ConfirmationModal";

import {
  getAllInvoices,
  archiveInvoice,
  filterAllInvoices,
  getAllRecurrsiveInvoices,
} from "network/landlord/Account";
import { message, Switch } from "antd";
import { formatDate } from "utils";
import { isRejected } from "@reduxjs/toolkit";
import RecordPayment from "./RecordPayment";
import { FormModalLayout } from "Components/GeneralComponents";
import { setOptions } from "leaflet";
const Payment = () => {
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [button, setButton] = useState(true);
  const [mulDeleteMsg, setMulDeleteMsg] = useState(
    "Are you sure want to delete selected tenants?"
  );
  const [disableMulDltBtn, setDisableMulDltBtn] = useState(false);
  const [isDeleteTenantModal, setIsDeleteTenantModal] = useState(false);
  const [key, setKey] = useState("");
  const [isDeleteTenantsRender, setIsDeleteTenantsRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [allInvoices, setAllInvoices] = useState([]);
  const [isRecordModelOpen, setIsRecordModelOpen] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState(null);
  const [recurringId,setRecurringId] = useState(null)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmModelOpen, setIsConfirmModalOpen] = useState(false);
  const [archiveInvoiceId, setArchiveInvoiceId] = useState(null);
  const [recurrsiveInvoices, setRecurrsiveInvoices] = useState([]);
  const [columns,setColumns] = useState([])
  const [leaseStatusCard, setLeaseStatusCard] = useState({
    total_invoices: 0,
    paid_invoices: 0,
    pending_invoices: 0,
    overDue_invoices: 0,
  });
  const [tempInvoiceData, setTempInvoiceData] = useState("");
  const [tempRecurrsiveData, setTempRecurrsiveData] = useState([]);
  const navigate = useNavigate();

  const [data, setData] = useState([
    {
      key: "1",
    },
    {
      key: "2",
    },
    {
      key: "3",
    },
  ]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    range: "",
    property: "",
  });

  useEffect(() => {
    if (allInvoices.length > 0) {
      filterInvoices("one time");
    }
  }, [allInvoices]);

  const filterInvoices = (type) => {
    console.log("type", type);
    console.log("allInvoices: ", allInvoices);
    // const filteredInvoices = allInvoices.filter((item) => item.type === type);
    // console.log("filteredInvoices", filteredInvoices);
    if(type=="one time"){
      setInvoiceData(allInvoices);
      setTempInvoiceData(allInvoices);

    }
    else{
      setInvoiceData(recurrsiveInvoices)
      setTempRecurrsiveData(recurrsiveInvoices)

    }
    setIsTableLoading(false)
  };

  const handleClose = () => {
    setIsRecordModelOpen(false);
    filterInvoices("one time");
  };
  const fetchInvoices = async () => {
    try {

      getAllInvoices().then((response) => {
        if (response.apiCallStatus === "success") {
          const transformedInvoices =
            response.message.invoices.allInvoices.invoices.map(
              (invoice, index) => ({
                display_invoice_no: invoice.invoiceNo.substring(
                  invoice.invoiceNo.indexOf("INV")
                ),
                invoice_no: invoice.invoiceNo,
                due_date: invoice.dueDate,
                type: invoice.type,
                sendStatus: invoice.sendStatus,

                key: index.toString(),
                name: `${invoice.tenantId.firstName} ${invoice.tenantId.lastName}`,
                invoice_date: new Date(
                  invoice.invoiceDate
                ).toLocaleDateString(),
                amount: invoice.total,
                Description: "Rent",
                status:
                  invoice.paidStatus.charAt(0).toUpperCase() +
                  invoice.paidStatus.slice(1),
              })
            );

          setLeaseStatusCard({
            total_invoices: response.message.invoices.allInvoices.totalInvoices,
            paid_invoices: response.message.invoices.allInvoices.paidInvoices,
            pending_invoices:
              response.message.invoices.allInvoices.pendingInvoices,
            overDue_invoices:
              response.message.invoices.allInvoices.overdueInvoices,
          });

          setAllInvoices(transformedInvoices);
          console.log("Invoices set successfully!", transformedInvoices);
          setInvoiceData(transformedInvoices);

          filterInvoices("one time");

        }
      });
      getAllRecurrsiveInvoices().then((response) => {
        console.log("Recurrsive Invoices set successfully!", response.message);

        if (response.apiCallStatus === "success") {
          const transformedInvoices = response.message.invoices.invoices.map(
            (invoice, index) => ({
              due_date: invoice.dueDate,
              id: invoice._id,
              type: invoice.type,

              key: index.toString(),
              name: `${invoice.tenantId.firstName} ${invoice.tenantId.lastName}`,
              invoice_date: new Date(invoice.invoiceDate).toLocaleDateString(),
              amount: invoice.total,
              Description: "Rent",
              status:
                invoice.status.charAt(0).toUpperCase() +
                invoice.status.slice(1),
            })
          );

          setRecurrsiveInvoices(transformedInvoices);
          console.log("Invoices set successfully!", transformedInvoices);

        }
      });
    } catch (error) {
      setIsTableLoading(false);

      console.error("Error fetching invoices", error);
    }
  };
  useEffect(() => {
    fetchInvoices();

  }, []);
  const handleDownloadPDF = (invoiceNo) => {
    generateAndDownloadPDF(invoiceNo);
  };
  const handlePrintPDF = (invoiceNo) => {
    generateAndPrintPDF(invoiceNo);
  };

  const handleFilter = async () => {
    console.log("Filter called", filter);
    try {
      const response = await filterAllInvoices(filter);

      if (response?.apiCallStatus === "success") {
        console.log(response);

        const transformedInvoices = response.message?.invoices?.map(
          (invoice, index) => ({
            display_invoice_no: invoice.invoiceNo.substring(
              invoice.invoiceNo.indexOf("INV")
            ),
            name: invoice.landlordId
              ? `${invoice.landlordId.firstName} ${invoice.landlordId.lastName}`
              : `${invoice.tenantId.firstName} ${invoice.tenantId.lastName}`,
            invoice_no: invoice.invoiceNo,
            due_date: invoice.dueDate,
            sendStatus: invoice.sendStatus,
            type: invoice.type,
            key: index.toString(),
            invoice_date: new Date(invoice.invoiceDate).toLocaleDateString(),
            amount: invoice.total,
            Description: "Rent",
            status:
              invoice.paidStatus.charAt(0).toUpperCase() +
              invoice.paidStatus.slice(1),
          })
        );
        console.log("Transformed in filter invoices:", transformedInvoices);
        setAllInvoices(transformedInvoices);
        if (button) {
          filterInvoices("one time");
        } else {
          filterInvoices("recurring");
        }

        setInvoiceData(transformedInvoices);
        setButton(true)
      } else {
        console.log("API call was unsuccessful", response);
      }
    } catch (error) {
      message.error( error?.message);
      console.error("Error:", error);
    }
  };

  const handleFilterReset = () => {
    setFilter({});
    setButton(true)
    fetchInvoices();
  };
  const recordpayment = async (invoiceNo) => {
    console.log("recordpayment==> 1: ", invoiceNo);
    setIsRecordModelOpen(true);
    setInvoiceNo(invoiceNo);
    console.log(invoiceNo);
  };
  const archiveInvoiceById = async (invoice_no) => {
    const resposne = await archiveInvoice(invoice_no);
    setIsConfirmModalOpen(false);
    if (resposne.apiCallStatus === "success") {
      message.success("Invoice Archived Successfully");

      console.log(resposne?.message);
      fetchInvoices();
    } else {
      message.error("Failed to archive invoice", resposne?.message);
    }
  };

  const invoice = () => {
    filterInvoices("one time");

    setButton(true);
  };
  const recuring = () => {
    filterInvoices("recurring");
    setButton(false);
  };
  const hanldeArchive = (invoiceNo) => {
    setArchiveInvoiceId(invoiceNo);
    setIsConfirmModalOpen(true);
  };
  const handleArchiveClose = () => {
    setIsConfirmModalOpen(false);
  };
  const getColumns = () => {
    const baseColumns = [
      button
        ? {
            title: "Invoice No",
            dataIndex: "display_invoice_no",
            render: (text, data) => <>{text}</>,
          }
        : undefined,
      {
        title: "Name",
        dataIndex: "name",
        render: (text, data) => <>{text}</>,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (text, date) => <>{text}</>,
      },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        render: (text, record) => (
          <div className="d-flex flex-column ">
            <span className="d-flex flex-row">
              <span className="text-nowrap">{text}</span>
             {button && <span
                className={`${
                  record.sendStatus === "sent"
                    ? "prospect-active-bar"
                    : "prospect-unpaid-bar"
                }`}
                style={{ width: "50px", marginLeft: "8px" }}
              >
                <span>{record.sendStatus}</span>
              </span>
              }
            </span>
            <span className="text-nowrap"> Due Date: {record.due_date}</span>
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text, setting) => (
          <div
            className={`${
              text === "Paid" || text==='Active' ? "prospect-active-bar" : "prospect-unpaid-bar"
            }`}
            style={{ padding: "5px 10px" }}
          >
            <img src={text === "Paid" || text==='Active' ? activeDot : redDot} alt="" />{" "}
            <span>{text}</span>
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "setting",
        render: (text, setting) => (
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleView: () => console.log("View"),
                cancel: () => console.log("Cancel"),
                recordpayment: () => {
                  if (setting.status === "Unpaid") {
                    recordpayment(setting.invoice_no);
                  }
                },
                archive: () => archiveInvoiceById(setting.invoice_no),
                downloadaspdf: () => handleDownloadPDF(setting.invoice_no),
                print: () => handlePrintPDF(setting.invoice_no),
                editseries: () => handleEditOpen(setting.id),
                editinvoice: ()=> editOneTime(setting.invoice_no),

              }}
              fields={{
                editseries: !button,
                editinvoice : setting.status === "Unpaid" && setting.type==="one time",
                downloadaspdf: true,
                print: true,
                view: true,
                cancel: true,
                archive: true,
                recordpayment: setting.status === "Unpaid",
              }}
            />
          </div>
        ),
      },
    ];

    // Filter out undefined entries
    return baseColumns.filter((column) => column !== undefined);
  };
  const handleEditClose = () => {
    setIsEditModalOpen(false);
  };
  const handleEditOpen = (invoiceNo) => {
    console.log("----------------------------------------------------------------")
    console.log("----------------------------------------------------------------");
    console.log("invoiceNo: ", invoiceNo);
    setInvoiceNo(null);
    setRecurringId(invoiceNo);
    setIsEditModalOpen(true);
  };
  const editNextInvoice = (editMode) => {
    console.log(invoiceNo);
    console.log(recurringId);
    navigate("/edit-invoice", {
      state: {
        invoiceNo: null,
        recurringId: recurringId,
        editMode: editMode,
      },
    });
  };
  const editSeries = (editMode) => {
    console.log(invoiceNo);
    console.log(recurringId);
    navigate("/edit-invoice", {
      state: {
        invoiceNo: null,
        recurringId: recurringId,
        editMode: editMode,
      },
    });
  };
  const editOneTime = (editMode) => {
    console.log("----------------------------------------------------------------");
    console.log("----------------------------------------------------------------");

    console.log(invoiceNo);
    console.log(editMode);
    navigate("/edit-invoice", {
      state: {
        invoiceNo: editMode,
        recurringId: null,
        editMode: editMode,
      },
    });
  }
  const handleIconClick = (result) => {
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0]?.key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  useEffect(() => {}, [isDeleteTenantsRender]);

  useEffect(() => {
    // Check if filter.search has a value
    if (filter.search && filter.search.trim()) {
      console.log("filter.search", filter.search);
      
      let filteredData = []
      // Filter the invoice data based on the search criteria
      if(button){
        filteredData = tempInvoiceData.filter((item) => {
          // Check if any of the item properties include the search string
          return (
            item.invoice_no.toLowerCase().includes(filter.search.toLowerCase()) ||
            item.Description.toLowerCase().includes(
              filter.search.toLowerCase()
            ) ||
            item.status.toLowerCase().includes(filter.search.toLowerCase()) ||
            item.name.toLowerCase().includes(filter.search)
          );
        });
        setData(filteredData);

        setInvoiceData(filteredData);
      }else{
        filteredData = tempRecurrsiveData.filter((item) => {
          // Check if any of the item properties include the search string
          return (
            // item.invoice_no.toLowerCase().includes(filter.search.toLowerCase()) ||
            item.Description.toLowerCase().includes(
              filter.search.toLowerCase()
            ) ||
            item.status.toLowerCase().includes(filter.search.toLowerCase()) ||
            item.name.toLowerCase().includes(filter.search.toLowerCase())
          );
        });
        setData(filteredData);

        setInvoiceData(filteredData);

      }

     
    } else {
      if(button){
        setInvoiceData(tempInvoiceData);

      }else{
        setTempRecurrsiveData(tempRecurrsiveData)
      }
      // Reset invoice data to the original state when filter is cleared
    }
  }, [filter.search]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const onCloseDeleteTenantModal = () => {
    setIsDeleteTenantModal(false);
    setMulDeleteMsg("Are you sure want to delete selected tenants?");
    setDisableMulDltBtn(false);
  };

  return (
    <>
      {isConfirmModelOpen && (
        <ConfirmationModal
          isOpen={isConfirmModelOpen}
          onClose={handleArchiveClose}
          confirmationCall={archiveInvoiceById}
          message="Do you Confirm to archive Your Invoice?"
          isLoading={false}
          disableBtn={false}
          data={archiveInvoiceId}
        />
      )}
      {console.log("edit modal: ", isEditModalOpen)}
      {isEditModalOpen && (
        <EditInvoiceWarning
          onClose={handleEditClose}
          isOpen={isEditModalOpen}
          EditNextInvoice={editNextInvoice}
          EditSeries={editSeries}
          message="Do you want to edit the next invoice or do you want to edit the remaining series?"
        />
      )}

      {console.log(isRecordModelOpen)}
      {isRecordModelOpen && (
        <FormModalLayout
          isOpen={isRecordModelOpen}
          onClose={handleClose}
          title="Record Payment"
        >
          <RecordPayment invoiceNo={invoiceNo} />
        </FormModalLayout>
      )}

      <Component.DeleteModal
        isOpen={isDeleteTenantModal}
        onClose={onCloseDeleteTenantModal}
        message={mulDeleteMsg}
        deleteCall={() => setIsDeleteTenantsRender(!isDeleteTenantsRender)}
        isLoading={isLoading}
        disableBtn={disableMulDltBtn}
      />

      <div className="container-fluid bg-white p-4">
        <div className="row mt-3">
          <Leasedstatuscards
            icon={icon.file4}
            balance={true}
            value={leaseStatusCard.total_invoices}
            title="Total Invoices"
          />
          <Leasedstatuscards
            icon={icon.file1}
            balance={true}
            value={leaseStatusCard.paid_invoices}
            title="Paid Invoices"
          />
          <Leasedstatuscards
            icon={icon.file3}
            balance={true}
            value={leaseStatusCard.pending_invoices}
            title="Pending Invoice"
          />
          <Leasedstatuscards
            icon={icon.file2}
            balance={true}
            value={leaseStatusCard.overDue_invoices}
            title="Overdue Invoice"
          />
        </div>
      </div>
      <div className="row mt-1 ps-md-4 col-md-5 col-12">
        <div className="stepper-btn d-flex justify-content-between  gap-1 pb-3">
          <button
            onClick={invoice}
            className={`${button ? "next-btn-same-class" : "recuring-btn"}`}
          >
            Invoices
          </button>
          <button
            onClick={recuring}
            className={`${
              button
                ? "recuring-btn text-nowrap"
                : "next-btn-same-class text-nowrap"
            }`}
          >
            Recuring Series
          </button>
        </div>
      </div>
      <div className="container-fluid bg-white p-3 ">
        <Component.FilterRow
          filter={filter}
          setFilter={setFilter}
          btnTitle={"Create New Invoice"}
          btnHandler={() => navigate("/create-payable")}
          handleSubmit={handleFilter}
          handleReset={handleFilterReset}
          filterList={{
            date: true,
            invoiceFilter: true,
          }}
        />
      </div>
      <div className="row p-4">
        <div className="col-md-12">
          {selectedTableItem.length !== 0 && (
            <div className="table-delete-icon mb-3">
              <button
                onClick={() => setIsDeleteTenantModal(true)}
                className="table-delete-btn next-btn-main"
              >
                <img src={trashIconWhite} alt="" />
                Delete
              </button>
            </div>
          )}
          <Component.CustomTable
            loading={isTableLoading}
            pagination={true}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            columns={getColumns()}
            dataSource={invoiceData} //{invoiceData}
          />
        </div>
      </div>
    </>
  );
};

export default Payment;
