import MileageView from "Components/MileageView/MileageView";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const MilageDetail = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail={"Mileage "} back="mileage" />
      </div>
      <MileageView />
    </div>
  );
};

export default MilageDetail;
