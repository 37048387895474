import EndLease from "Components/EndLease/EndLease";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const LeasesEnd = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail="End Lease" back="lease-detail" />
      </div>
      <EndLease />
    </div>
  );
};

export default LeasesEnd;
