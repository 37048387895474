import TenantQuestions from "Components/TenantQuestions/TenantQuestions";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const TenantQuestionsPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail={"Questions"} back="tenant-passport" />
      </div>
      <TenantQuestions />
    </div>
  );
};

export default TenantQuestionsPage;
