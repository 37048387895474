const usaStates = [
  { label: "Alabama", value: "Alabama", abbreviation: "AL" },
  { label: "Alaska", value: "Alaska", abbreviation: "AK" },
  { label: "Arizona", value: "Arizona", abbreviation: "AZ" },
  { label: "Arkansas", value: "Arkansas", abbreviation: "AR" },
  { label: "California", value: "California", abbreviation: "CA" },
  { label: "Colorado", value: "Colorado", abbreviation: "CO" },
  { label: "Connecticut", value: "Connecticut", abbreviation: "CT" },
  { label: "Delaware", value: "Delaware", abbreviation: "DE" },
  { label: "Florida", value: "Florida", abbreviation: "FL" },
  { label: "Georgia", value: "Georgia", abbreviation: "GA" },
  { label: "Hawaii", value: "Hawaii", abbreviation: "HI" },
  { label: "Idaho", value: "Idaho", abbreviation: "ID" },
  { label: "Illinois", value: "Illinois", abbreviation: "IL" },
  { label: "Indiana", value: "Indiana", abbreviation: "IN" },
  { label: "Iowa", value: "Iowa", abbreviation: "IA" },
  { label: "Kansas", value: "Kansas", abbreviation: "KS" },
  { label: "Kentucky", value: "Kentucky", abbreviation: "KY" },
  { label: "Louisiana", value: "Louisiana", abbreviation: "LA" },
  { label: "Maine", value: "Maine", abbreviation: "ME" },
  { label: "Maryland", value: "Maryland", abbreviation: "MD" },
  { label: "Massachusetts", value: "Massachusetts", abbreviation: "MA" },
  { label: "Michigan", value: "Michigan", abbreviation: "MI" },
  { label: "Minnesota", value: "Minnesota", abbreviation: "MN" },
  { label: "Mississippi", value: "Mississippi", abbreviation: "MS" },
  { label: "Missouri", value: "Missouri", abbreviation: "MO" },
  { label: "Montana", value: "Montana", abbreviation: "MT" },
  { label: "Nebraska", value: "Nebraska", abbreviation: "NE" },
  { label: "Nevada", value: "Nevada", abbreviation: "NV" },
  { label: "New Hampshire", value: "New Hampshire", abbreviation: "NH" },
  { label: "New Jersey", value: "New Jersey", abbreviation: "NJ" },
  { label: "New Mexico", value: "New Mexico", abbreviation: "NM" },
  { label: "New York", value: "New York", abbreviation: "NY" },
  { label: "North Carolina", value: "North Carolina", abbreviation: "NC" },
  { label: "North Dakota", value: "North Dakota", abbreviation: "ND" },
  { label: "Ohio", value: "Ohio", abbreviation: "OH" },
  { label: "Oklahoma", value: "Oklahoma", abbreviation: "OK" },
  { label: "Oregon", value: "Oregon", abbreviation: "OR" },
  { label: "Pennsylvania", value: "Pennsylvania", abbreviation: "PA" },
  { label: "Rhode Island", value: "Rhode Island", abbreviation: "RI" },
  { label: "South Carolina", value: "South Carolina", abbreviation: "SC" },
  { label: "South Dakota", value: "South Dakota", abbreviation: "SD" },
  { label: "Tennessee", value: "Tennessee", abbreviation: "TN" },
  { label: "Texas", value: "Texas", abbreviation: "TX" },
  { label: "Utah", value: "Utah", abbreviation: "UT" },
  { label: "Vermont", value: "Vermont", abbreviation: "VT" },
  { label: "Virginia", value: "Virginia", abbreviation: "VA" },
  { label: "Washington", value: "Washington", abbreviation: "WA" },
  { label: "West Virginia", value: "West Virginia", abbreviation: "WV" },
  { label: "Wisconsin", value: "Wisconsin", abbreviation: "WI" },
  { label: "Wyoming", value: "Wyoming", abbreviation: "WY" },
];

const selectPhoneType = [
  { label: "Mobile", value: "Mobile" },
  { label: "Home", value: "Home" },
  { label: "Office", value: "office" },
];

const selectRelationship = [
  { label: "Children", value: "Children" },
  { label: "Spouse", value: "Spouse" },
  { label: "Elderly Dependents", value: "Elderly Dependents" },
  { label: "Siblings", value: "Siblings" },
];

const selectDateRange = [
  { label: "Today", value: "Today" },
  { label: "This Week", value: "This Week" },
  { label: "This Month", value: "This Month" },
  { label: "Quarterly", value: "Quarterly" },
  { label: "Yearly", value: "Yearly" },
];

const selectPets = [
  { value: "Dogs", label: "Dogs" },
  { value: "Cats", label: "Cats" },
  { value: "Fish", label: "Fish" },
  { value: "Birds", label: "Birds" },
  { value: "Small Mammals", label: "Small Mammals" },
  { value: "Reptiles", label: "Reptiles" },
  { value: "Exotic Pets", label: "Exotic Pets" },
];

const selectVehicle = [
  { value: "Car/Sedan", label: "Car/Sedan" },
  {
    value: "SUV (Sport Utility Vehicle)",
    label: "SUV (Sport Utility Vehicle)",
  },
  { value: "Truck", label: "Truck" },
  { value: "Van/Minivan", label: "Van/Minivan" },
  { value: "Motorcycle/Scooter", label: "Motorcycle/Scooter" },
  { value: "Bus", label: "Bus" },
  { value: "RV (Recreational Vehicle)", label: "RV (Recreational Vehicle)" },
  { value: "Commercial Vehicle", label: "Commercial Vehicle" },
  {
    value: "Electric Vehicle (EV) or Hybrid",
    label: "Electric Vehicle (EV) or Hybrid",
  },
];

const selectLeaseTerm = [
  { value: "Fixed Term", label: "Fixed Term" },
  { value: "Month-to-Month", label: "Month-to-Month" },
];

const selectBoolean = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const selectFrequency = [
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Yearly", label: "Yearly" },
];

const selectFrequencyFromThree = [
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
];

const selectLateFeeFrequency = [
  { value: "Once", label: "Once" },
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
];

const selectVendorServices = [
  { value: "Licensed Plumber", label: "Licensed Plumber" },
  { value: "Licensed Electrician", label: "Licensed Electrician" },
  { value: "Licensed HVAC Technician", label: "Licensed HVAC Technician" },
  {
    value: "Landscaping/Groundskeeping Service",
    label: "Landscaping/Groundskeeping Service",
  },
  { value: "Pest Control Service", label: "Pest Control Service" },
  { value: "Cleaning Service", label: "Cleaning Service" },
  {
    value: "Handyman/General Maintenance Technician",
    label: "Handyman/General Maintenance Technician",
  },
  { value: "Security Service", label: "Security Service" },
];

const selectTaskStatus = [
  { value: "In Progress", label: "In Progress" },
  { value: "Completed", label: "Completed" },
  { value: "Not Started", label: "Not Started" },
];

const selectTaskPriority = [
  { value: "Low", label: "Low" },
  { value: "Medium", label: "Medium" },
  { value: "High", label: "High" },
];

const propertyStatus = [
  { value: "Active", label: "Active" },
  { value: "InActive", label: "In Active" },
];
const selectactiveinactivearchive = [
  { value: "Active", label: "Active" },
  { value: "InActive", label: "In Active" },
  { value: "Archive", label: "Archive" },
];

const selectMultiColumns = [
  [
    { id: "1", value: "Addtional Storage", label: "Addtional Storage" },
    { id: "2", value: "Balcony", label: "Balcony" },
    { id: "3", value: "Carport", label: "Carport" },
    { id: "4", value: "Courtyard", label: "Courtyard" },
    { id: "5", value: "Double Sink Vanity", label: "Double Sink Vanity" },
    { id: "6", value: "Framed Mirrors", label: "Framed Mirrors" },
    { id: "7", value: "Handrails", label: "Handrails" },
    { id: "8", value: "Heat", label: "Heat" },
    { id: "9", value: "Laminate Counter Tops", label: "Laminate Counter Tops" },
    { id: "10", value: "Linen Closet", label: "Linen Closet" },
    { id: "11", value: "Patio", label: "Patio" },
    { id: "12", value: "Range", label: "Range" },
    { id: "13", value: "Skylight", label: "Skylight" },
    { id: "14", value: "View", label: "View" },
    { id: "15", value: "WD Hookup", label: "WD Hookup" },
  ],
  [
    { id: "1", value: "Air Conditioner", label: "Air Conditioner" },
    { id: "2", value: "Cable", label: "Cable" },
    { id: "3", value: "Ceiling Fan", label: "Ceiling Fan" },
    { id: "4", value: "Dishwasher", label: "Dishwasher" },
    { id: "5", value: "Dryer", label: "Dryer" },
    { id: "6", value: "Furnished", label: "Furnished" },
    { id: "7", value: "Hardwood Flooring", label: "Hardwood Flooring" },
    {
      id: "8",
      value: "Individual Climate Control",
      label: "Individual Climate Control",
    },
    { id: "9", value: "Vinyl Flooring", label: "Vinyl Flooring" },
    { id: "10", value: "Microwave", label: "Microwave" },
    { id: "11", value: "Private Balcony", label: "Private Balcony" },
    { id: "12", value: "Refrigerator", label: "Refrigerator" },
    { id: "13", value: "Tile Flooring", label: "Tile Flooring" },
    { id: "14", value: "Washer", label: "Washer" },
    { id: "15", value: "Window Coverings", label: "Window Coverings" },
  ],
  [
    { id: "1", value: "Alarm", label: "Alarm" },
    { id: "2", value: "Carpet", label: "Carpet" },
    { id: "3", value: "Controlled Access", label: "Controlled Access" },
    { id: "4", value: "Disposal", label: "Disposal" },
    { id: "5", value: "Fireplace", label: "Fireplace" },
    { id: "6", value: "Garage", label: "Garage" },
    {
      id: "7",
      value: "Hard Surface Counter Tops",
      label: "Hard Surface Counter Tops",
    },
    { id: "8", value: "Island Kitchen", label: "Island Kitchen" },
    { id: "9", value: "Pantry", label: "Pantry" },
    { id: "10", value: "Private Patio", label: "Private Patio" },
    { id: "11", value: "Satellite", label: "Satellite" },
    { id: "12", value: "Vaulted Ceiling", label: "Vaulted Ceiling" },
    { id: "13", value: "Wheel Chair", label: "Wheel Chair" },
    { id: "14", value: "Other", label: "Other" },
  ],
];

const driverLicenseState = [
  {
    value: "Learning",
    label: "Learning",
  },
  {
    value: "Permanent",
    label: "Permanent",
  },
];
const incomeFrequency = [
  {
    value: "PerMonth",
    label: "PerMonth",
  },
  {
    value: "PerYear",
    label: "PerYear",
  }
]

const employmentStatus = [
  {
    value: "Employed",
    label: "Employed",
  },
  {
    value: "Self-employed",
    label: "Self-employed",
  },
  {
    value: "Unemployed",
    label: "Unemployed",
  },
  {
    value: "Retired",
    label: "Retired",
  },
  {
    value: "Student",
    label: "Student",
  },
  {
    value: "Unknown",
    label: "Unknown",
  },
];

const maritalStatus = [
  {
    value: "Single",
    label: "Single",
  },
  {
    value: "Married",
    label: "Married",
  },
  {
    value: "Divorced",
    label: "Divorced",
  },
  {
    value: "Widowed",
    label: "Widowed",
  },
  {
    value: "Separated",
    label: "Separated",
  },
];

const selectGender = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Non-binary",
    label: "Non-binary",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const residenceType = [
  {
    value: "I rented this property",
    label: "I rented this property",
  },
  {
    value: "I owned this property",
    label: "I owned this property",
  },
  {
    value: "Other (i.e with family, outside US, college dorms etc)",
    label: "Other (i.e with family, outside US, college dorms etc)",
  },
];

const selectIncomeFrequency = [
  { value: "Per Month", name: "Per Month" },
  { value: "Per Year", name: "Per Year" },
];

// const employmentStatus = [
//   { value: "Not Employed", label: "Not Employed" },
//   { value: "Employed", label: "Employed" },
//   { value: "Self Employed", label: "Self Employed" },
//   { value: "Student", label: "Student" },
// ];

const residentialPropertyType = [
  {
    value: "Single Family",
    label: "Single Family",
  },
  {
    value: "Multi Family",
    label: "Multi Family",
  },
  {
    value: "Condo",
    label: "Condo",
  },
  {
    value: "Townhouse",
    label: "Townhouse",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const commercialPropertyType = [
  {
    value: "Office",
    label: "Office",
  },
  {
    value: "Retail",
    label: "Retail",
  },
  {
    value: "Industrial",
    label: "Industrial",
  },
  {
    value: "Hospitality",
    label: "Hospitality",
  },
  {
    value: "Multifamily",
    label: "Multifamily",
  },
  {
    value: "Special Purpose",
    label: "Special Purpose",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const userRoles = [
  { label: "Full Access", value: "Full Access" },
  { label: "Manager", value: "Manager" },
  { label: "Staff", value: "Staff" },
  { label: "Tech Support", value: "Tech Support" },
  { label: "Maintenance Team", value: "Maintenance Team" },
];

const invoiceduedate = [
{ label: "On Receipt", value: "Due on Receipt" },
{ label: "No due date", value: "No due date" },
{ label: "On specific date", value: "On specific date" },
{ label: 'In 10 days', value: 'In 10 days' },
{ label: 'In 15 days', value: 'In 15 days' },
{ label: 'In 30 days', value: 'In 30 days' },
{ label: 'In 45 days', value: 'In 45 days' },
{ label: 'In 60 days', value: 'In 60 days' },
{ label: 'In 90 days', value: 'In 90 days' },
];

const invoiceFilter = [
  { label: "All", value: "all" },
  { label: "Unpaid", value: "unpaid" },
  { label: "overdue", value: "overdue" },
  { label: "paid", value: "paid" },
  { label: "archive", value: "archive" },
]

export {
  usaStates,
  selectPhoneType,
  selectRelationship,
  selectDateRange,
  selectPets,
  selectVehicle,
  selectLeaseTerm,
  selectBoolean,
  selectFrequency,
  selectFrequencyFromThree,
  selectLateFeeFrequency,
  selectVendorServices,
  selectTaskStatus,
  selectTaskPriority,
  propertyStatus,
  selectMultiColumns,
  driverLicenseState,
  maritalStatus,
  selectGender,
  residenceType,
  selectIncomeFrequency,
  employmentStatus,
  residentialPropertyType,
  commercialPropertyType,
  userRoles,
  selectactiveinactivearchive,
  invoiceduedate,
  invoiceFilter,
  incomeFrequency
};
