// TenantIdentityVerificationComponent.js
import React, { useState, useEffect, useRef } from 'react';
import { PlaidLink } from 'react-plaid-link';
import { generateIdentityVerificatoinLink } from 'network/tenant/passport';
import { message } from 'antd';
const IdentityVerification = ({ handleNext, data,handlePrev }) => {
  const [linkToken, setLinkToken] = useState(null);
  const plaidLinkRef = useRef(null);

  console.log(`Verifying data for ${data}`);

  useEffect(() => {
    const fetchLinkToken = async () => {
      try {
        const response = await generateIdentityVerificatoinLink(data);
        console.log("Link token fetched: ", response);
        if(response?.apiCallStatus == "error"){
          message.error(response.error);
          handlePrev();
          return;
        }
        setLinkToken(response?.linkTokenResponse?.link_token);
      } catch (error) {
        console.error("Error fetching link token:", error);
      }
    };

    fetchLinkToken();

    // Optional cleanup if needed for the link token
    return () => setLinkToken(null);
  }, [data]); // Added `data` as a dependency to refetch if it changes

  useEffect(() => {
    if (linkToken && plaidLinkRef.current) {
      plaidLinkRef.current.open(); // Automatically open the Plaid Link flow
    }
  }, [linkToken]); // This will trigger when linkToken changes

  const onSuccess = (public_token, metadata) => {
    console.log('Public Token: ', public_token);
    console.log('Metadata: ', metadata);
    handleNext(); // Proceed to the next step after successful verification
  };

  return (
    linkToken && (
      <div className="container d-flex flex-column align-items-center justify-content-center min-vh-100">
      <div className="card p-4 shadow-lg">
        <h2 className="text-center mb-4">Identity Verification</h2>
        
        {linkToken ? (
          <PlaidLink
            ref={plaidLinkRef}
            token={linkToken}
            onLoad={() => {
              if (plaidLinkRef.current) {
                plaidLinkRef.current.open();
              }
            }}
            onSuccess={onSuccess}
            onExit={(err, metadata) => console.log('Exit: ', err, metadata)}
          >
            {/* Hidden button text, as the link opens automatically */}
            <span className="d-none">Identity Verification</span>
          </PlaidLink>
        ) : (
          <p className="text-center">Loading...</p> // Loading state
        )}
      </div>
    </div>
    )
  );
};

export default IdentityVerification;
