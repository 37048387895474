import NewLease from "Components/NewLease/NewLease";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const LeasesNew = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail="New Lease" back="all-lease" />
      </div>
      <NewLease />
    </div>
  );
};

export default LeasesNew;
