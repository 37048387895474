import LeaseDetails from "Components/LeaseDetails/LeaseDetails";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const LeasesDetail = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail="Lease Details" back="all-lease" />
      </div>
      <LeaseDetails />
    </div>
  );
};

export default LeasesDetail;
