import NewFile from "Components/NewFile/NewFile";
import TitleTopBar from "Helpers/TitleTopBar";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import React from "react";

const FilesNew = () => {
  const { id } = UseUrlParamsHook();
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"New File"}
          back={id ? `property-details-view?id=${id}` : `all-files`}
        />
      </div>
      <NewFile />
    </div>
  );
};

export default FilesNew;
