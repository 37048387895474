import WorkOrder from "Components/WorkOrder/WorkOrder";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const WorkOrderPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail={"Work Orders"} back="all-work-order" />
      </div>
      <WorkOrder />
    </div>
  );
};

export default WorkOrderPage;
