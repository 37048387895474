import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import PropertiesUnitsEdit from "Components/PropertiesUnitsEdit/PropertiesUnitsEdit";

const PropertiesUnitsEditPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle={"Unit Details"} />
      </div>
      <PropertiesUnitsEdit />
    </div>
  );
};

export default PropertiesUnitsEditPage;
