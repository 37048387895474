import TaskReportDetail from "Components/TaskReportDetail/TaskReportDetail";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const TaskReportDetailPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail="Task Report Detail"
          back="task-reports"
        />
      </div>
      <TaskReportDetail />
    </div>
  );
};

export default TaskReportDetailPage;
