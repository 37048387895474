import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import SideDrawer from "Components/SideDrawer/SideDrawer";
import ResponsiveSideBar from "Components/ResponsiveSideBar/ResponsiveSideBar";
import { useSelector } from "react-redux";
import VerifyEmailModal from "Components/GeneralComponents/Modals/VerifyEmailModal";
import { FetchUser, FetchUserTenant } from "network/landlord/Account";
import { getCurrentSubscription } from "network/landlord/subscription";
import TrialEndedModal from "Components/GeneralComponents/Modals/TrialEndedModal";
import AccountSuspensionModal from "Components/GeneralComponents/Modals/AccountSuspensionModal";
const Home = () => {
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);
  const [isUpgraded, setIsUpgraded] = useState(false);
  const [isSuspended, setIsSuspended] = useState(false);

  const handleVerifyEmailModalClose = () => {
    setIsVerifyEmail(false);
  };

  const handleUpgradeNow = () => {
    setIsUpgraded(false);
  };

  const handleSuspension = () => {
    setIsSuspended(false);
  };

  const drawer = useSelector((state) => {
    return state.sideDrawer.drawerState;
  });
  const navigate = useNavigate();

  // Redirecting script if user is not logged in
  useEffect(() => {
    if (!role || !token) {
      navigate("/onboarding");
    }
  }, [navigate]);

  useEffect(() => {
    if (role === "landlord") {
      FetchUser().then((res) => {
        if (res.apiCallStatus === "success") {
          setIsVerifyEmail(!res.message.isEmailVerified);
        } else {
          navigate("/onboarding");
        }
      });
    } else if (role === "tenant") {
      FetchUserTenant().then((res) => {
        if (res.apiCallStatus === "success") {
          setIsVerifyEmail(!res.message.isEmailVerified);
        } else {
          navigate("/onboarding");
        }
      });
    }
  }, [role]);

  useEffect(() => {
    if (role === "landlord") {
      getCurrentSubscription().then((res) => {
        if (res.apiCallStatus === "success") {
          const subscription = res.message.subscription;

          if (subscription.plan === "Trial") {
            const trialEndDate = new Date(subscription.trialEndDate); // Convert trialEndDate to Date object
            const currentDate = new Date(); // Get current date

            // Convert both dates to UTC timestamps for accurate comparison
            if (trialEndDate.getTime() < currentDate.getTime()) {
              setIsUpgraded(true);
            }
          } else if (
            subscription.plan === "Basic" ||
            subscription.plan === "Pro" ||
            subscription.plan === "Premium"
          ) {
            if (subscription.status === "Expired") {
              setIsSuspended(true);
            }
          }
        }
      });
    }
  }, [role]);

  // useEffect(() => {
  //   const { initializeSocket } = Socket();

  //   initializeSocket.on("verifyEmail", () => {
  //     console.log("Email verified event received");
  //     setIsVerifyEmail(false);
  //   });

  //   return () => {
  //     initializeSocket.disconnect();
  //   };
  // }, []);

  return (
    <div>
      <VerifyEmailModal
        isOpen={isVerifyEmail}
        onClose={handleVerifyEmailModalClose}
      />
      <TrialEndedModal isOpen={isUpgraded} onClose={handleUpgradeNow} />
      <AccountSuspensionModal isOpen={isSuspended} onClose={handleSuspension} />
      {!isVerifyEmail && !isUpgraded && (
        <div className="container-fluid">
          <div className="row hv-100">
            {/* Aside Bar */}
            <div className="col-xl-2 p-sm-0 d-md-none d-lg-none d-xl-block d-none position-relative drawer-bg-primary">
              <SideDrawer />
            </div>
            <div
              className={
                drawer === true ? "res-drawer-bg-open" : "res-drawer-bg-close"
              }
            >
              <div
                className={
                  drawer === true
                    ? "drawer-bg-primary responsive-drawer-container-open"
                    : "drawer-bg-primary responsive-drawer-container "
                }
              >
                <ResponsiveSideBar />
              </div>
            </div>
            {/* Main Content */}
            <div className="col-xl-10 col-lg-12 col-md-12">
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
