import UserDetails from "Components/UserDetails/UserDetails";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const UserDetailsPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"User Details"}
          back="./settings/user-info"
        />
      </div>
      <UserDetails />
    </div>
  );
};

export default UserDetailsPage;
