import settingIcon from "assets/setting-icon.png";
import lockIcon from "assets/lock.png";
import monitorIcon from "assets/monitor-03.png";
import userIcon from "assets/user-circle.png";
import userRoleIcon from "assets/user-03.png";

const landlordTabs = [
  {
    key: "personalInfo",
    to: "personal-info",
    icon: settingIcon,
    title: "Personal Information",
    description:
      "Update your name, profile picture, phone number, email, and mailing address.",
  },
  {
    key: "loginInfo",
    to: "login-info",
    icon: lockIcon,
    title: "Login & Password",
    description: "Update your password for account security.",
    roles: ["landlord", "user"],
    permission: "settings_changePassword",
  },
  {
    key: "companyInfo",
    to: "company-info",
    icon: monitorIcon,
    title: "Company Information",
    description:
      "Update your company name, profile picture, phone number, email, and mailing address.",
  },
  // {
  //   key: "regionAndCurrency",
  //   to: "",
  //   icon: userIcon,
  //   title: "Region & Currency",
  //   description: "Change your country, date formats, and currency settings.",
  // },
  {
    key: "userInfo",
    to: "user-info",
    icon: userIcon,
    title: "User",
    description: "Add users and assign their roles.",
  },
  {
    key: "userRole",
    to: "user-role",
    icon: userRoleIcon,
    title: "User Roles",
    description: "Manage and assign user roles and permissions for your team.",
    roles: ["landlord", "user"],
    permission: "settings_ManageUsers",
  },
  {
    key: "subscription",
    to: "subscription",
    icon: userIcon,
    title: "Subscription",
    description:
      "Manage your subscription plan, upgrade, or download billing details.",
    roles: ["landlord", "user"],
    permission: "settings_Subscription",
  },
  {
    key: "deleteaccount",
    to: "deleteaccount",
    icon: userIcon,
    title: "Delete Account",
    description:
      "Permanently delete your account and all data associated with it.",
    roles: ["landlord", "user"],
    permission: "settings_DeleteAccount",
  },
  // {
  //   key: "paymentMethod",
  //   to: "",
  //   icon: userIcon,
  //   title: "Payment Method",
  //   description:
  //     "Allow or block access to features of Digital Tenant for each user.",
  // },
  // {
  //   key: "portfolios",
  //   to: "",
  //   icon: userIcon,
  //   title: "Portfolios",
  //   description:
  //     "Allow or block access to features of Digital Tenant for each user.",
  // },
  // {
  //   key: "leadGenerationChannel",
  //   to: "",
  //   icon: userIcon,
  //   title: "Lead Generation Channel",
  //   description:
  //     "Link your leads with lead source, run reports, and keep track of your leads.",
  // },
];

const tenantTabs = [
  {
    key: "personalInfo",
    to: "personal-info",
    icon: settingIcon,
    title: "Personal Information",
    description:
      "Update your name, profile picture, phone number, email, and mailing address.",
  },
  {
    key: "loginInfo",
    to: "login-info",
    icon: lockIcon,
    title: "Login & Password",
    description: "Update your password for account security.",
    roles: ["tenant"],
    permission: "settings_changePassword",
  },
  {
    key: "paymentMethod",
    to: "payment-method",
    icon: userIcon,
    title: "Payment Method",
    description: "Update your payment method.",
  },
  {
    key: "notification",
    to: "notification",
    icon: userIcon,
    title: "Notification",
    description: "Update your notification settings.",
  },
  {
    key: "deleteaccount",
    to: "deleteaccount",
    icon: userIcon,
    title: "Delete Account",
    description:
      "Permanently delete your account and all data associated with it.",
    roles: ["tenant"],
    permission: "settings_DeleteAccount",
  },
];

const userTabs = [
  {
    key: "personalInfo",
    to: "personal-info",
    icon: settingIcon,
    title: "Personal Information",
    description:
      "Update your name, profile picture, phone number, email, and mailing address.",
  },
  {
    key: "loginInfo",
    to: "login-info",
    icon: lockIcon,
    title: "Login & Password",
    description: "Update your password for account security.",
    roles: ["user"],
    permission: "settings_changePassword",
  },
  {
    key: "companyInfo",
    to: "company-info",
    icon: monitorIcon,
    title: "Company Information",
    description:
      "Update your company name, profile picture, phone number, email, and mailing address.",
  },
];

export { landlordTabs, tenantTabs, userTabs };
