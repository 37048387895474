import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
// import useStore from "@/(store)/store";
import * as ServiceProfessionals from "Pages/ServiceProfessionals";
import * as Landlord from "Pages/Landlord";
import * as Tenant from "Pages/Tenant";
import * as Page from "Pages";
import TenantQuestions from "Components/TenantQuestions/TenantQuestions";
import Checkout from "Components/Payment/Checkout";
import Setting from "Components/Setting/Setting";
import UserSetPassword from "Components/UserSetPassword/UserSetPassword";

const AppRoutes = () => {
  const role = localStorage.getItem("role");
  return (
    <BrowserRouter>
      <AnimatePresence>
        <Routes>
          <Route path="/" element={<Page.Home />}>
            {(role === "landlord" || role === "user") && (
              <>
                <Route path="/" element={<Landlord.Dashboard/>} />
                <Route exact path="*" element={<Landlord.Error404 />} />
                <Route path="/dashboard" element={<Landlord.Dashboard/>} /> {/* Don't need it */}
                <Route path="/create-new" element={<Landlord.CreateNewPage/>} />
                <Route path="/help" element={<Landlord.HelpPage/>} />
                <Route path="/notification" element={<Landlord.Notifications/>} />
                <Route path="/contacts" element={<Landlord.Contacts/>} />
                <Route path="/maintenance" element={<Landlord.Maintenance/>} />
                <Route path="/documentation" element={<Landlord.Documentation />} />
                <Route path="/accounting" element={<Landlord.Accounting/>} />
                <Route path="add-notes" element={<Landlord.NotesNew/>} /> {/* Don't need it */}
                <Route path="/custom-steps" element={<Landlord.CustomSteps/>} /> {/* Don't need it */}

                {/* No Access Page */}
                <Route path="/no-access" element={<Landlord.NoAccess/>} />

                {/* Landlord Properties Routes */}
                <Route path="/properties-dashboard" element={<Landlord.Properties/>} />
                <Route path="/add-property-details" element={<Landlord.PropertiesNew/>} />
                <Route path="/property-details-view" element={<Landlord.PropertyDetails/>} />
                <Route path="/properties-delete-view" element={<Landlord.PropertiesDelete/>} />
                <Route path="/property-add-account" element={<Landlord.PropertiesAddAccount/>} />

                {/* Landlord Units Routes */}
                <Route path="/properties-units-view" element={<Landlord.UnitDetails/>} />
                <Route path="/properties-units-edit" element={<Landlord.UnitEdit/>} />
                <Route path="/add-unit-details" element={<Landlord.UnitNew/>} />

                {/* Landlord prospect Routes */}
                <Route path="/all-prospect" element={<Landlord.Prospects/>} />
                <Route path="/add-prospect-details" element={<Landlord.ProspectsNew/>} />
                <Route path="/prospect-details" element={<Landlord.ProspectsDetails/>} />
                <Route path="/edit-prospect-details/:id" element={<Landlord.ProspectsEdit/>} />
                <Route path="/convert-to-tenant/:id" element={<Landlord.ProspectsToTenant/>} />
                <Route path="/prospect-lease" element={<Landlord.ProspectLease/>} />

                {/* Landlord tenant routes */}
                <Route path="/all-tenants" element={<Landlord.Tenants/>} />
                <Route path="/add-tenant-details" element={<Landlord.TenantsNew/>} />
                <Route path="/tenant-details-view" element={<Landlord.TenantsDetails/>} />
                <Route path="/edit-tenant-details/:id" element={<Landlord.TenantsEdit/>} />
                
                {/* Landlord Vendor Routes */}
                <Route path="/all-vendor" element={<Landlord.Vendors/>} />
                <Route path="/add-vendor-details" element={<Landlord.VendorsNew/>} />
                <Route path="/vendor-detail-view" element={<Landlord.VendorsDetail/>} />
                <Route path="/edit-vendor-details/:id" element={<Landlord.VendorsEdit/>} />

                {/* Landlord Service professionals Routes */}
                <Route path="/all-service-professional" element={<Landlord.ServiceProfessionals/>} />
                <Route path="/all-service-professional-details" element={<Landlord.ServiceProfessionalsDetail/>} />
                <Route path="/local-professional-details" element={<Landlord.LocalProfessionalDetailPage/>} />

                {/* Landlord Task Routes */}
                <Route path="/all-task" element={<Landlord.Tasks/>} />
                <Route path="/task-details" element={<Landlord.TaskDetailsPage/>} />

                {/* Landlord Work Order Routes */}
                <Route path="/all-work-order" element={<Landlord.WorkOrders/>} />
                <Route path="/work-order" element={<Landlord.WorkOrderPage />} />

                {/* Landlord Chat Routes */}
                <Route path="/user-chat" element={<Landlord.UserChat/>} >
                  <Route path="chat" element={<Landlord.ChatBody/>} />
                </Route> 
                <Route path="/mobile-chat" element={<Landlord.MobileChat/>} />
                <Route path="/archive-chat" element={<Landlord.ChatsArchive/>} />
                <Route path="/archive-user" element={<Landlord.ChatsArchiveUser/>} >
                  <Route path="chat" element={<Landlord.ChatBody/>} />
                </Route>

                {/* Landlord Reports Routes */}
                <Route path="/all-reports" element={<Landlord.Reports/>} >
                  <Route path="property-reports" element={<Landlord.ReportsProperty/>} />
                  <Route path="tenant-reports" element={<Landlord.ReportsTenant/>} />
                  <Route path="invoice-reports" element={<Landlord.ReportsInvoice/>} />
                  <Route path="task-reports" element={<Landlord.ReportsTask/>} />
                </Route>

                {/* Landlord Files Routes */}
                <Route path="/all-files" element={<Landlord.Files/>} />
                <Route path="/edit-file" element={<Landlord.FilesEdit/>} />
                <Route path="/new-files" element={<Landlord.FilesNew/>} />

                {/* Landlord Settings Routes */}
                <Route path="/all-lease" element={<Landlord.Leases/>} />
                <Route path="/new-lease" element={<Landlord.LeasesNew/>} />
                <Route path="/draft-lease" element={<Landlord.LeasesDraft/>} />
                <Route path="/lease-detail" element={<Landlord.LeasesDetail/>} />
                <Route path="/renew-lease" element={<Landlord.LeasesRenew/>} />
                <Route path="/non-renew-lease" element={<Landlord.LeasesNonRenew/>} />
                <Route path="/end-lease" element={<Landlord.LeasesEnd/>} />

                {/* Landlord Accounting Routes */}
                <Route path="/all-accounts" element={<Landlord.Accounts/>} />
                <Route path="/add-account" element={<Landlord.AccountsNew/>} />
                <Route path="/account-details/:id/:account_id" element={<Landlord.AccountsDetail/>} />
                
                {/* Landlord Milage Routes */}
                <Route path="/mileage" element={<Landlord.MileagePage/>} />
                <Route path="/add-mileage" element={<Landlord.MileageNew/>} />
                <Route path="/mileage-view" element={<Landlord.MileageDetail/>} />

                {/* Landlord Payment Routes */}
                <Route path="/payment" element={<Landlord.PaymentPage/>} />
                <Route path="/create-payable" element={<Landlord.PayableNew/>} />
                <Route path="/payable-overview" element={<Landlord.PayableOverViewPage/>} />
                <Route path="/edit-payable" element={<Landlord.PayableEdit/>} />
                <Route path="/edit-invoice" element={<Landlord.InvoiceEdit/>} />

                {/* Landlord Settings Routes */}
                <Route path="/settings" element={<Landlord.SettingsPage/>} >
                  <Route path="personal-info" element={<Landlord.SettingsPersonalInfo/>} />
                  <Route path="login-info" element={<Landlord.SettingsLoginInfo/>} />
                  <Route path="company-info" element={<Landlord.SettingsCompanyInfo/>} />
                  <Route path="region" element={<Landlord.SettingsRegion/>} />
                  <Route path="user-role" element={<Landlord.SettingsUserRole/>} />
                  <Route path="subscription" element={<Landlord.SettingsSubscription/>} />
                  <Route path="payment" element={<Landlord.SettingsPaymentMethod/>} />
                  <Route path="portfolio" element={<Landlord.SettingsPortfolio/>} />
                  <Route path="lead-source" element={<Landlord.SettingsLeadSource/>} />
                  <Route path="user-info" element={<Landlord.SettingsUserInfo/>} />
                  <Route path="add-user-details" element={<Landlord.SettingsUserInfoNew/>} />
                  <Route path="deleteaccount" element={<Landlord.SettingsDeleteAccount/>} />
                </Route>

                {/* Landlord Inner Settings Routes */}
                <Route path="/user-details" element={<Landlord.UserDetailsPage/>} />
                <Route path="/add-user-role" element={<Landlord.UsersRoleNew/>} />
                <Route path="/task-report-detail" element={<Landlord.TaskReportDetailPage/>} />                
              </>
            )}

            {role === "tenant" && (
              <>
                <Route path="/" element={<Tenant.Dashboard/>} />
                <Route exact path="*" element={<Tenant.Error404 />} />
                <Route path="/dashboard" element={<Tenant.Dashboard/>} />

                {/* Tenant Properties Routes */}
                <Route path="/tenant-properties" element={<Tenant.Properties/>} />
                <Route path="/tenant-property-details-view" element={<Tenant.PropertiesDetail/>} />
                <Route path="/tenant-property-units-view" element={<Tenant.UnitsDetail/>} />

                {/* Task Routes */}
                <Route path="/all-task" element={<Tenant.Tasks/>} />
                <Route path="/task-details" element={<Tenant.TasksDetail/>} />
                <Route path="/tenant-task-details" element={<Tenant.TasksDetail/>} />

                {/* Tenant Settings Routes */}
                <Route path="/settings" element={<Tenant.Settings/>}>
                  <Route path="personal-info" element={<Tenant.SettingsPersonalInfo/>} />
                  <Route path="login-info" element={<Tenant.SettingsLoginInfo/>} />
                  <Route path="payment-method" element={<Tenant.SettingsPaymentMethod/>} />
                  <Route path="notification" element={<Tenant.SettingsNotification/>} />
                  <Route path="deleteaccount" element={<Tenant.SettingsDeleteAccount/>} />
                  <Route path="company-info" element={<Tenant.SettingsCompanyInfo/>} />
                  <Route path="region" element={<Tenant.SettingsRegion/>} />
                  <Route path="user-role" element={<Tenant.SettingsUserRoleNew/>} />
                  <Route path="subscription" element={<Tenant.SettingsSubscription/>} />
                  <Route path="portfolio" element={<Tenant.SettingsPortfolio/>} />
                  <Route path="lead-source" element={<Tenant.SettingsLeadSource/>} />
                  <Route path="user-info" element={<Tenant.SettingsUserInfo/>} />
                </Route>

                {/* Tenant Payable Routes */}
                <Route path="/payment" element={<Tenant.PaymentPage/>} />
                <Route path="/create-payable" element={<Tenant.PayableNew/>} />
                <Route path="/payable-overview" element={<Tenant.PayableOverviewPage/>} />
                <Route path="/payment-success" element={<Tenant.PaymentSuccess/>} />

                {/* Tenant Chat Routes */}
                <Route path="/user-chat" element={<Tenant.UserChat/>} >
                  <Route path="chat" element={<Tenant.ChatBody/>} />
                </Route>
                <Route path="/mobile-chat" element={<Tenant.MobileChat/>} />
                <Route path="/archive-chat" element={<Tenant.ChatsArchive/>} />
                <Route path="/archive-user" element={<Tenant.ChatsArchiveUser/>}>
                  <Route path="chat" element={<Tenant.ChatBody/>} />
                </Route>

                {/* Tenant Passport Routes */}
                <Route path="/tenant-passport" element={<Tenant.Passport/>} />
                <Route path="/tenant-passport-edit-profile" element={<Tenant.PassportEditProfile/>} />
                <Route path="/tenant-passport-details-view" element={<Tenant.PassportDetail/>} />
                <Route path="/tenant-passport-profile" element={<Tenant.PassportProfile/>} />
                <Route path="/tenant-questions" element={<Tenant.TenantQuestionsPage/>} />
               
                <Route path="/invoice/:id" element={<></>} />

                {/* Tenant Notifications */}
                <Route path="/notification" element={<Tenant.NotificationPage/>} />

                {/* Tenant Help */}
                <Route path="/help" element={<Tenant.HelpPage/>} />
              </>
            )}

            {role === "serviceprovider" && (
              <>
                <Route path="/" element={<ServiceProfessionals.Dashboard/>} />
                {/* <Route exact path="*" element={<ServiceProfessionals.Error404 />} /> */}
                <Route path="/dashboard" element={<ServiceProfessionals.Dashboard/>} />

                {/* Service Professional Properties Routes */}
              </>
            )}
                

          </Route>
          
          <Route path="/onboarding" element={<Page.Onboarding />} />
          <Route path="/set-password" element={<Page.SetPassword/>} /> {/*Must NOT be open with token*/} 
          <Route path="/forgot-password" element={<Page.ForgotPassword/>} /> {/*Needs to re-create*/}
          <Route path="/email/check" element={<Page.CheckEmail/>} /> {/*No Need of this*/}

          {/* Landlord Auth */}
          <Route path="/landlord/login" element={<Landlord.Login />} />
          <Route path="/landlord/register" element={<Landlord.SignUp/>} />
          <Route path="/landlord/register/set-password" element={<Landlord.SetPassword/>} />
          <Route path="/landlord/register/subscription" element={<Landlord.Subscription/>} />
          <Route path="/landlord/register/pay" element={<Landlord.SubscriptionPayment/>} /> {/*Needs to improve*/}

          {/* Tenant Auth */}
          <Route path="/tenant/login" element={<Tenant.Login/>} />
          <Route path="/tenant/register" element={<Tenant.SignUp/>} />
          <Route path="/tenant/register/set-password" element={<Tenant.SetPassword isGoogleRegister={true} />} />
          <Route path="/set-password" element={<Tenant.SetPassword/>} /> {/*Must NOT be open with token*/}

          {/* Service Professionals Auth */}
          <Route path="/service-professionals/login" element={<ServiceProfessionals.Login/>} /> {/*Needs to re-create*/}
          <Route path="/service-professionals/register" element={<ServiceProfessionals.SignUp/>} />

          {/* User */}
          <Route path="/set-user-password" element={<UserSetPassword />} />

          <Route path="*" element={<Page.NotFound />} />
        </Routes>
      </AnimatePresence>
    </BrowserRouter>
  );
};

export default AppRoutes;
