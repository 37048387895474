import React, { useEffect, useState, useRef } from "react";
import * as Component from "Components/GeneralComponents";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "utils";
import * as API from "network/landlord/tenant";
import { getProperties } from "network/landlord/property";
import { EditOutlined } from "@ant-design/icons"; // Import the Ant Design edit icon
import Warning from "Components/GeneralComponents/Modals/Warning";
import Loader from "Helpers/Loader";
import {
  createinvoice,
  getTenants,
  selectinvoicecatogries,
} from "network/landlord/Account";
import {
  fetchInvoiceNo,
  fetchTenantProperty,
  getLease,
  fetchAccountbyId,
} from "network/landlord/Account";
import { invoiceformvalidation } from "utils/validations";
import { message, Switch } from "antd";
import { ConfigProvider } from "antd";
import { invoicedataform } from "utils/FormDataStructure";
import InvoiceModal from "Modals/InvoiceModal/InvoiceModal";
import { invoiceduedate } from "utils/dropdownData";
import { height } from "@mui/system";
import { validateInvoiceDetails } from "utils/validations";
const CreatePayable = () => {
  const navigate = useNavigate();
  // States start
  const [errors, setErrors] = useState({});
  const [invoiceNo, setInvoiceNo] = useState("");
  const [tenants, setTenants] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [propertyAccountWarning, setPropertyAccountWarning] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(false)

  const [invoiceCat, setInvoiceCat] = useState([
    {
      id: "Rent",
      category: "Rent",
    },
    {
      id: "Maintenance",
      category: "Maintenance",
    },
    {
      id: "Security",
      category: "Security",
    },
    {
      id: "Other",
      category: "Other",
    },
  ]);
  const [unitData, setUnitData] = useState([]);
  const [total, setTotal] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [BankAccount, setBankAccount] = useState({
    id: "",
    bankName: "",
  });
  const [images, setImages] = useState([]);
  const [form, setForm] = useState({
    invoiceNo: "",
    InvoiceDate: "",
    Duedate: "",
    SelectTenant: "",
    property: {
      propertyId: "",
      unitId: "",
    },
    Select_BankAccount: {
      id: "",
      bankName: "",
    },
    leaseId: "",

    frequency: "",
    latefee: false,
    late_fees_amount: {
      type: "",
      amount: 0,
    },
    total: 0,
    subtotal: 0,
    discount: {
      type: "$",
      amount: 0,
    },
    other_amount: {
      field: "OTHER AMOUNT",
      value: 0,
    },
    sendStatus: "",

    message: "",
  });

  const [postInvoiceData, setPostInvoiceData] = useState({
    to_name: "",
    from_name: "",
    invoice_date: "",
    due_date: "",
    invoice_no: "",
  });
  const inputRef = useRef(null); // Create a ref for the input field

  const handleIconClick = () => {
    setIsFocused(true);
    inputRef.current.focus(); // Focus the input field when icon is clicked
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  // const [billi:ngform, setBillingform] = useState({
  //   full_name: "",
  //   address: "",
  //   phone_no: "",
  // });
  // const [shippingform, setShippingform] = useState({
  //   full_name: "",
  //   address: "",
  //   phone_no: "",
  // });
  const [isFocused, setIsFocused] = useState(false);
  const [otherform, setOtherform] = useState({
    start_date: "",
    end_date: "",
    frequency: "",
    number_of_days_until_due: "",
    repeatForever: false,
  });

  const calledOnce = useRef(false);

  useEffect(() => {
    if (!calledOnce.current) {
      setIsPageLoading(true);
      getTenants().then((response) => {
        if (response?.apiCallStatus === "success") {
          setIsPageLoading(false);
          setTenants(response?.message?.data);
        }
      });
      calledOnce.current = true; // Set flag to prevent rerun
    }
  }, []);
  
  useEffect(() => {
    if (form.SelectTenant) {
      const tenant = tenants.find((tenant) => tenant._id === form.SelectTenant);

      // If tenant is found, set their properties to state
      if (tenant && tenant.properties) {
        const propertyData = tenant?.properties.map((e) => ({
          id: e._id,
          heading1: "",
          heading2: `${e.address}`,
          heading3: e.title,
          units: e.units.map((unit) => ({ id: unit.id, name: unit })),
          account: {
            id: e.account._id,
            name: e?.account?.landlordAccount?.stripeAccountDetails[0]
              ?.bank_name,
          },
        }));

        setPropertyData(propertyData);

        // Set the default bank account in form from the first property
      }


    } else {
      // setProperties([]); // Reset if no tenant or properties found
    }
  }, [form.SelectTenant]);

  // useEffect(() => {
  //   getProperties().then((res) => {
  //     if (res.apiCallStatus === "success") {
  //       setPropertyData(
  //         res.message.map((e) => ({
  //           id: e.id,
  //           heading1: "",
  //           heading2: `${e.address.address_line_1}, ${e.address.address_line_2}, ${e.address.city}, ${e.address.state}, ${e.address.country}, ${e.address.zipcode}`,
  //           heading3: e.title,
  //           units: e.units.map((unit) => ({ id: unit.id, name: unit.name })),
  //         }))
  //       );
  //     }
  //   });
  // }, []);

  // use it to create a invoice
  // useEffect(() => {
  //   const createInvoice = () => {
  //     const newErrors = invoiceformvalidation(form, otherform, options);
  //     setErrors(newErrors);
  //     console.log("new Errors: ",newErrors);
  //     console.log("Form: ", form);
  //     console.log("otherform: ", otherform);
  //     console.log("invoiceLineItems: ", invoiceLineItems);
  //     console.log("options: ",options)

  //     if (Object.keys(newErrors).length === 0) {
  //       const formdata = invoicedataform(
  //         form,
  //         images,
  //         otherform,
  //         invoiceLineItems,
  //         options
  //       );
  //       createinvoicefunction(formdata);
  //     }
  //   };

  // },[])

  const createInvoice = (sendStatus) => {
    setIsLoading(true);
    // const newErrors = invoiceformvalidation(form, otherform, options);
    // setErrors(newErrors);
    // console.log("new Errors: ",newErrors);
    // Update form with sendStatus in a variable
    const newErrors = validateInvoiceDetails(
      form,
      otherform,
      options,
      tempValue
    );

    const updatedForm = {
      ...form,
      sendStatus: sendStatus,
      invoiceNo: invoiceNo,
    };

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const formdata = invoicedataform(
        updatedForm,
        images,
        otherform,
        invoiceLineItems,
        options
      );

      for (let pair of formdata.entries()) {
      }
      createinvoicefunction(formdata);
    } else {
      setIsLoading(false);
    }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update the window width state on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchInvoiceNo().then((response) => {
      if (response.apiCallStatus === "success") {
        // setTenants(response.message.data);
        setForm({
          ...form,
          invoiceNo: response?.message?.invoiceNo,
        });
        const fullInvoiceNo = response.message.invoiceNo;
        const invoiceNo = fullInvoiceNo.substring(fullInvoiceNo.indexOf("INV")); // Extract everything starting from 'INV'
        setInvoiceNo(invoiceNo);
      }
    });
    // fetchLandlorTenants().then((response) => {
    //   if (response.apiCallStatus === "success") {
    //     // setTenants(response.message.data);
    //     console.log("from tenants in create payable: ",response.message.data)
    //     setTenants(response.message.data)

    //     // const fullInvoiceNo = response.message.data;
    //     //   const invoiceNo = fullInvoiceNo.substring(fullInvoiceNo.indexOf('INV')); // Extract everything starting from 'INV'
    //     // setInvoiceNo(invoiceNo)
    //   }
    // },[])
  }, []);

  // useEffect(() => {
  //   fetchTenantProperty(form.SelectTenant).then((response) => {
  //     if (response?.apiCallStatus === "success") {
  //       // setTenants(response.message.data);
  //       console.log("from tenant property in create payable: ",response.message)

  //       response?.message?.properties?.map((e) => ({
  //         id: e.id,
  //         heading1: "",
  //         heading2: `${e.address}`,
  //         heading3: e.title,
  //         units: e.units.map((unit) => ({ id: unit.id, name: unit.name })),
  //       }))

  //       setPropertyData(response?.message)
  //     }
  //   });

  // },[form.SelectTenant])

  useEffect(() => {
    if (form.property.propertyId && form.property.unitId) {
      getLease(form.property.propertyId, form.property.unitId).then(
        (response) => {
          if (response?.apiCallStatus === "success") {
           
            setForm({
              ...form,
              frequency: response?.message?.data?.frequency.toLowerCase(),
              latefee: response?.message?.data?.late_fee === "0" ? false : true,
              late_fees_amount: Number(
                response?.message?.data?.late_fees_amount
              ),
            });

           
            setOtherform({
              ...otherform,
              start_date: formatDate(
                response?.message?.data?.lease_term_start_date
              ),
              end_date: formatDate(
                response?.message?.data?.lease_term_end_date
              ),
              repeatForever:
                response?.message?.data?.lease_term === "Month-to-Month"
                  ? true
                  : false,
            });

            if (response?.message?.data?.lease_term === "Month-to-Month") {
              setOptions({ value: 2, label: "Recurring Invoice" });
            } else {
              setOptions({ value: 1, label: "One Time Invoice" });
            }

            // Example where you're handling the update directly:
            // Example where you're handling the update:
            var prevRent = 0;
            setinvoiceLineItems((prevItems) => {
              prevRent = prevItems
                .filter((item) => item.category === "Rent")
                .reduce((total, item) => total + (item.amount || 0), 0); // Sum up the amounts
              // Filter out any items where all attributes are empty or where category is 'Rent'

              setForm({
                ...form,
                leaseId: response?.message?.data?.id,
                subtotal:
                  form.subtotal +
                  response?.message?.data?.rent_amount -
                  prevRent,
              });

              const filteredItems = prevItems.filter(
                (item) =>
                  (item.description ||
                    item.product_details ||
                    item.category ||
                    item.amount) &&
                  item.category !== "Rent"
              );

              // Add the new 'Rent' item to the filtered list
              return [
                ...filteredItems, // Keep only non-empty and non-'Rent' items
                {
                  description: "Rent",
                  product_details: "This is the rent of House",
                  category: "Rent",
                  amount: response?.message?.data?.rent_amount,
                },
              ];
            });
          }
        }
      );
    }
  }, [form.property.propertyId, form.property.unitId]);

  // useEffect(() => {
  //   selectinvoicecatogries().then((res) => {
  //     if (res.apiCallStatus === "success") {
  //       setInvoiceCat(res.message);
  //     }
  //   });
  // }, []);
  const handleChangeCat = (value, index) => {
    const updatedInvoiceLineItems = invoiceLineItems.map((item, i) =>
      i === index ? { ...item, category: value } : item
    );
    setinvoiceLineItems(updatedInvoiceLineItems);
  };

  const handleChange = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };
  const handleDiscount = (field, value) => {
    setForm({
      ...form,
      discount: {
        ...form.discount, // Spread the existing discount object
        amount: value.amount,
        type: value.type,
      },
    });
  };

  const calculateDiscountAmount = () => {
    // console.log("Calling my fucntion",field,value);
    if (form.discount.amount === undefined) {
      form.discount.amount = 0;
    }
    // console.log("Discount",field,value)
    //calcuate discount based on field value
    let discount = 0;

    // Calculate discount based on type
    if (form.discount.type === "$") {
      // Fixed amount discount
      discount = form.discount.amount;
    } else if (form.discount.type === "%") {
      // Percentage discount
      discount =
        (form.discount.amount / 100) *
        (Number(form.subtotal) + Number(form.other_amount.value));
    }

    // Ensure discount does not exceed the total amount
    if (discount > Number(form.subtotal + form.other_amount.value)) {
      discount = Number(form.subtotal + form.other_amount.value);
    }

    // const discountedTotal = discount;
    setDiscountAmount(discount);

    // setForm({...form,
    //   total: discountedTotal
    //  });
  };

  useEffect(() => {
    calculateDiscountAmount();
  }, [form.discount, form.other_amount, form.subtotal]); // This will run every time discount is updated

  // const setBillingForm = (field, value) => {
  //   setBillingform({ ...billingform, [field]: value });
  // };
  // const setshippingForm = (field, value) => {
  //   setShippingform({ ...shippingform, [field]: value });
  // };
  const handleotherform = (field, value) => {
    if (field === "number_of_days_until_due" && value < 0) {
      setOtherform({ ...otherform, [field]: 0 });
    } else {
      setOtherform({ ...otherform, [field]: value });
    }
  };

  const handleDateChange = (field, e) => {
    handleChange(field, new Date(e).toLocaleDateString());
  };

  //mere kam ka function hai
  const createinvoicefunction = async (formdata) => {
    const response = await createinvoice(formdata);
    if (response.apiCallStatus === "success") {
      setIsLoading(false);
      const fullInvoiceNo = response?.message?.invoice?.invoiceNo || null;
      const invoiceNo = fullInvoiceNo?.substring(fullInvoiceNo?.indexOf("INV")); // Extract everything starting from 'INV'
      setInvoiceNo(invoiceNo);
      setPostInvoiceData({
        to_name: response.message.invoice.to_name,
        from_name: response.message.invoice.from_name,
        invoice_date: formatDate(response.message.invoice.invoiceDate),
        due_date: response.message.invoice.dueDate,
        invoice_no: invoiceNo,
      });

      setOpenModal(true);
    } else {
      setIsLoading(false);
      message.error(response.message);
    }
  };

  const handlePropertyChange = async (e) => {
    try {
      if (!e || !e.id) {
        console.warn("No property ID provided.");
        return;
      }

      // Fetch account details and handle possible errors
      const response = await fetchAccountbyId(e.id);

      if (response.apiCallStatus != "success") {
        // message.error(response.message);
        setPropertyAccountWarning(response.message);
        return;
      }

      // Handle account data (e.g., updating form state)
      if (response && response?.message.data.id) {

        setForm({
          ...form,
          Select_BankAccount: {
            ...form.Select_BankAccount,
            id: response.message.data.id,
            value: response.message.data.bankName,
          },
          property: { propertyId: e.id, unitId: "" },

        });

        setUnitData(e.units);
      } else {
        setPropertyAccountWarning(
          "No account data returned for the selected property."
        );

        console.warn("No account data returned for the selected property.");
      }
    } catch (error) {
      setPropertyAccountWarning(error.message);
      console.error("Error fetching account data:", error.message);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [options, setOptions] = useState();
  const [DeletedImages, setDeletedImages] = useState([]);
  const [Repeat, setRepeat] = useState("");
  const [invoiceLineItems, setinvoiceLineItems] = useState([
    {
      description: "",
      product_details: "",
      category: "",
      amount: "",
    },
  ]);
  const removeLineItems = (id) => {
    if (id !== 0) {
      const updatedLineItems = invoiceLineItems.filter((lineItem, index) => {
        return index !== id;
      });
      const deletedInvoiceLineItems = invoiceLineItems.filter(
        (lineItem, index) => {
          return index == id;
        }
      );
      setForm({
        ...form,
        subtotal: form.subtotal - Number(deletedInvoiceLineItems[0]?.amount),
      });

      // const subtoal = invoiceLineItems.reduce((sum, item) => sum + Number(item.amount), 0);
      setTotal(total - Number(deletedInvoiceLineItems[0]?.amount));
      // setSubtotal(subtoal - Number(deletedInvoiceLineItems[0]?.amount))
      setinvoiceLineItems(updatedLineItems);
    }
  };
  // Extract the numerical part from the invoice number
  const getNumericalPart = () => {
    const numericalPart = invoiceNo.match(/\d+/); // Extracts numerical part from the string
    return numericalPart ? numericalPart[0] : "";
  };

  const handleInvoiceClick = () => {
    setIsEditing(true);

    setTempValue(getNumericalPart());
  };

  const handleInvoiceBlur = () => {
    const fullInvoiceNo = form.invoiceNo;
    const invoiceNo = fullInvoiceNo.substring(fullInvoiceNo.indexOf("INV")); // Extract everything starting from 'INV'
    const numericalPart = invoiceNo.match(/\d+/);

    if (Number(tempValue) < Number(numericalPart)) {
      setErrors({
        ...errors,
        invoiceNo: "Invoice No should be greater than previous ",
      });
    } else {
      setErrors({
        ...errors,
        invoiceNo: "",
      });
      const paddedValue = tempValue.padStart(4, "0"); // Adds leading zeros if less than 4 digits

      setInvoiceNo("INV-" + paddedValue);
    }
    setIsEditing(false); // Stop editing when focus is lost
  };

  const handleInvoiceChange = (e) => {
    setTempValue(e.target.value);
  };
  const handlePropertyWarningClose = () => {
    setPropertyAccountWarning(null);
  };

  const handleAddInputChange = (fieldName, e, index) => {
    if (fieldName === "amount" && e < 0) {
      const list = [...invoiceLineItems];
      list[index][fieldName] = 0;
      setinvoiceLineItems(list);
    } else {
      const list = [...invoiceLineItems];
      list[index][fieldName] = e;
      setinvoiceLineItems(list);
    }
    form.subtotal = 0;
    const subtotal = invoiceLineItems.reduce(
      (sum, item) => sum + Number(item.amount),
      0
    );
    setForm({
      ...form,
      subtotal: subtotal,
    });
    setTotal(subtotal + Number(form.other_amount.value));
  };
  const handleAdd = () => {
    setinvoiceLineItems([
      ...invoiceLineItems,
      { description: "", product_details: "", category: "", amount: "" },
    ]);
  };
  // // Modal Functions
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    navigate("/payment");
    setOpenModal(false);
  };

  const downlaodfun = () => {};

  const containerStyle = {
    position: "relative",
    // display: 'flex',
    width: "150px", // Fixed width
    height: "40px",
  };

  const textStyle = {
    height: "40px",
    borderRadius: "4px",

    overflow: "hidden",
    whiteSpace: "nowrap", // Prevent text from wrapping
    // border: '1px solid #ccc',
    border: isFocused ? "1px solid #EF6B3E" : "none", // Dynamic border style
    outline: isFocused ? "2px solid #3B82F6" : "none", // Dynamic outline when focused
    padding: isFocused ? "8px" : "0px", // Dynamic outline when focused
    width: isFocused ? "200px" : "150px", // Dynamic border style
    backgroundColor: "transparent",
    color: "#344054",
    // display: 'inline-block',
    // width: '100%',
    outline: "none",
  };

  const iconStyle = {
    // position: 'absolute',
    top: "7px",
    left: "80%", // Positioning the icon inside the padding
    // transform: 'translateY(-50%)',
    fontSize: "14px", // Adjust icon size
    color: "#344054", // Icon color
    cursor: "pointer",
  };
  return (
    <>
      {propertyAccountWarning && (
        <Warning
          onClose={handlePropertyWarningClose}
          isOpen={propertyAccountWarning}
          message={propertyAccountWarning}
        />
      )}
      {}
     
      {openModal && (
        <>
          <Component.FormModalLayout
            title={"Invoice Preview"}
            isOpen={openModal}
            onClose={onCloseModal}
          >
            <InvoiceModal
              onClose={onCloseModal}
              to_name={postInvoiceData.to_name}
              from_name={postInvoiceData.from_name}
              invoice_date={postInvoiceData.invoice_date}
              due_date={postInvoiceData.due_date}
              invoice_no={postInvoiceData.invoice_no}
              // postInvoiceData
            />
          </Component.FormModalLayout>
        </>
      )}
       {isPageLoading ? (
        <Loader/>
      ) : (
        <div className=" bg-white p-3">
        <div className="container">
          <div className="row ">
            <div className="col-md-4 mt-3">
              <span className="property-details-input-title">Invoice No</span>
              <Component.InvoiceNoField
                type={isEditing ? "number" : "text"}
                className="form-control"
                value={isEditing ? tempValue : invoiceNo}
                onClick={handleInvoiceClick}
                onBlur={handleInvoiceBlur}
                onChange={handleInvoiceChange}
                readOnly={!isEditing} // Enable editing only when clicked
                errorMessage={errors.invoiceNo}
              />
            </div>
            <div className="col-md-4 mt-3">
              <Component.DateField
                required={true}
                label={"Invoice Date"}
                placeholder={"Select Invoice Date"}
                defaultValue={form.InvoiceDate && formatDate(form.InvoiceDate)}
                handler={(e) => handleDateChange("InvoiceDate", e)}
                errorMessage={errors.InvoiceDate}
              />
            </div>
            <div className="col-md-4 mt-3">
              <Component.SelectField
                required={true}
                label={"Due Date"}
                value={form.Duedate}
                placeholder={"Select Due Date"}
                options={invoiceduedate.map((e) => {
                  return { value: e.value, label: e.label };
                })}
                handler={(e) => handleChange("Duedate", e)}
                errorMessage={errors.Duedate}
              />
              {/* <Component.DateField
                required={true}
                label={"Due Date"}
                placeholder={"Select Due Date"}
                defaultValue={form.Duedate && formatDate(form.Duedate)}
                handler={(e) => handleDateChange("Duedate", e)}
                errorMessage={errors.Duedate}
              /> */}
            </div>
            <div className="col-md-12 mt-3">
              <Component.SelectField
                required={true}
                label={"Select Tenant"}
                placeholder={"Select Tenant"}
                options={tenants.map((e) => {
                  return { value: e._id, label: e.fullName };
                })}
                value={form.SelectTenant}
                handler={(e) => handleChange("SelectTenant", e)}
                errorMessage={errors.SelectTenant}
              />
            </div>
            <div className="col-md-6 ">
              <Component.SelectPropertyField
                label={"Property"}
                placeholder={"Select Property"}
                isRequired={true}
                PropertyData={propertyData}
                errorMessage={errors.property}
                onPropertySelect={(selectedProperty) => {
                  handlePropertyChange(selectedProperty);
                }}
                disabled={propertyData.length === 0}
              />
            </div>
            <div className="col-md-6 mt-3 ">
              <Component.SelectField
                name={"unit"}
                required={true}
                label={"Unit"}
                TooltipTitle={
                  "Unit can't be selected without selecting property first."
                }
                placeholder={"First select property then unit"}
                value={form.property.unitId}
                options={unitData.map((e) => ({
                  value: e.id,
                  label: e.name.unit,
                }))}
                handler={(e) => {
                  setForm({
                    ...form,
                    property: { ...form.property, unitId: e },
                  });
                }}
                errorMessage={errors.unit}
                disabled={unitData.length === 0}
              />
            </div>
            <div className="col-md-12 mt-3">
              <Component.SelectField
                required={true}
                label="Select Bank Account"
                placeholder={"Select Bank Account"}
                options={[
                  {
                    value: form.Select_BankAccount.id,
                    label: form.Select_BankAccount.value,
                  },
                ]}
                value={form.Select_BankAccount.value}
                handler={(e) => handleChange("Select_BankAccount", e)}
                errorMessage={errors.Select_BankAccount}
                disabled={true}
              />
            </div>
            <div className="mt-3">
              <Component.RadioGroupField
                label={"Invoice Category"}
                options={[
                  { value: 1, label: "One Time Invoice" },
                  { value: 2, label: "Recurring Invoice" },
                ]}
                handler={(e) => setOptions(e)}
                value={options?.value || options} // Setting the selected value
                direction="row"
              />
            </div>
            {(options?.value == 2 || options == 2) && (
              <div className="mt-3 row">
                <div className="col-md-4">
                  <Component.DateField
                    required={true}
                    label={"Start Date"}
                    placeholder={"Select Start Date"}
                    defaultValue={
                      otherform.start_date && formatDate(otherform.start_date)
                    }
                    handler={(e) => handleotherform("start_date", e)}
                    errorMessage={errors.start_date}
                  />
                </div>
                <div className="col-md-4">
                  <Component.DateField
                    required={true}
                    label={"End Date"}
                    placeholder={"Select End Date"}
                    defaultValue={
                      otherform.end_date && formatDate(otherform.end_date)
                    }
                    handler={(e) => handleotherform("end_date", e)}
                    errorMessage={errors.end_date}
                    disabled={otherform.repeatForever}
                  />
                </div>
                <div className="col-md-4">
                  <Component.SelectField
                    required={true}
                    label={"Frequency"}
                    placeholder={"Select Frequency"}
                    options={[
                      { value: "Weekly", label: "Weekly" },
                      { value: "Monthly", label: "Monthly" },
                      { value: "Quarterly", label: "Quarterly" },
                      { value: "Yearly", label: "Yearly" },
                    ]}
                    value={otherform.frequency}
                    handler={(e) => handleotherform("frequency", e)}
                    errorMessage={errors.frequency}
                  />
                </div>
                <div className="col-md-4 mt-3">
                  <Component.InputField
                    name={"number_of_days_until_due"}
                    label={"Number of Days Until Due"}
                    type="number"
                    placeholder={"Number of Days Until Due"}
                    value={otherform.number_of_days_until_due}
                    handler={(e) => {
                      handleotherform(
                        "number_of_days_until_due",
                        e.target.value
                      );
                    }}
                    min={0}
                    // errorMessage={errors.number_of_days_until_due}
                  />
                </div>

                <div className="col-md-4 align-items-center  mt-2 pt-3 mt-md-4 pt-md-4">
                  <ConfigProvider
                    theme={{
                      components: {
                        Switch: {
                          colorPrimary: "#EF6B3E",
                          colorPrimaryHover: "#EF6B3E",
                        },
                      },
                    }}
                  >
                    <span className="d-flex align-items-center gap-2">
                      <Switch
                        onChange={(e) => {
                          setOtherform({
                            ...otherform,
                            repeatForever: !otherform.repeatForever,
                          });
                        }}
                        // defaultChecked={ true}
                        checked={otherform.repeatForever}
                      />
                      Repeat Forever
                    </span>
                  </ConfigProvider>
                </div>
              </div>
            )}
            {/* <div className="d-flex flex-column flex-md-row ">
              <div className="flex-grow-1 pe-md-3 pt-3">
                <div className="col-md-6 mt-4">
                  <p className="normal-grey-text my-3">BILLING DETAILS</p>
                </div>
                <div className="mt-3">
                  <Component.InputField
                    name={"full_name"}
                    label={"Full Name"}
                    type="text"
                    placeholder={"Full Name"}
                    value={billingform.full_name}
                    handler={(e) => {
                      setBillingForm("full_name", e.target.value);
                      console.log("Billing Full Name", e);
                    }}
                  />
                </div>
                <div className="mt-3">
                  <Component.InputField
                    name={"address"}
                    label={"Address"}
                    type="text"
                    placeholder={"Address"}
                    value={billingform.address}
                    handler={(e) => {
                      setBillingForm("address", e.target.value);
                    }}
                  />
                </div>
                <div className="mt-3">
                  <Component.PhoneNumberField
                    name={"phone_no"}
                    label={"Phone No"}
                    placeholder={"Phone number"}
                    value={billingform.phone_no}
                    handler={(e) => {
                      setBillingForm("phone_no", e);
                    }}
                  />
                </div>
              </div> */}
            {/* <div className="flex-grow-1 p-md-3 ">
                <div className="col-md-6 mt-4">
                  <p className="normal-grey-text my-3">SHIPPING DETAILS</p>
                </div>
                <div className="mt-3">
                  <Component.InputField
                    name={"full_name"}
                    label={"Full Name"}
                    type="text"
                    placeholder={"Full Name"}
                    value={shippingform.full_name}
                    handler={(e) => {
                      setshippingForm("full_name", e.target.value);
                      console.log("Billing Full Name", e);
                    }}
                  />
                </div>
                <div className="mt-3">
                  <Component.InputField
                    name={"address"}
                    label={"Address"}
                    type="text"
                    placeholder={"Address"}
                    value={shippingform.address}
                    handler={(e) => {
                      setshippingForm("address", e.target.value);
                    }}
                  />
                </div>
                <div className="mt-3">
                  <Component.PhoneNumberField
                    name={"phone_no"}
                    label={"Phone No"}
                    placeholder={"Phone number"}
                    value={shippingform.phone_no}
                    handler={(e) => {
                      setshippingForm("phone_no", e);
                    }}
                  />
                </div>
              </div> */}
            {/* </div> */}
          </div>
        </div>
        <div className="row mt-3 ">
          {invoiceLineItems.map((data, index) => {
            return (
              <>
                <div key={index + 1} className="col-md-12">
                  <div className="container">
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-column flex-md-row">
                        <div className="col-md-4 col-12">
                          <div className="ribin pt-3 pb-3 mt-3 ps-3 mb-3">
                            DESCRIPTION
                          </div>
                          <div className=" pe-md-4">
                            <Component.InputField
                              name="description"
                              label="Product Name"
                              type="text"
                              placeholder="Product Name"
                              value={data.description}
                              handler={(e) =>
                                handleAddInputChange(
                                  "description",
                                  e.target.value,
                                  index
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-12">
                          <div className="ribin pt-3 pb-3 mt-3 mb-3">
                            CATEGORY
                          </div>
                          <div className="pe-md-4">
                            <Component.SelectField
                              name="category"
                              label={"Select Category"}
                              placeholder={"Select Category"}
                              options={invoiceCat.map((e) => {
                                return { value: e.id, label: e.category };
                              })}
                              value={invoiceLineItems[index]?.category} // Assuming you're selecting for the first item
                              handler={(value) => handleChangeCat(value, index)} // Pass value and index
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-12">
                          <div className="ribin pt-3 pb-3  mt-3 mb-3 d-flex align-items-center justify-content-between">
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                marginLeft: "10px",
                              }}
                            >
                              AMOUNT
                            </div>
                            {index !== 0 && (
                              <div
                                className="trash cursor align-items-center "
                                style={{
                                  // width: '5px',
                                  // height: '100%',
                                  marginRight: "30px",
                                  // marginBottom: '20px',
                                }}
                                onClick={() => removeLineItems(index)}
                              >
                                <svg
                                  width={20}
                                  height={20}
                                  fill="none"
                                  stroke="#D92D20"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M3 6h18" />
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                  <path d="M10 11v6" />
                                  <path d="M14 11v6" />
                                </svg>
                              </div>
                            )}
                          </div>

                          <div className=" ">
                            <Component.InputField
                              name="amount"
                              label="Amount"
                              type="number"
                              placeholder="Amount"
                              value={data.amount}
                              handler={(e) =>
                                handleAddInputChange(
                                  "amount",
                                  e.target.value,
                                  index
                                )
                              }
                              min={0}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <Component.TextAreaField
                          name="product_details"
                          label="Product Details"
                          type="text"
                          rows={4}
                          cols={10}
                          placeholder="Product Details"
                          value={data.product_details}
                          handler={(e) =>
                            handleAddInputChange(
                              "product_details",
                              e.target.value,
                              index
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="container mb-4 mt-4">
          <div className="row mt-3 mb-4 ">
            <div className="col-md-12 text-start ">
              <button
                onClick={handleAdd}
                className="save-btn-outline primary-orange-text fw-bold"
              >
                + Add Item
              </button>
            </div>
          </div>
          <div className="border-top">
            <div className="row mt-4">
              <div className=" col-md-4 col-12">
                <Component.SelectField
                  label={"Late Fees"}
                  placeholder={"Select Late Fees"}
                  options={[
                    { value: true, label: "Yes" },
                    { value: false, label: "No" },
                  ]}
                  value={form.latefee ? "Yes" : "No"}
                  handler={(e) => handleChange("latefee", e)}
                />
              </div>
              <div className="col-md-4 mt-3 mt-md-0">
                <Component.SelectField
                  label={"Frequency"}
                  placeholder={"Select Frequency"}
                  options={[
                    { value: "Daily", label: "Daily" },
                    { value: "Weekly", label: "Weekly" },
                    { value: "Monthly", label: "Monthly" },
                    { value: "Yearly", label: "Yearly" },
                  ]}
                  value={form.frequency}
                  handler={(e) => handleChange("frequency", e)}
                  // errorMessage={errors.frequency}
                />
              </div>
              <div className="col-md-4 col-12 mt-3 mt-md-0">
                <Component.AmountField
                  name={"late_fees_amount"}
                  label={"Amount"}
                  placeholder={
                    form.late_fees_amount.type === "%"
                      ? "Enter Late Fees Percentage"
                      : "Enter Late Fees Amount"
                  }
                  value={{
                    amount: form?.late_fees_amount?.amount,
                    type: form?.late_fees_amount?.type,
                  }}
                  handler={(value) => handleChange("late_fees_amount", value)}
                  amountTypes={["$", "%"]}
                  disabled={form.latefee === true ? false : true}
                />
              </div>
            </div>
          </div>

          {/* //add fields for total and subtoal */}
          <div className="container mt-5 mr-5">
            {/* Sub total Section */}
            <div
              className="row md:column col-md-11  justify-content-end mr-5"
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "16px",
                // fontWeight: '500',
                lineHeight: "20px",
                textAlign: "left",
                color: "#344054",
                // color: '#fff', // Ensure text is readable on the dark background
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              <div className="col-md-6 offset-md-6 d-flex justify-content-between">
                <span className="">SUBTOTAL</span>
                <span className="">${form.subtotal}.00</span>
              </div>
            </div>

            {/* Discount Section */}
            <div
              className="row col-md-11  justify-content-end"
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "16px",
                // fontWeight: '500',
                lineHeight: "20px",
                textAlign: "left",
                color: "#344054",
                // color: '#fff', // Ensure text is readable on the dark background
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              <div className="col-md-6 offset-md-6 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
                <span>DISCOUNT</span>
                <div
                  style={{
                    width: window.innerWidth > 768 ? "40%" : "80%",
                    marginTop: window.innerWidth > 768 ? "0" : "5px",
                  }}
                >
                  <Component.AmountField
                    name={"discount"}
                    // label={"Amount"}
                    placeholder={
                      form.discount.type === "%"
                        ? "Enter Late Fees Percentage"
                        : "Enter Late Fees Amount"
                    }
                    value={{
                      amount: form?.discount?.amount,
                      type: form?.discount?.type,
                    }}
                    handler={(value) => handleDiscount("discount", value)}
                    amountTypes={["$", "%"]}
                    // disabled={form.latefee === true ? false : true}
                  />
                </div>
              </div>
            </div>

            {/* Other Amount Section */}
            <div
              className="row col-md-11 justify-content-end"
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "16px",
                lineHeight: "20px",
                textAlign: "left",
                color: "#344054",
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              <div className="col-md-6 offset-md-6 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
                <div
                  style={containerStyle}
                  className="d-flex flex-row justify-content-between align-items-start align-items-md-center"
                >
                  <input
                    // contentEditable={true}
                    ref={inputRef} // Attach the ref to the input
                    style={textStyle}
                    onBlur={handleBlur}
                    onFocus={handleIconClick}
                    value={form.other_amount.field}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        other_amount: {
                          ...form.other_amount,
                          field: e.target.value, // Use innerText to get the updated content
                        },
                      });
                    }}
                  />

                  <EditOutlined
                    onClick={handleIconClick}
                    style={{
                      iconStyle,
                      display: isFocused ? "none" : "",
                    }}
                  />
                </div>

                {/* <div>{form.other_amount.field}</div> */}

                {/* <input className="font-weight-bold" 
          style={{
            // border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '5px',
            minWidth: '100px',
            textAlign: 'right',
            width: '100px', // Fixed width
            border: '1px solid #ccc',
            // outline: 'none',
            overflow: 'hidden',
            textOverflow: 'ellipsis',

            
          }}

          value={form.other_amount.value}
          type='Number'

          onChange={(e)=>{
            setForm({
             ...form,
              other_amount: {
               ...form.other_amount,
                value: e.target.value,
              },
            });
          }}
    /> */}

                <div
                  style={{
                    width: window.innerWidth > 768 ? "40%" : "80%",
                    marginTop: window.innerWidth > 768 ? "0" : "5px",
                  }}
                >
                  <Component.AmountField
                    name={"other_amount"}
                    // label={"Amount"}
                    placeholder={"0.00"}
                    value={{
                      value: form?.other_amount?.value,
                      // type: form?.other_amount?.type,
                    }}
                    handler={(value) =>
                      setForm({
                        ...form,
                        other_amount: {
                          ...form.other_amount,
                          value: value.amount,
                        },
                      })
                    }
                    amountTypes={["$"]}
                    // disabled={form.latefee === true ? false : true}
                  />
                </div>
              </div>
            </div>

            {/* Total Section */}
            <div
              className="row col-md-11 justify-content-end "
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "20px",
                textAlign: "left",
                color: "#344054",
                // color: '#fff', // Ensure text is readable on the dark background
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              <div className="col-12 col-md-6  d-flex  justify-content-between">
                <span className="text-uppercase " style={{ fontWeight: "600" }}>
                  Total
                </span>
                <span className="" style={{ fontWeight: "600" }}>
                  $
                  {Number(form.subtotal) +
                    Number(form.other_amount.value) -
                    discountAmount}
                </span>
              </div>
            </div>
          </div>

          <div className="mt-4 mr-4">
            <Component.MediaField
              label={"Upload or Attach files"}
              value={images}
              setImages={setImages}
              Images={images}
              DeletedImages={DeletedImages}
              setDeletedImages={setDeletedImages}
            />
          </div>
          <div className="mt-4">
            <Component.TextAreaField
              name="message"
              label="Message"
              type="text"
              rows={4}
              cols={10}
              placeholder="Enter the message"
              value={form.message}
              handler={(e) => handleChange("message", e.target.value)}
            />
          </div>
        </div>
        <div className="container ">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center ">
            <div className="mb-3 mb-md-0">
              <Link
                className="primary-orange-text fw-semibold td"
                onClick={() => createInvoice("sent")}
              >
                Save Invoice
              </Link>
            </div>
            <div className="d-flex  gap-2" style={{ width: "350px" }}>
              <button
                onClick={downlaodfun}
                className={`recuring-btn text-nowrap`}
              >
                Download
              </button>
              <button
                onClick={() => createInvoice("sent")}
                className={`next-btn-same-class text-nowrap`}
              >
                {isLoading ? "Loading..." : "Send"}
              </button>
            </div>
          </div>
        </div>
        {/* <div className="container pt-3">
          <div className="d-flex  align-items-center  justify-content-between"> */}
        {/* <div className="mt-4"> */}
        {/* </div> */}
        {/* <div className="d-flex">
              <button
                onClick={downlaodfun}
                className={`recuring-btn text-nowrap`}
              >
                Download
              </button>
              <div className="payables-btn d-flex">
                <button onClick={createInvoice} className="save-btn ">
                  <span className="me-2">
                    <svg
                      width={21}
                      height={21}
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M22 2 11 13" />
                      <path d="m22 2-7 20-4-9-9-4 20-7z" />
                    </svg>
                  </span>
                  Send
                </button>
              </div>
            </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
      )
    }
     
    </>
  );
};

export default CreatePayable;
