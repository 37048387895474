import AllWorkOrders from "Components/AllWorkOrders/AllWorkOrders";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";

const WorkOrders = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle={"All Work Orders"} />
      </div>
      {!["Owner/Executive", "Property Manager", "Maintenance Technician"].some(
        (role) => myUserRoles?.includes(role)
      ) && myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <AllWorkOrders />
      )}
      {/* <AllWorkOrders /> */}
    </div>
  );
};

export default WorkOrders;
