import axios from "axios";
import config from "Helpers/config.js";

const createProperty = async (data) => {
  try {
    const response = await axios.post(`${config.baseUrl}/api/property`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getProperties = async (filters) => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/property`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: filters,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getActiveProperties = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/properties/active`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getProperty = async (id) => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/property/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const archiveProperty = async (id) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/propertyArchive?id=${id}`,
      {
        archive: true,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const unArchiveProperty = async (id) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/propertyArchive?id=${id}`,
      {
        archive: false,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchTasks = async (id) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/tasks/property/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchAccountByProperty = async (id) => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/stripe/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchPropertyReport = async (url, id) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/getReport/property?type=${url}&property=${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateProperty = async (id, data) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/property/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteProperty = async (id) => {
  try {
    const response = await axios.delete(
      `${config.baseUrl}/api/property/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteMultipleProperties = async (data) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/properties/delete`,
      {
        propertyIds: data,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const addUnit = async (data) => {
  try {
    const response = await axios.post(`${config.baseUrl}/api/unit`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteUnit = async (id) => {
  try {
    const response = await axios.delete(`${config.baseUrl}/api/unit/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getUnit = async (id) => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/unit/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateUnit = async (id, data) => {
  try {
    const response = await axios.put(`${config.baseUrl}/api/unit/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getLeaseUnit = async (unitId, propertyId) => {
  // console.log("Ids >>>>>>>",unitId, propertyId);
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/leases/unit/${unitId}/${propertyId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          unitId,
          propertyId,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getUnitTenants = async (id) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/landlord/tenants/unit/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  createProperty,
  getProperties,
  getActiveProperties,
  getProperty,
  archiveProperty,
  unArchiveProperty,
  fetchTasks,
  fetchAccountByProperty,
  fetchPropertyReport,
  updateProperty,
  deleteProperty,
  deleteMultipleProperties,
  addUnit,
  deleteUnit,
  getUnit,
  updateUnit,
  getLeaseUnit,
  getUnitTenants,
};
