import LocalProfessionalDetail from "Components/LocalProfessionalDetail/LocalProfessionalDetail";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const LocalProfessionalDetailPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"Water Zone"}
          back="all-service-professional"
        />
      </div>
      <LocalProfessionalDetail />
    </div>
  );
};

export default LocalProfessionalDetailPage;
