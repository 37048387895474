import React from "react";
import AccountDetails from "Components/AccountDetails/AccountDetails";
import TitleTopBar from "Helpers/TitleTopBar";

const AccountsDetail = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitleDetail={"Accounting"} back="all-accounts" />
      </div>
      <AccountDetails />
    </div>
  );
};

export default AccountsDetail;
