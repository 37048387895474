import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import PropertiesDeleteView from "Components/PropertiesDeleteView/PropertiesDeleteView";

const PropertiesDeleteViewPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle={"Archive Properties"} />
      </div>
      <PropertiesDeleteView />
    </div>
  );
};

export default PropertiesDeleteViewPage;
