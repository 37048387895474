import AllVendors from "Components/AllVendors/AllVendors";
import TitleTopBar from "Helpers/TitleTopBar";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";
import React from "react";

const Vendors = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitle="All Vendors"
          vendorText="Add vendors to assign them maintenance requests, create work tasks, and pay them."
        />
      </div>
      {!["Owner/Executive", "Property Manager"].some((role) =>
        myUserRoles?.includes(role)
      ) && myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <AllVendors />
      )}
      {/* <AllVendors /> */}
    </div>
  );
};

export default Vendors;
