import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import AddTenantDetailsEditModal from "Modals/AddTenantDetailsEditModal/AddTenantDetailsEditModal";

const AddTenantDetailsEditModalPage = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"Edit Tenant Details"}
          back="all-tenants"
        />
      </div>
      <AddTenantDetailsEditModal />
    </div>
  );
};

export default AddTenantDetailsEditModalPage;
