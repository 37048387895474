import { useEffect, useState } from "react";
import searchIcon from "assets/search.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "antd";
import moment from "moment";
import { FirstCharacterIcon } from "Components/GeneralComponents";
import { getLandlordConversations, getLandlordArchive } from "network/landlord/chat";
import { getTenantConversations, getTenantArchive } from "network/tenant/chat";
import { ArchiveIcon, ChatIcon } from "assets/icons";
import { fetchUser } from "network/landlord/setting";
import { FetchUserOwnerInfo } from "network/landlord/Account";
import ChatBody from "../ChatBody/ChatBody";

const ChatUserList = ({ archive }) => {
  const socket = useSelector((state) => state.Chat.socket);
  const navigate = useNavigate();
  const location = useLocation();
  const [conversations, setConversations] = useState([]);
  const [openedChat, setOpenedChat] = useState(null);
  const [landlordData, setLandlordData] = useState([]);
  const [search, setSearch] = useState("");
  const role = localStorage.getItem("role");
  const [isRerender, setIsRerender] = useState(false);

  useEffect(() => {
    if (role === "landlord") {
      fetchUser().then((res) => {
        if (res.apiCallStatus === "success") {
          setLandlordData(res.message);
        }
      });
    } else if (role === "user") {
      FetchUserOwnerInfo().then((res) => {
        if (res.apiCallStatus === "success") {
          setLandlordData(res.message);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (archive) {
      if (role === "landlord" || role === "user") {
        getLandlordArchive().then((res) => {
          if (res.apiCallStatus === "success") {
            setConversations(res.message);
          }
        });
      } else {
        getTenantArchive().then((res) => {
          if (res.apiCallStatus === "success") {
            setConversations(res.message);
          }
        });
      }
    } else {
      if (role === "landlord" || role === "user") {
        getLandlordConversations(1, search).then((res) => {
          if (res.apiCallStatus === "success") {
            // console.log("res.data >>>>>>>>>>>", res);
            setConversations(res.message);
          }
        });
      } else {
        getTenantConversations(1, search).then((res) => {
          if (res.apiCallStatus === "success") {
            // console.log("res.data >>>>>>>>>>>", res);
            setConversations(res.message);
          }
        });
      }
    }
  }, [search, isRerender]);

  // Listen for new messages via socket
  useEffect(() => {
    if (socket) {
      socket.on("chatMessage", () => {
        if (role === "landlord" || role === "user") {
          getLandlordConversations(1, search).then((res) => {
            if (res.apiCallStatus === "success") {
              // console.log("res.data >>>>>>>>>>>", res);
              setConversations(res.message);
            }
          });
        } else {
          getTenantConversations(1, search).then((res) => {
            if (res.apiCallStatus === "success") {
              // console.log("res.data >>>>>>>>>>>", res);
              setConversations(res.message);
            }
          });
        }
      });
    }
  }, [socket, role]);

  const handleNavigateArchive = () => {
    navigate("/archive-user");
  };

  // Detect route changes (outlet close)
  useEffect(() => {
    if (!location.pathname.includes("user-chat")) {
      setIsRerender((prev) => !prev);
    }
  }, [location]);

  return (
    <div className="row m-0 h-100">
      <div className="col-md-5 col-lg-4 border-end p-0 h-100">
        <div className="chat-user-list-container p-2 h-100">
          <div className="chat-search-bar border-bottom position-relative pb-3 d-none d-lg-block">
            <input
              type="text"
              placeholder="Search"
              className="form-control search-form-control-task"
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="search-icon-task">
              <img src={searchIcon} alt="search" />
            </div>
          </div>

          <div className="chat-archive-bar mt-3 text-center">
            <button
              onClick={handleNavigateArchive}
              className={`archive-chat-button w-100 mb-2 ${
                archive && "d-none"
              }`}
            >
              <span>
                <ArchiveIcon />
              </span>{" "}
              Archived Chats
            </button>
          </div>

          <div
            className="chat-user-lists-container"
            style={{ height: "calc(100vh - 115px)", overflowY: "auto" }}
          >
            {conversations.map((item) => {
              const user =
                role === "landlord" || role === "user"
                  ? item.tenant
                  : item.landlord;
              return (
                <Link
                  key={item.id}
                  className="text-grey"
                  to={
                    window.innerWidth <= 850
                      ? "/mobile-chat"
                      : `chat?id=${item.id}`
                  }
                  onClick={() => {
                    setOpenedChat(item);
                    // dispatch(OnlineOffline(user.status || item.isTenant));
                  }}
                >
                  <div className="chat-user-lists gap-3 d-flex align-items-center border-bottom p-3">
                    {user?.profileImage ? (
                      <Avatar
                        src={user.profileImage}
                        size="large"
                        style={{ backgroundColor: "" }}
                      />
                    ) : (
                      <FirstCharacterIcon name={user?.firstName[0]} />
                    )}
                    <div
                      className="chat-user-info"
                      style={{ width: "calc(100% - 50px)" }}
                    >
                      <p className="mb-0">{`${user?.firstName} ${user?.lastName}`}</p>
                      <p
                        className="mb-0 normal-grey-text"
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {item.text}
                      </p>
                    </div>
                    <div className="chat-user-time">
                      <p className="mb-0 normal-grey-text">
                        {moment(item.timestamp).startOf("minutes").fromNow()}
                      </p>
                    </div>
                    {item.unreadCount > 0 && (
                      <div className="chat-user-count">
                        <p className="mb-0 text-white">{item.unreadCount}</p>
                      </div>
                    )}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <div className="col-md-7 d-md-none d-lg-block col-lg-8 ps-0 position-relative">
        {/* <Outlet context={{ data: openedChat }} /> */}
        {openedChat ? (
          <ChatBody
            data={openedChat}
            archive={archive}
            setOpenedChat={setOpenedChat}
            setIsRerender={setIsRerender}
            landlordData={landlordData}
          />
        ) : (
          <div className="chat-body-container">
            <div className="chat-body d-flex justify-content-center align-items-center h-100">
              <div>
                <div
                  className="d-flex justify-content-center"
                  style={{
                    color: "#A9A9A9",
                  }}
                >
                  <ChatIcon width={100} height={100} />
                </div>
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    height: "300px",
                    color: "#A9A9A9",
                  }}
                >
                  Select a chat to start messaging
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatUserList;
