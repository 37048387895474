import TenantTask from "Components/TenantTask/TenantTask";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const Tasks = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar topBarTitle="All Maintenance Request" />
      </div>
      <TenantTask />
    </div>
  );
};

export default Tasks;
