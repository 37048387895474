import axios from "axios";
import config from "Helpers/config.js";

const fetchUserTenant = async () => {
    try {
        const response = await axios.get(
        `${config.baseUrl}/api/auth/tenant/userinfo`,
        {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }
        );
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
};

const generateIncomeVerificationToken = async (data) => {
    console.log('Generating verification token');
    console.log('--------------------------------')
    console.log(localStorage.getItem("token"))
    try {
        const response = await axios.post(
        `${config.baseUrl}/api/plaid/incomeVerification`,
        data,
        {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }
        );
        console.log(response);
        return response.data.message;
    }
    catch (error) {
        return error.response.data;
    }
};

const generateIdentityVerificatoinLink = async (data) => {
    console.log('Generating verification token');
    console.log('--------------------------------')
    console.log("Generating identity: ",data)
    console.log(localStorage.getItem("token"))
    try {
        const response = await axios.post(
        `${config.baseUrl}/api/plaid/identityVerification`,
        
            data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json", // Ensure JSON format
                },
            }
        );
        console.log(response);
        return response.data.message;
    }
    catch (error) {
        return error.response.data;
    }
};

const exchangePublicToken =  async(publicToken) =>{
    try {
        const response = await axios.post(
        `${config.baseUrl}/api/plaid/exchangePublicToken`,
        {
            public_token: publicToken
        },
        {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }
        );

        console.log(response.data);
        return response.data.access_token;
    }
    catch (error) {
        return error.response.data;
    }
}

const handleIncomeVerificationSuccess = async() => {
    try {
        const response = await axios.post(
        `${config.baseUrl}/api/plaid/incomeVerificationSuccess`,
        {},
        {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }
        );
        console.log("income verification success: ",response);
        return response.data.message;
    }
    catch (error) {
        return error.response.data;
    }
}
const handleIncomeVerificationFailure = async() => {
    try {
        const response = await axios.post(
        `${config.baseUrl}/api/plaid/incomeVerificationSuccess`,
        {},
        {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }
        );
        console.log("Income verfication failed: ",response.data);
        return response.data.message;
    }
    catch (error) {
        return error.response.data;
    }
}

export {
     fetchUserTenant, 
     generateIncomeVerificationToken, 
     generateIdentityVerificatoinLink, 
     exchangePublicToken, 
     handleIncomeVerificationSuccess, 
     handleIncomeVerificationFailure 
    };