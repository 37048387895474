import React, { useEffect, useState } from "react";
import { recordInvoicePayment } from "network/landlord/Account";
import { message } from "antd";
import { SubmitButton, DateField, SelectField, TextAreaField, InputField } from "Components/GeneralComponents";

const RecordPayment = (props) => {
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Bank Transfer");
  const [notes, setNotes] = useState("");
  const [displayInvoiceNo, setDisplayInvoiceNo] = useState("");
  const { invoiceNo } = props;

  useEffect(() => {
    // Extract the invoice number substring from invoiceNo
    let display_invoice_no = invoiceNo.substring(invoiceNo.indexOf("INV"));
    setDisplayInvoiceNo(display_invoice_no);
  }, [invoiceNo]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ paymentAmount, paymentDate, paymentMethod, notes });

    recordInvoicePayment(invoiceNo)
      .then((res) => {
        console.log(res);
        setPaymentAmount("");
        setPaymentDate("");
        setPaymentMethod("Bank Transfer");
        setNotes("");
        message.success("Payment recorded successfully!");
      })
      .catch((e) => {
        message.error("Failed to record payment!");
        console.error(e);
      });
  };

  return (
    <div className="container ">
      <div className="card border-0 shadow-sm p-4">
        <form onSubmit={handleSubmit}>
          <div className="row mb-2">
            {/* Invoice No and Amount Due */}
            <div className="col-md-6 mb-3"
            style={{
              fontSize: '14px', fontWeight: '400px'  // Customize font style for invoice number and amount due
            }}
            >
              <label className="form-label">Invoice No</label>
              <p className="form-control-plaintext">{displayInvoiceNo}</p>
            </div>
            <div className="col-md-6 mb-3"
              style={{
                fontSize: '14px', fontWeight: '400px'  // Customize font style for invoice number and amount due
              }}
            >
              <label className="form-label">Amount Due</label>
              <p className="form-control-plaintext">$600</p> {/* Replace with actual amount */}
            </div>
          </div>

          <div className="row mb-2">
            {/* Payment Method and Payment Date */}
            <div className="col-md-6 mb-3">
              <SelectField
                label="Payment Method"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                options={[
                  { value: "Bank Transfer", label: "Bank Transfer" },
                  { value: "Credit Card", label: "Credit Card" },
                  { value: "Cash", label: "Cash" },
                ]}
              />
            </div>
            <div className="col-md-6 mb-3">
              <DateField
                name="payment_date"
                label="Payment Date"
                value={new Date().toISOString().split('T')[0]} // Format the current date as YYYY-MM-DD
                disabled={true}
                placeholder={new Date().toISOString().split('T')[0]} // Placeholder as just the date
              />
            </div>
          </div>

          <div className="mb-3">
            <TextAreaField
              label="Notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Enter any additional notes"
              rows={3}
            />
          </div>

          <SubmitButton text="Record Payment" handler={handleSubmit}>
            Record Payment
          </SubmitButton>
        </form>
      </div>
    </div>
  );
};

export default RecordPayment;
