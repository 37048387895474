import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentSuccessModal from "Modals/PaymentSuccessModal/PaymentSuccessModal";
import { postPayment } from "network/landlord/Account";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const Checkout = ({ options, invoiceNo }) => {
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleCheckoutCompletion = async (event) => {
    console.log("Checkout completed", event);
    console.log("invoiceNo: ", invoiceNo);

    setMessage("Payment was successful!");
    postPayment(invoiceNo);
  };

  const checkoutOptions = {
    ...options,
    onComplete: handleCheckoutCompletion,
  };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={checkoutOptions}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default Checkout;
