import PropertyReport from "Components/PropertyReport/PropertyReport";
import React from "react";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";

const ReportsProperty = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="container-fluid">
      {!["Owner/Executive", "Property Manager"].some((role) =>
          myUserRoles?.includes(role)
        ) && myUserRoles?.length !== 0 ? (
          <NoAccessPage />
        ) : (
          <PropertyReport />
        )}
      {/* <PropertyReport /> */}
    </div>
  );
};

export default ReportsProperty;
