import TenantPropertyDetailsView from "Components/TenantPropertyDetailsView/TenantPropertyDetailsView";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const PropertiesDetail = () => {
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"Property Details"}
          back="tenant-properties"
        />
      </div>
      <TenantPropertyDetailsView />
    </div>
  );
};

export default PropertiesDetail;
