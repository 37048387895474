import AddNotes from "Components/AddNotes/AddNotes";
import TitleTopBar from "Helpers/TitleTopBar";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import React from "react";

const NotesNew = () => {
  const { id } = UseUrlParamsHook();
  return (
    <div className="right-component bg-white h-100">
      <div className="container-fluid bg-default">
        <TitleTopBar
          topBarTitleDetail={"Add Notes"}
          back={id ? `property-details-view?id=${id}` : `all-notes`}
        />
      </div>
      <AddNotes />
    </div>
  );
};

export default NotesNew;
